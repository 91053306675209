import { captureException } from '@sentry/react';
import { Country } from '@unobravo/core';
import { StorageOptions, TimeFrameAgenda, VouchersSnapshot } from './types';
import { StorageConfig, createOption } from './utils';

export enum StorageKeys {
  mgm = 'mgm',
  sidebar = 'sidebar',
  localCountry = 'local-country',
  crowdinFeature = 'jipt_language_code_unobravo-manual',
  calendarTimeFrame = 'calendar_time_frame',
  incentiveModal = 'incentiveModal',
  noteWidthSidebar = 'noteWidthSidebar',
  notesFirstView = 'notesFirstView',
  redirectedFromWeeklyRecap = 'redirectedFromWeeklyRecap',
  keyGuideNDA = 'keyGuideNDA',
  patientVouchersSnapshot = 'pt-vouchers-snapshot',
  signupVisitorId = 'signup-visitor-id',
  indigoStorageKey = 'indigo-ai-widget-uid',
  isMobileApp = 'is-mobile-app',
  partnerToken = 'partner.token',
  regOnTopEmailSent = 'regOnTopEmailSent'
}

const config = {
  [StorageKeys.mgm]: createOption(localStorage, false),
  [StorageKeys.sidebar]: createOption(
    sessionStorage,
    undefined as boolean | undefined
  ),
  [StorageKeys.localCountry]: createOption(
    localStorage,
    undefined as Country | undefined
  ),
  [StorageKeys.crowdinFeature]: createOption(localStorage, ''),
  [StorageKeys.calendarTimeFrame]: createOption(
    localStorage,
    'month' as TimeFrameAgenda
  ),
  [StorageKeys.incentiveModal]: createOption(
    localStorage,
    undefined as string | undefined
  ),
  [StorageKeys.noteWidthSidebar]: createOption(localStorage, 500),
  [StorageKeys.notesFirstView]: createOption(localStorage, [] as string[]),
  [StorageKeys.redirectedFromWeeklyRecap]: createOption(localStorage, false),
  [StorageKeys.keyGuideNDA]: createOption(localStorage, false),
  [StorageKeys.patientVouchersSnapshot]: createOption(
    localStorage,
    {} as VouchersSnapshot
  ),
  [StorageKeys.signupVisitorId]: createOption(sessionStorage, ''),
  [StorageKeys.partnerToken]: createOption(sessionStorage, ''),
  [StorageKeys.indigoStorageKey]: createOption(
    localStorage,
    undefined as number | undefined
  ),
  [StorageKeys.isMobileApp]: createOption(
    localStorage,
    undefined as boolean | undefined
  ),
  [StorageKeys.regOnTopEmailSent]: createOption(sessionStorage, false)
} as const satisfies Record<StorageKeys, StorageConfig>;

export const createSafeStorage = <T extends StorageKeys>(key: T) => {
  type ReturnType = (typeof config)[T]['type'];

  const currentConfig = config[key];
  const { storage } = currentConfig;

  const defaultValue = (
    'defaultValue' in currentConfig ? currentConfig.defaultValue : null
  ) as ReturnType;

  const getLocalStorage = (options?: StorageOptions) => {
    try {
      const storageKey = `${key}${options?.suffix ?? ''}`;

      const value = storage.getItem(storageKey);

      return value ? (JSON.parse(value) as ReturnType) : defaultValue;
    } catch (error) {
      captureException(`safe-storage: getItem ${key}: ${error}`, {
        tags: { 'safe-storage': true }
      });

      options?.onError?.(error);

      return defaultValue;
    }
  };

  const setLocalStorage = (value: ReturnType, options?: StorageOptions) => {
    try {
      const storageKey = `${key}${options?.suffix ?? ''}`;

      storage.setItem(storageKey, JSON.stringify(value));
    } catch (error) {
      captureException(`safe-storage: setItem ${key}: ${error}`, {
        tags: { 'safe-storage': true }
      });

      options?.onError?.(error);
    }
  };

  const removeItem = (options?: StorageOptions) => {
    try {
      const storageKey = `${key}${options?.suffix ?? ''}`;

      return storage.removeItem(storageKey);
    } catch (error) {
      captureException(`safe-storage: removeItem ${key}: ${error}`, {
        tags: { 'safe-storage': true }
      });

      options?.onError?.(error);

      return defaultValue;
    }
  };

  return [getLocalStorage, setLocalStorage, { removeItem }] as const;
};
