import { SessionStatus } from '@unobravo-monorepo/common/types/IAppointments';
import { Color } from '@unobravo/zenit-core';
import { Box, Text, Stack } from '@unobravo/zenit-web';
import { BundleChatExperimentButton } from '../../../features/chat/components/BundleChatExperimentButton';

interface IPaidAppointment {
  dateLabel: string;
  hourLabel: string;
  messageTime?: string;
  headerLabel?: string;
  status: SessionStatus;
  action: () => void | Promise<void>;
  roundedFull?: boolean;
}

const backgroundColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.50';
    default:
      return 'cappuccino.50';
  }
};

const primaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.500';
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.500';
    default:
      return 'candy.500';
  }
};

const secondaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.700';
    default:
      return 'black';
  }
};

export const PaidAppointment = ({
  dateLabel,
  hourLabel,
  messageTime,
  headerLabel,
  status,
  action,
  roundedFull = true
}: IPaidAppointment) => {
  return (
    <>
      {headerLabel && (
        <Stack
          w="100%"
          justify="center"
          align="center"
          direction="row"
          mb="2xs"
        >
          <Text variant="sm" textAlign="center">
            {headerLabel}
          </Text>
        </Stack>
      )}
      <Box
        style={{ padding: 12 }}
        bgColor={backgroundColor(status)}
        w="100%"
        roundedBottom={roundedFull ? 'md' : undefined}
        roundedTop="md"
      >
        <Stack direction="row" align="center" justify="space-between">
          <Stack direction="column">
            <Text
              variant="lg"
              color={primaryColor(status)}
              fontWeight="semibold"
            >
              {dateLabel}
            </Text>
            <Text variant="sm" color={secondaryColor(status)}>
              {hourLabel}
            </Text>
            {messageTime && (
              <Text variant="xs" color="cappuccino.700">
                {messageTime}
              </Text>
            )}
          </Stack>

          <Stack direction="row" align="center">
            <Box pl="xs">
              <BundleChatExperimentButton status={status} action={action} />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
