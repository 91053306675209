import {
  ApolloClientProvider,
  platform
} from '@unobravo-monorepo/common/providers/ApolloClientProvider';
import { ErrorPageProvider } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import { ToasterProvider } from '@unobravo-monorepo/common/providers/Toaster';
import { TranslationsProvider, useCountry } from '@unobravo/translations';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const { NX_APP_APOLLO_URI: apolloUri } = process.env;

const PatientActivationErrorProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <ErrorPageProvider
      errorPageProps={{
        wentWrong: t('common:errorPage.ops'),
        description: t('common:errorPage.wentWrong'),
        pageMalfunction: t('common:errorPage.pageMalfunction')
      }}
    >
      {children}
    </ErrorPageProvider>
  );
};

export const PatientActivationProvider = () => {
  const { domainCountry } = useCountry();

  return (
    <TranslationsProvider namespaces={['patient-activation']}>
      <ToasterProvider>
        <ApolloClientProvider
          uri={apolloUri!}
          application={platform}
          country={domainCountry}
        >
          <PatientActivationErrorProvider>
            <Outlet />
          </PatientActivationErrorProvider>
        </ApolloClientProvider>
      </ToasterProvider>
    </TranslationsProvider>
  );
};
