import { mobileFirstMedia, Theme } from '@unobravo-monorepo/common';
import {
  headerHeight,
  mobileHeaderHeight
} from '@unobravo-monorepo/common/layouts/consts';
import { useBreakpointValue } from '@unobravo/zenit-web';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { layoutSelector } from '../../features/layout/layout.slice';
import { ModalOnboarding } from '../../features/onboarding/components/ModalOnboarding';
import { DashboardMenu } from '../components/DashboardMenu';
import { DoctorInfo } from '../components/TherapistSidebar/DoctorInfo';
import { PatientHeader } from './PatientHeader';
import { ReassignmentModal } from '../../features/reassignment/components/ReassignmentModal';
import { StatsigAATestExperiment } from '../../features/statsigExperiment';

const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  height: ${mobileHeaderHeight}px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  flex-shrink: 0;
  z-index: 4;
  background: ${Theme.specialColors.white};
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid ${Theme.colors.cappuccino[200]};
  ${mobileFirstMedia('md')(`
    position: relative;
    height: ${headerHeight}px;
    border-bottom: none;
  `)}
`;

const Body = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: shrink;
  height: 100%;
  padding-top: ${mobileHeaderHeight}px;
  ${mobileFirstMedia('md')(`
    padding-top: 0;
    min-height: 630px;
    height: calc(100vh - ${headerHeight}px);
    overflow: hidden;
  `)}
`;

interface IMobileMenu {
  isOpen: boolean;
  right?: boolean;
}

const MobileMenu = styled.aside<IMobileMenu>`
  background: ${Theme.specialColors.white};
  height: calc(100% - ${mobileHeaderHeight}px);
  position: fixed;
  top: ${mobileHeaderHeight}px;
  width: 100vw;
  background: ${Theme.specialColors.white};
  transition: ${({ right }) => (right ? 'right' : 'left')};
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.71, 0, 0.58, 1);
  z-index: ${({ right }) => (right ? 48 : 49)};
  overflow: auto;
  ${({ right }) => (right ? '' : 'padding: 16px 20px;')}
  ${({ right, isOpen }) =>
    `${right ? 'right:' : 'left:'}${isOpen ? '0;' : '-100vw;'}`}
`;

const DesktopMenu = styled.aside<{ isOpen: boolean; right?: boolean }>`
  flex-shrink: 0;
  ${({ right }) =>
    right ? 'width: 350px; margin-right: 12px;' : 'padding: 0 20px 16px 20px;'}
`;

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  ${mobileFirstMedia('md')(
    `padding-bottom: 12px; margin-right: 12px; width: auto;`
  )}
`;

export const PatientLayout: React.FC<{
  showTherapistSidebar?: boolean;
  children?: ReactNode;
}> = ({ children, showTherapistSidebar }) => {
  const { isLeftMenuOpen, isRightMenuOpen } = useSelector(layoutSelector);
  const { isMobile } = useBreakpointValue();
  const MenuWrapper = isMobile ? MobileMenu : DesktopMenu;
  const { isOnboardingOpen } = useSelector(layoutSelector);

  return (
    <>
      {isOnboardingOpen && <ModalOnboarding />}
      <ReassignmentModal />
      {/** statsig experiment */}
      <StatsigAATestExperiment />
      <PatientContainer data-testid="logged-in-container">
        <Header>
          <PatientHeader />
        </Header>
        <Body>
          <MenuWrapper isOpen={isLeftMenuOpen}>
            <DashboardMenu />
          </MenuWrapper>
          <Content>{children}</Content>
          {showTherapistSidebar && (
            <MenuWrapper isOpen={!isMobile || isRightMenuOpen} right>
              <DoctorInfo />
            </MenuWrapper>
          )}
        </Body>
      </PatientContainer>
    </>
  );
};
