import { Box } from '@unobravo/zenit-web';
import styled from 'styled-components';
import '@whereby.com/browser-sdk/embed';

interface VideoCallComponentProps {
  appointmentUrl: string;
}

const WhereByContainer = styled(Box)`
  display: flex;
  > whereby-embed {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export const WherebyEmbed = ({ appointmentUrl }: VideoCallComponentProps) => {
  return (
    <WhereByContainer id="videocall-container" w="100%" h="100%">
      <whereby-embed room={appointmentUrl} />
    </WhereByContainer>
  );
};
