import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Appointment } from '@unobravo-monorepo/common/components/Appointment';
import {
  IAppointment,
  SessionStatus
} from '@unobravo-monorepo/common/types/IAppointments';
import { downloadPdfFile } from '@unobravo-monorepo/common/utils/fileUtils';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { useFirebaseUser } from '@unobravo/core';
import { Box, Text, useBreakpointValue } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBundle } from '../../../features/bundles/hooks/useBundle';
import { ListPatientBundlesDocument } from '../../../features/home/graphql/queries.generated';
import { usePatient } from '../../../features/patientData/hooks/usePatient';
import { useConfirmBonusSession } from '../../../features/payment/hooks/useConfirmBonusSession';
import { usePayFreeSession } from '../../../features/payment/hooks/usePayFreeSession';
import { useTherapySurveyConsent } from '../../../features/therapySurveyConsentModal/hooks/useTherapySurveyConsent';
import { openTherapySurveyConsentModal } from '../../../features/therapySurveyConsentModal/therapySurveyConsent.slice';
import { useToast } from '../../../features/toaster/hooks/useToast';
import { PaySessionWithBundleDocument } from '../../graphql/mutation.generated';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { usePatientGTM } from '../../hooks/usePatientGTM';
import { useSession } from '../../hooks/useSession';
import { shouldOpenPaywall } from '../../utils/bundles';
import { ModalDownloadInvoice } from '../ModalDownloadInvoice';
import { ModifyAppointmentBox } from '../TherapySetting/AppointmentBubble/ModifyAppointmentChatMessage';
import { PaidAppointment } from '../PaidAppointment/PaidAppointment';

interface IAppointmentBubble {
  session: IAppointment;
  sentAt?: string;
  handleInvoice?: boolean;
  handleCreditNote?: boolean;
  showVideocall?: boolean;
}

interface ActionType {
  header?: string;
  label: string;
  action?: (arg?: boolean) => void;
}

const isModifyProposalRunning = (
  state: SessionStatus,
  proposalStatus?: 'accepted' | 'declined' | 'submitted'
) =>
  state &&
  ['NEW', 'CONFIRMED', 'VIDEOCALL', 'EXPIRED'].includes(state) &&
  (proposalStatus === 'submitted' || proposalStatus === 'accepted');

export const AppointmentBubble: React.FC<IAppointmentBubble> = ({
  session,
  sentAt,
  handleInvoice = false,
  handleCreditNote = false,
  showVideocall = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { payFreeSession, loading: paymentLoading } = usePayFreeSession();
  const { confirmBonusSession, loading: confirmBonusLoading } =
    useConfirmBonusSession();
  const { id: patientId, uuid: patientUuid } = usePatient();
  const { currentUser } = useFirebaseUser();
  const { uuid, id: sessionId } = session;
  const {
    category,
    sessionDate,
    id,
    status,
    isFree,
    hasVoucher,
    isInvoiceable,
    invoiceDownloadUrl,
    hasCreditNote,
    creditNoteDownloadUrl,
    refetch
  } = useSession(uuid, { showVideocall }, session);
  const { sendGenericError } = useErrorHandler();
  const { isMobile } = useBreakpointValue();
  const { hasBundleRemaining } = useBundle();

  const { startHour, endHour, day, month, year } = sessionDate || {};
  const showInvoice = handleInvoice && isInvoiceable;
  const showCreditNote = handleCreditNote && hasCreditNote;
  const sessionCategory = category || 'PAID';
  const isFreeSession = isFree || sessionCategory === 'FREE';
  const isHome = location.pathname.includes('home');
  const [modalSession, setModalSession] = useState('');

  const lastProposal = session.sessionsUpdateProposals?.[0];

  const isModifyProposalActive =
    lastProposal && isModifyProposalRunning(status, lastProposal?.status);

  const dispatch = useDispatch();
  const isConsentGiven = useTherapySurveyConsent();

  const { sendToast } = useToast();
  const [paySessionWithBundle, { loading: paySessionWithBundleLoading }] =
    useMutation(PaySessionWithBundleDocument, {
      refetchQueries: ['ListPatientBundles']
    });
  const [getListPatientBundles, { loading: getListPatientBundlesLoading }] =
    useLazyQuery(ListPatientBundlesDocument);

  const stateLocation = {
    state: {
      modal: location
    }
  };

  const payLabel = isFreeSession
    ? 'chat.appointmentProposalAction.free'
    : 'chat.appointmentProposalAction.pending';

  const checkDialogNavigation = () => {
    if (isHome) return 'home';
    return location.pathname.includes('agenda') ? 'agenda' : 'chat';
  };

  const payClickHandler = async (
    isInBundleAbTest = false,
    testVariant = undefined
  ) => {
    if (
      paymentLoading ||
      confirmBonusLoading ||
      paySessionWithBundleLoading ||
      getListPatientBundlesLoading ||
      !id
    ) {
      return;
    }
    if (isFreeSession) {
      const payResult = await payFreeSession(id, sessionDate);

      if (payResult && !payResult.success) {
        return;
      }
      await refetch();

      if (isConsentGiven) {
        return;
      }

      if (payResult && payResult.action === 'REQUEST_QOT_CONSENTS') {
        setTimeout(() => {
          dispatch(openTherapySurveyConsentModal());
        }, 500);
      }

      return;
    }
    if (sessionCategory === 'BONUS') {
      return (await confirmBonusSession(id)) && refetch();
    }

    if ((isInBundleAbTest || hasBundleRemaining) && patientId) {
      try {
        const { data: list } = await getListPatientBundles({
          variables: { patientId }
        });
        if (shouldOpenPaywall(list)) {
          if (testVariant === 'paywall') {
            return navigate(
              `../dialog/${checkDialogNavigation()}/bundlePaySession/${uuid}`,
              stateLocation
            );
          }
          if (testVariant === 'alert') {
            return navigate(
              `../dialog/${checkDialogNavigation()}/paySession/${uuid}`,
              {
                state: { modal: location }
              }
            );
          }
        }

        const { data } = await paySessionWithBundle({
          variables: { sessionId: +sessionId }
        });

        if (data?.paySessionWithBundle.result) {
          sendToast({
            variant: 'success',
            title: t('bundles.toast.title'),
            description: t('bundles.toast.subtitle')
          });
          await refetch();
        }
        return;
      } catch (error) {
        if (error instanceof ApolloError) {
          sendToast({
            variant: 'error',
            title: t('common:errorPage.ops2'),
            description: t('common:errorPage.retry')
          });
        }
      }
    }

    return navigate(`../dialog/${checkDialogNavigation()}/paySession/${uuid}`, {
      state: { modal: location }
    });
  };

  const downloadDocument = async (url?: string) => {
    if (!url) return;
    const filename = `${t(
      'common:appointment'
    )}_${sessionDate?.rawDate.toFormat('dd-MM-yyyy')}`;
    try {
      await downloadPdfFile(url!, filename, currentUser.token!);
    } catch {
      sendGenericError();
    }
  };

  const openModalDownloadInvoice = async () => {
    if (isMobile) {
      setModalSession(session.id);
    } else {
      await downloadDocument(creditNoteDownloadUrl);
    }
  };

  const openVideocall = () => {
    pushAuthenticatedEvent('StartVideocall', {
      user_id: patientId,
      uuid: patientUuid
    });
    pendoTrack('join_session', {
      sessionId
    });
    navigate(`../appointment/${uuid}`);
  };

  const headerLabel = t(
    isFreeSession
      ? 'chat.appointmentProposal.headerfree'
      : sessionCategory === 'BONUS'
      ? 'chat.appointmentProposal.headerbonus'
      : 'chat.appointmentProposal.headerpaid'
  );

  const headerFree = isFreeSession
    ? t('chat.appointmentProposal.headerfree')
    : undefined;

  const appointmentMap: Record<SessionStatus, ActionType> = {
    NEW: {
      header: headerLabel,
      label: payLabel,
      action: payClickHandler
    },
    CONFIRMED: {
      header: headerFree,
      label:
        hasVoucher && !isInvoiceable
          ? 'chat.appointmentProposalAction.confirmedWithVoucher'
          : 'chat.appointmentProposalAction.paid'
    },
    VIDEOCALL: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.videocall',
      action: openVideocall
    },
    EXPIRED: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.expired'
    },
    DONE: {
      header: headerFree,
      label: showInvoice
        ? 'agenda.invoice'
        : 'chat.appointmentProposalAction.done',
      action: showInvoice
        ? () => downloadDocument(invoiceDownloadUrl)
        : undefined
    },
    CANCELED: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.canceled'
    }
  };

  useEffect(() => {
    if (!!modalSession && !isMobile) {
      setModalSession('');
    }
  }, [isMobile]);

  return (
    <>
      <ModalDownloadInvoice
        filename={`${t('common:appointment')}_${sessionDate?.rawDate.toFormat(
          'dd-MM-yyyy'
        )}`}
        invoiceDownloadUrl={invoiceDownloadUrl}
        creditNoteDownloadUrl={creditNoteDownloadUrl}
        token={currentUser.token!}
        openModal={modalSession}
        onClose={() => setModalSession('')}
        label={`${day} ${month} ${year}`}
      />
      <div id={status === 'NEW' ? 'statusNew' : undefined}>
        {category === 'PAID' && status === 'NEW' ? (
          <PaidAppointment
            hourLabel={`${startHour} - ${endHour}`}
            dateLabel={`${day} ${month} ${year}`}
            messageTime={sentAt}
            status={status}
            headerLabel={
              appointmentMap[status as keyof typeof appointmentMap].header
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            action={
              appointmentMap[status as keyof typeof appointmentMap].action!
            }
            roundedFull={!isModifyProposalActive}
          />
        ) : (
          <Appointment
            hourLabel={`${startHour} - ${endHour}`}
            dateLabel={`${day} ${month} ${year}`}
            messageTime={sentAt}
            status={status}
            actionLabel={t(appointmentMap[status].label)}
            headerLabel={!isHome ? appointmentMap[status].header : ''}
            action={appointmentMap[status].action}
            onInvoiceDownloadPress={() => downloadDocument(invoiceDownloadUrl)}
            isInvoiceable={showInvoice}
            showCreditNote={showCreditNote}
            invoiceDisabled={isInvoiceable && !invoiceDownloadUrl}
            creditNoteDisabled={showCreditNote && !creditNoteDownloadUrl}
            invoiceTooltipLabel={
              showInvoice
                ? t(
                    invoiceDownloadUrl
                      ? 'chat.appointmentProposalAction.downloadInvoice'
                      : 'chat.appointmentProposalAction.pendingInvoice'
                  )
                : undefined
            }
            creditNoteTooltipLabel={t(
              'chat.appointmentProposalAction.downloadCreditNote'
            )}
            onCreditNoteDownloadPress={openModalDownloadInvoice}
            actionLabelCreditNote={t(
              `chat.appointmentProposalAction.${
                isMobile ? 'creditNoteMobile' : 'creditNote'
              }`
            )}
            roundedFull={!isModifyProposalActive}
          />
        )}
        {isModifyProposalActive && (
          <ModifyAppointmentBox
            variant={lastProposal.status}
            body={lastProposal.body}
          />
        )}
        {hasBundleRemaining && status === 'NEW' && !isFreeSession && (
          <Box pt="xs">
            <Text color="grey.600" variant="sm">
              {t('chat.appointmentProposalAction.payWithBundleNote')}
            </Text>
          </Box>
        )}
      </div>
    </>
  );
};
