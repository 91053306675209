import * as RadioGroup from '@radix-ui/react-radio-group';
import { Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import KlarnaLogo from '../../../../assets/official_klarna_logo.png';
import { BundleChip } from '../BundleChip';
import { BundleRadioItem } from '../BundleRadioItem';
import { BundlesVariations } from '../../../../types/Bundles';
import {
  getBundleMap,
  getBundlePriceInfo,
  getSingleSession
} from '../bundle-map';

const BULLET_TEXT = '•';

const getFixedAmount = (amount: number, fractionDigits = 2) => {
  if (amount % 1 !== 0) {
    return parseFloat(amount.toFixed(fractionDigits))
      .toString()
      .replace('.', ',');
  }
  return amount.toString();
};

export const BundleFormItem = ({
  bundleId,
  sessionPrice,
  bundlesVariant,
  hasDiscount = true
}: {
  bundleId: string;
  sessionPrice: number;
  bundlesVariant: BundlesVariations;
  hasDiscount?: boolean;
}) => {
  const { t } = useTranslation();
  const bundle = [
    ...getBundleMap(hasDiscount),
    getSingleSession(hasDiscount)
  ].find((elm) => elm.id === bundleId);
  const bundlePriceInfo = getBundlePriceInfo(bundlesVariant, bundleId);

  if (bundle && bundlePriceInfo) {
    const { value, title, finalPriceOutcome, bulletPointList, chips } = bundle;
    const { bundlePrice, numSessions, expireInMonths } = bundlePriceInfo;

    const fullPrice = numSessions * sessionPrice;

    return (
      <BundleRadioItem
        id={bundleId}
        data-testid="bundle-radio-item"
        value={value}
        title={t(title, { numSessions })}
        finalPriceOutcome={t(finalPriceOutcome, {
          sessionPrice,
          bundlePrice,
          numSessions
        })}
      >
        <Stack direction="column" spacing="md">
          <RadioGroup.Indicator>
            {bulletPointList && bulletPointList?.length > 0 && (
              <Stack direction="column" spacing="xs">
                {bulletPointList.map((sentence) => (
                  <Stack spacing="xs" key={sentence.id} align="center">
                    <Text color="candy.600">{BULLET_TEXT}</Text>
                    <Text>
                      {t(sentence.text, {
                        fullPrice: getFixedAmount(fullPrice),
                        bundlePrice: getFixedAmount(bundlePrice),
                        expireInMonths,
                        numSessions,
                        sessionPrice: getFixedAmount(sessionPrice)
                      })}
                    </Text>
                    {t(sentence.text).includes('Klarna') && (
                      <img
                        src={KlarnaLogo}
                        height={40}
                        width={70}
                        alt="Klarna logo"
                      />
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </RadioGroup.Indicator>
          {chips && chips?.length > 0 && (
            <Stack spacing="xs">
              {chips.map((chip) => (
                <BundleChip
                  key={chip.id}
                  bgColor={chip.bgColor}
                  text={t(chip.title, {
                    originalPrice: getFixedAmount(fullPrice)
                  })}
                  textColor={chip.textColor}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </BundleRadioItem>
    );
  }
  return null;
};
