// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';
import type { CamelCasedProperties } from 'type-fest';
import { LaunchDarklyProvider } from '.';

export type LaunchDarklyConfig = Partial<Record<ActiveFeatureFlags, boolean>>;

export enum ActiveFeatureFlags {
  BONUS_PSICOLOGO = 'bonus-psicologo',
  BONUS_PSICOLOGO_THERAPIST = 'bonus-psicologo-therapist',
  CHANGE_EMAIL = 'change-email',
  INDIGO_CHATBOT = 'indigo-chatbot',
  MARK_AS_CHAT = 'mark-as-chat',
  NEW_INVITATIONS_WITHOUT_RANGE = 'new-invitations-without-range',
  PATIENT_TOS_ES = 'patient-tos-es',
  WEEKLY_RECAP_2_WEEKS_BLOCK = 'weekly-recap-2-weeks-block',
  ZENDESK_SSO_ENABLED = 'ZENDESK_SSO_ENABLED',
  NEW_BILLING_PAGE = 'new-billing-page',
  THERAPY_SURVEYS_PT_CONSENT = 'therapy-surveys-pt-consent',
  BASE64_INVOICE = 'base64-invoice',
  TP_NOTES_MOBILE = 'tp-notes-mobile',
  TP_ACQUISITION_INITIATIVE = 'tp-acquisition-initiative',
  DOMICILE_FORM = 'domicile-form',
  PLAN_NEXT_SESSIONS_ALERT = 'plan-next-sessions-alert',
  PT_VOUCHERS = 'pt-vouchers',
  AUTOCOMPLETE_ENABLED = 'AUTOCOMPLETE_ENABLED',
  INCENTIVE_GUIDE_WIDGET = 'INCENTIVE_GUIDE_WIDGET',
  LONG_TERM_INCENTIVE_WIDGET = 'LONG_TERM_INCENTIVE_WIDGET',
  QUALITY_OF_SERVICE = 'quality-of-service',
  TECH_PT_MODIFY_APPOINTMENT_MODAL = 'tech-pt-modify-appointment-modal',
  BUNDLES_VARIATIONS = 'bundles-variations',
  VIDEOCALL_PT_FULLSCREEN = 'videocall-pt-fullscreen',
  BUNDLES_ALERT_PAYMENT_MODAL = 'bundles-alert-payment-modal',
  BUNDLES_PAYWALL_V2 = 'bundle-paywall-v2',
  TP_RATINGS = 'tp-ratings',
  TP_SCHEDULING_FLOW = 'tp-scheduling-flow',
  PT_BUNDLE_INFO = 'pt-bundle-info'
}

export const createFeatureFlagHooks = <Config extends LaunchDarklyConfig>() =>
  useFlags as () => CamelCasedProperties<Config>;

interface FeatureFlagsProviderProps {
  children: ReactNode;
  customAttributes?: Record<string, unknown>;
}

export const createFeatureFlagProvider =
  <Config extends LaunchDarklyConfig>(config: Config) =>
  ({ children, customAttributes }: FeatureFlagsProviderProps) => (
    <LaunchDarklyProvider flags={config} customAttributes={customAttributes}>
      {children}
    </LaunchDarklyProvider>
  );
