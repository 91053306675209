import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useApolloSignupV2Client } from '@unobravo-monorepo/common/hooks/useApolloSignupV2Client';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import { refreshUserInfo } from '@unobravo-monorepo/common/utils/social';
import { useFirebaseUser } from '@unobravo/core';
import { ConfirmActivationDocument } from '../graphql/mutation.visitor.generated';

export const useActivationRegOnTop = () => {
  const { pushAuthenticatedEvent } = useGTM();

  const client = useApolloSignupV2Client();
  const [confirmUserRegOnTop] = useMutation(ConfirmActivationDocument, {
    client
  });

  const { loginFromCustomToken } = useFirebaseUser();

  const verify = async (code: string) => {
    try {
      const response = await confirmUserRegOnTop({
        variables: { token: code }
      });

      const { authToken, visitor } = response.data?.confirmActivation ?? {};

      if (authToken) {
        await loginFromCustomToken(authToken);
        await refreshUserInfo();
      }

      if (visitor) {
        pushAuthenticatedEvent('ActivatedUser', {
          // tp_id: doctor?.id,
          // tp_first_name: doctor?.name,
          // tp_last_name: doctor?.surname,
          email: visitor?.hashedEmail,
          user_id: visitor?.id,
          age: visitor?.age,
          uuid: visitor?.id
        });
      }

      return response;
    } catch (error) {
      captureException(error);
      return null;
    }
  };

  return { verify } as const;
};
