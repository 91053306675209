import { useBillingInfoV2 } from '@unobravo/patient';
import { Cross } from '@unobravo/zenit-icons';
import { ActionIcon, Stack } from '@unobravo/zenit-web';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BundlesVariations } from '../../../../types/Bundles';
import { useBundle } from '../../../bundles/hooks/useBundle';
import { useBundleExperiment } from '../../../bundles/hooks/useBundleExperiment';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { BillingInfo } from '../../BillingInfo';
import { PaymentContext } from '../../types/paymentContext';
import { PaySessionWithBundle } from '../PaySessionWithBundle';
import { Paywall } from './Paywall';
import { Summary } from './Summary';
import { IBundlePriceDetails } from '../../../../shared/components/Bundles/bundle-prices-map';

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Stack
    position="sticky"
    top={0}
    right={0}
    pt="xs"
    pr="xs"
    justify="end"
    style={{ zIndex: 5, marginBottom: -44 }}
  >
    <ActionIcon
      style={{ position: 'absolute', top: 0, right: 0 }}
      type="button"
      data-testid="bundle-pay-session-modal-close"
      variant="filled"
      size="md"
      onClick={onClick}
    >
      <Cross />
    </ActionIcon>
  </Stack>
);

/**
 * STEP 1
 * Bundles paywall
 */
const Step1 = ({
  onNext,
  onBack,
  setSelectedBundleInfo,
  bundleVariant,
  showAlert = true
}: {
  onNext: () => void;
  onBack?: () => void;
  setSelectedBundleInfo: Dispatch<
    SetStateAction<IBundlePriceDetails | undefined>
  >;
  bundleVariant?: BundlesVariations;
  showAlert?: boolean;
}) => {
  const onSubmit = async (values: IBundlePriceDetails | undefined) => {
    if (values && values.numSessions > 1) {
      setSelectedBundleInfo(values);
      onNext();
    } else {
      onBack && onBack();
    }
  };
  return (
    <Paywall
      onBack={onBack}
      onSubmit={onSubmit}
      bundlesVariant={bundleVariant}
      showAlert={showAlert}
    />
  );
};

/**
 * STEP 2
 * Billing Info
 */
const Step2 = ({
  onNext,
  onBack
}: {
  onNext: () => void;
  onBack: () => void;
}) => {
  return <BillingInfo onBack={onBack} onNext={onNext} />;
};

/**
 * STEP 3
 * Summary
 */
const Step3 = ({
  onBack,
  setCurrentStep,
  setIsEditingBillingInfo,
  selectedBundleInfo,
  patientId
}: {
  onBack: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setIsEditingBillingInfo: Dispatch<SetStateAction<boolean>>;
  selectedBundleInfo?: IBundlePriceDetails;
  patientId: number;
}) => {
  return (
    selectedBundleInfo && (
      <Summary
        onEditBillingInfo={() => {
          setIsEditingBillingInfo(true);
          setCurrentStep(2);
        }}
        onBack={onBack}
        bundleInfo={selectedBundleInfo}
        patientId={patientId}
      />
    )
  );
};

/**
 * Bundle Modal component
 */
export const BundleModalContent = ({
  uuid,
  context
}: {
  uuid?: string;
  context: PaymentContext;
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isEditingBillingInfo, setIsEditingBillingInfo] = useState(false);
  const [selectedBundleInfo, setSelectedBundleInfo] =
    useState<IBundlePriceDetails>();

  const navigate = useNavigate();

  const { id: patientId } = usePatient();
  const { hasBundleRemaining, getPathFromContext } = useBundle();
  const { bundleVariant } = useBundleExperiment();

  const { billingInfoData } = useBillingInfoV2(patientId!);

  const skip = () => {
    if (context === 'MOBILE') {
      return navigate(`/patient/mobile/paySession/${uuid}`);
    }

    return navigate(`../${getPathFromContext(context)}`, {
      state: {
        referrer: { path: `${getPathFromContext(context)}/paySession/${uuid}` }
      }
    });
  };

  const close = () => {
    navigate(`../${getPathFromContext(context)}`);
  };

  const nextStep = () => {
    if (currentStep !== 1) {
      setCurrentStep(3);
      return;
    }

    // check if patient has billing info saved
    if (billingInfoData) {
      setCurrentStep(3);
      return;
    }

    setCurrentStep(2);
  };

  const previousStep = () => {
    if (currentStep === 1) {
      skip();
      return;
    }

    if (!billingInfoData) {
      setCurrentStep(currentStep - 1);
      return;
    }

    if (isEditingBillingInfo) {
      setIsEditingBillingInfo(false);
      setCurrentStep(3);
      return;
    }

    setCurrentStep(1);
  };

  const renderStep = () => {
    if (uuid && hasBundleRemaining) {
      return <PaySessionWithBundle uuid={uuid} context={context} />;
    }
    switch (currentStep) {
      case 1:
        return (
          <Step1
            onNext={nextStep}
            {...(uuid && { onBack: skip })}
            setSelectedBundleInfo={setSelectedBundleInfo}
            bundleVariant={bundleVariant}
            showAlert={!hasBundleRemaining}
          />
        );
      case 2:
        return <Step2 onNext={nextStep} onBack={previousStep} />;
      case 3:
        return (
          <Step3
            onBack={previousStep}
            setCurrentStep={setCurrentStep}
            setIsEditingBillingInfo={setIsEditingBillingInfo}
            selectedBundleInfo={selectedBundleInfo}
            patientId={patientId!}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack h="100%" direction="column">
      {context !== 'MOBILE' && <CloseButton onClick={close} />}
      {renderStep()}
    </Stack>
  );
};
