import { ArrowRight } from '@unobravo/zenit-icons';
import { Link, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

const bulletPointList = [
  'bundles.bundleFormV2.termsAndConditions.bulletPointList.item2',
  'bundles.bundleFormV2.termsAndConditions.bulletPointList.item3'
];
const BULLET_TEXT = '•';

export const TemsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      spacing="xs"
      bgColor="cappuccino.50"
      rounded="sm"
      p="sm"
    >
      <Text color="candy.600" fontWeight="bold" variant="sm">
        {t('bundles.bundleFormV2.termsAndConditions.whatToKnow')}
      </Text>
      {bulletPointList && bulletPointList?.length > 0 && (
        <Stack direction="column" spacing="xs">
          {bulletPointList.map((sentence) => (
            <Stack spacing="xs" key={sentence} align="center">
              <Text color="candy.600" variant="sm">
                {BULLET_TEXT}
              </Text>
              <Text color="grey.600" variant="sm">
                {t(sentence)}
              </Text>
            </Stack>
          ))}

          <Link
            to="/docs/bundle-tos.pdf"
            target="_blank"
            color="candy.500"
            style={{
              textDecoration: 'none' // Workaround TODO: to fix underline={false}
            }}
          >
            <Stack direction="row" spacing="2xs" align="center">
              <Text variant="sm" fontWeight="semibold">
                {t('bundles.paywall.termsAndConditions')}
              </Text>
              <ArrowRight size="sm" />
            </Stack>
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
