/* eslint-disable @typescript-eslint/no-unused-vars */
import { useExperiment } from '@statsig/react-bindings';

export const StatsigAATestExperiment = () => {
  const kleinSimpleAATestExperiment = useExperiment(
    'simple-aa-test-user-id-wild-animals'
  );

  const fakeBundleTest = useExperiment('fake-bundles-test');

  const stratifiedTest = useExperiment(
    'aa-test-user-id-stratified-session-bought'
  );

  const userIdTwoTest = useExperiment('aa-test-user-id-two');

  const animal = kleinSimpleAATestExperiment.get('animal', 'alpaca');
  return animal === 'wolf' ? <></> : <></>;
};
