import { RStack, Stack, Text, Box } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { BundleCard } from '../../../shared/components/Bundles/BundleCard';
import { BuyBackCard } from './BuyBackCard';
import { InvoiceBanner } from './InvoiceBanner';
import { useBundle } from '../hooks/useBundle';
import { useBundleExperiment } from '../hooks/useBundleExperiment';

export const BundlePageContent = () => {
  const { t } = useTranslation();
  const { bundlesList, latestBundle, hasBundleRemaining } = useBundle();
  const { isBundleOpened } = useBundleExperiment();

  if (isBundleOpened && (!bundlesList || bundlesList.length === 0))
    return (
      <Stack p="xl" maxW="948px">
        <BuyBackCard />
      </Stack>
    );

  const shouldBuyAgain =
    latestBundle &&
    (latestBundle.sessionsAvailables === 0 ||
      DateTime.fromISO(latestBundle.validUntil) < DateTime.now());

  const pastBundles = bundlesList.slice(
    latestBundle && !shouldBuyAgain ? 1 : 0
  );

  return (
    <>
      {!isBundleOpened && !hasBundleRemaining && (
        <Box p="md">
          <InvoiceBanner />
        </Box>
      )}
      <RStack
        direction={{ base: 'column', sm: 'row' }}
        spacing={{ base: '3xl', sm: 'xl' }}
        py="2xl"
        px={{ base: 'md', sm: 'xl' }}
      >
        {(isBundleOpened || hasBundleRemaining) && (
          <>
            <Stack
              direction="column"
              maxW="948px"
              spacing="md"
              style={{ flex: 1 }}
            >
              <Text variant="2xl" fontWeight="bold">
                {t('bundles.pageSubtitle')}
              </Text>
              {shouldBuyAgain && isBundleOpened ? (
                <BuyBackCard />
              ) : (
                <BundleCard activeBundle bundle={latestBundle} />
              )}
              <InvoiceBanner />
            </Stack>
          </>
        )}

        {bundlesList.length > 0 && pastBundles.length > 0 && (
          <Stack direction="column" spacing="md" style={{ flex: 1 }}>
            <Text variant="2xl" fontWeight="bold">
              {t('bundles.pastBundles')}
            </Text>
            {pastBundles.map((bundle) => {
              return (
                <BundleCard key={bundle?.patientBundleId} bundle={bundle} />
              );
            })}
          </Stack>
        )}
      </RStack>
    </>
  );
};
