export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A local date string (i.e., with no associated timezone) in `yyyy-MM-dd` format, e.g. `2021-03-25`. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm` format, e.g. `14:25`. */
  Hour: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptPatientTosInput = {
  prefPrivacy: Scalars['Boolean'];
  prefTerms: Scalars['Boolean'];
};

export type AcceptedContractDetail = {
  __typename?: 'AcceptedContractDetail';
  acceptedWhen: Scalars['String'];
  version: Scalars['String'];
};

export type AcceptedParticularContractDetail = {
  __typename?: 'AcceptedParticularContractDetail';
  acceptedWhen: Scalars['String'];
  minWeeklyContractHours?: Maybe<Scalars['Int']>;
  version: Scalars['String'];
};

export type AllSessionsAnalytics = {
  __typename?: 'AllSessionsAnalytics';
  details: Array<AllSessionsDetails>;
  fullTotal: Scalars['Int'];
  meta?: Maybe<AllSessionsAnalyticsMeta>;
  reward?: Maybe<Reward>;
  total: Analytics;
};

export type AllSessionsAnalyticsMeta = {
  __typename?: 'AllSessionsAnalyticsMeta';
  coupleFee: Scalars['Int'];
  /** @deprecated No longer supported */
  couplePrice: Scalars['Int'];
  /** @deprecated No longer supported */
  coupleUnobravoFee: Scalars['Int'];
  lastUpdate: Scalars['DateTime'];
  singleFee: Scalars['Int'];
  /** @deprecated No longer supported */
  singlePrice: Scalars['Int'];
  /** @deprecated No longer supported */
  singleUnobravoFee: Scalars['Int'];
};

export type AllSessionsDetails = {
  __typename?: 'AllSessionsDetails';
  amountToSend: Scalars['Int'];
  inapp: PlatformAnalytics;
  outside: PlatformAnalytics;
  outsideUbFeeReport: Array<Maybe<OutsideUbFeeSessionReport>>;
  when: Scalars['String'];
};

export type Analytics = {
  __typename?: 'Analytics';
  amount: Scalars['Int'];
  count: Scalars['Int'];
};

export type AppVersionStatusCheckResult = {
  __typename?: 'AppVersionStatusCheckResult';
  status: AppVersionStatusEnum;
};

export enum AppVersionStatusEnum {
  Broken = 'BROKEN',
  Stable = 'STABLE',
  UpdateAvailable = 'UPDATE_AVAILABLE'
}

export type ApplyVoucherResponse = {
  __typename?: 'ApplyVoucherResponse';
  campaignName?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  failureReason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  total: Scalars['Float'];
  willGenerateInvoice?: Maybe<Scalars['Boolean']>;
};

export type BundleDocument = {
  __typename?: 'BundleDocument';
  amount: Scalars['Int'];
  completed: Scalars['Boolean'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  num: Scalars['String'];
  numSessions: Scalars['Int'];
  type: BundleDocumentTypeEnum;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum BundleDocumentTypeEnum {
  Creditnote = 'creditnote',
  Invoice = 'invoice'
}

export type BundleInvoiceConnection = {
  __typename?: 'BundleInvoiceConnection';
  edges: Array<Maybe<BundleDocument>>;
  hasPreviousPage: Scalars['Boolean'];
};

export enum BundleRefundedEnum {
  Partial = 'partial',
  Total = 'total'
}

export enum CameFromType {
  B2b = 'b2b',
  Event = 'event',
  Facebook = 'facebook',
  Google = 'google',
  Influencer = 'influencer',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Magazine = 'magazine',
  Ooh = 'ooh',
  Other = 'other',
  Radio = 'radio',
  RadioOnly = 'radioOnly',
  Referral = 'referral',
  Social = 'social',
  Tv = 'tv',
  WordOfMouth = 'wordOfMouth',
  Youtube = 'youtube'
}

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String'];
  cardId: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  lastFour: Scalars['String'];
};

export type CityData = {
  __typename?: 'CityData';
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  municipality?: Maybe<Scalars['String']>;
  municipalityCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ConfirmResubmitResponse = {
  __typename?: 'ConfirmResubmitResponse';
  authToken?: Maybe<Scalars['String']>;
};

export type ConfirmUserResponse = {
  __typename?: 'ConfirmUserResponse';
  doctor?: Maybe<DoctorLite>;
  doctors?: Maybe<Array<Maybe<DoctorLite>>>;
  patient?: Maybe<PatientLite>;
  token?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type ContractDetail = {
  __typename?: 'ContractDetail';
  content?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type ContractResponse = {
  __typename?: 'ContractResponse';
  general?: Maybe<AcceptedContractDetail>;
  particular?: Maybe<AcceptedParticularContractDetail>;
  platformCountry?: Maybe<PlatformCountry>;
  type: ContractType;
};

export type ContractResponseV2 = {
  __typename?: 'ContractResponseV2';
  accepted?: Maybe<ContractResponse>;
  current?: Maybe<LastContract>;
  toUpdate?: Maybe<Scalars['Boolean']>;
};

export enum ContractType {
  Hourly = 'hourly',
  MinimumGuaranteed = 'minimum_guaranteed',
  MinimumGuaranteed_15 = 'minimum_guaranteed_15',
  MinimumGuaranteed_20 = 'minimum_guaranteed_20',
  MinimumGuaranteed_25 = 'minimum_guaranteed_25'
}

export type Conversation = {
  __typename?: 'Conversation';
  chatStartedByDoctor?: Maybe<Scalars['Boolean']>;
  chatStartedByPatient?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: Doctor;
  doctorIsTyping: Scalars['Boolean'];
  hasMessagesToReadDoctor?: Maybe<Scalars['Boolean']>;
  hasMessagesToReadPatient?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastMessage?: Maybe<Message>;
  otherUser: DoctorOrPatient;
  patient: Patient;
  patientId: Scalars['Float'];
  patientIsTyping: Scalars['Boolean'];
  patientStatus: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  edges: Array<Maybe<Conversation>>;
  pageInfo: PageInfo;
};

export type ConversationsFilter = {
  hideDeletedPatients?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDiaryTrackInput = {
  activities?: InputMaybe<Array<DiaryActivityName>>;
  mood: DiaryMood;
  text?: InputMaybe<Scalars['String']>;
  when: Scalars['Date'];
};

export type CreateDoctorInput = {
  age: Scalars['Int'];
  countryIsoCode: Scalars['String'];
  email: Scalars['String'];
  firebaseId: Scalars['String'];
  name: Scalars['String'];
  prefTimeAfternoon?: InputMaybe<Scalars['Boolean']>;
  prefTimeEvening?: InputMaybe<Scalars['Boolean']>;
  prefTimeMorning?: InputMaybe<Scalars['Boolean']>;
  sex: DoctorSex;
  surname: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  weeklyWorkingHours?: InputMaybe<Scalars['Int']>;
};

export type CreateDoctorVacationInput = {
  acceptNewMatches?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type CreatePatientInput = {
  age: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  name: Scalars['String'];
  pTest?: InputMaybe<Scalars['String']>;
  pastTherapy?: InputMaybe<PastTherapyEnum>;
  prefForWhom: PatientForWhomPref;
  sendContinue?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  underageConsentFile?: InputMaybe<Scalars['Upload']>;
};

export type CreateSessionInput = {
  confirmed?: InputMaybe<Scalars['Boolean']>;
  day: Scalars['Date'];
  doctorId: Scalars['Float'];
  external?: InputMaybe<Scalars['Boolean']>;
  hour: Scalars['Hour'];
  patientId: Scalars['Float'];
};

export type DailyDiaryTrack = {
  __typename?: 'DailyDiaryTrack';
  track?: Maybe<DiaryTrack>;
  when: Scalars['Date'];
};

export type DataProcessingAgreement = {
  __typename?: 'DataProcessingAgreement';
  createdAt: Scalars['DateTime'];
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  validDpa: Scalars['Boolean'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
  version: Scalars['String'];
};

export type DayAvailabilities = {
  __typename?: 'DayAvailabilities';
  endTime: Scalars['String'];
  id: Scalars['String'];
  startTime: Scalars['String'];
};

export enum DeviceOs {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export enum DiaryActivityName {
  BadSleep = 'bad_sleep',
  Break = 'break',
  Checklist = 'checklist',
  Cinema = 'cinema',
  Cleaning = 'cleaning',
  Colleagues = 'colleagues',
  Concentration = 'concentration',
  Date = 'date',
  Delivery = 'delivery',
  Exam = 'exam',
  Family = 'family',
  FastFood = 'fast_food',
  FilmTv = 'film_tv',
  Friends = 'friends',
  Games = 'games',
  GoodSleep = 'good_sleep',
  GroupProject = 'group_project',
  Gym = 'gym',
  Healthy = 'healthy',
  Home = 'home',
  HomeToWork = 'home_to_work',
  HomeWork = 'home_work',
  Homemade = 'homemade',
  InTime = 'in_time',
  Laundry = 'laundry',
  Lesson = 'lesson',
  LittleSleep = 'little_sleep',
  Meditation = 'meditation',
  Nature = 'nature',
  Office = 'office',
  OutOfMeals = 'out_of_meals',
  Outside = 'outside',
  Overtime = 'overtime',
  Partner = 'partner',
  Party = 'party',
  Procrastination = 'procrastination',
  Reading = 'reading',
  Relax = 'relax',
  Restaurant = 'restaurant',
  School = 'school',
  Shopping = 'shopping',
  SmartWorking = 'smart_working',
  Sport = 'sport',
  StrongDrinks = 'strong_drinks',
  Studying = 'studying',
  Supermarket = 'supermarket',
  TooMuchSleep = 'too_much_sleep',
  Training = 'training',
  Travel = 'travel',
  TwoLOfWater = 'two_l_of_water',
  Vacation = 'vacation',
  Walk = 'walk',
  WorkPermit = 'work_permit',
  Yoga = 'yoga'
}

export enum DiaryMood {
  Bad = 'bad',
  Good = 'good',
  Neutral = 'neutral',
  VeryBad = 'very_bad',
  VeryGood = 'very_good'
}

export type DiaryTrack = {
  __typename?: 'DiaryTrack';
  activities?: Maybe<Array<DiaryActivityName>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  mood: DiaryMood;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
  when: Scalars['Date'];
};

export enum DiscountType {
  Bonus = 'BONUS',
  Bundle = 'BUNDLE',
  GiftCardUnobravo = 'GIFT_CARD_UNOBRAVO',
  Voucher = 'VOUCHER'
}

export type Doctor = {
  __typename?: 'Doctor';
  acceptItalianPatients: Scalars['Boolean'];
  addressStreet?: Maybe<Scalars['String']>;
  age: Scalars['Int'];
  availabilitiesDay: Array<Maybe<Scalars['String']>>;
  available: Scalars['Boolean'];
  bpEnabled: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractResponseV2>;
  contractType: ContractType;
  conversions: Scalars['Int'];
  countryIsoCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  cuData?: Maybe<Array<Maybe<DoctorCuFile>>>;
  curriculums: Array<Maybe<DoctorCurriculum>>;
  description?: Maybe<Scalars['String']>;
  doctorRegisterCountry?: Maybe<Scalars['String']>;
  doctorRegisterNumber?: Maybe<Scalars['String']>;
  doctorRegisterRegion?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  /** @deprecated Changed with invoiceType */
  hasElectronicInvoice: Scalars['Boolean'];
  hasSignedContracts: Scalars['Boolean'];
  id: Scalars['ID'];
  invoiceDelegation: DoctorInvoiceDelegation;
  invoiceType: DoctorInvoiceTypeEnum;
  isSpecializing?: Maybe<Scalars['Boolean']>;
  isSupervisor: Scalars['Boolean'];
  isTherapist?: Maybe<Scalars['Boolean']>;
  /** @deprecated use contract */
  lastContractVersionAccepted?: Maybe<ContractResponse>;
  marketingAcceptedWhen?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  /** @deprecated No longer supported */
  online: Scalars['Boolean'];
  patientHistory?: Maybe<Array<Maybe<PatientDoctorHistory>>>;
  patients?: Maybe<Array<Maybe<Patient>>>;
  phone?: Maybe<Scalars['String']>;
  platformCountry?: Maybe<PlatformCountry>;
  platformDomain?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefTimeAfternoon: Scalars['Boolean'];
  prefTimeEvening: Scalars['Boolean'];
  prefTimeMorning: Scalars['Boolean'];
  professionalAreas?: Maybe<Array<Maybe<ProfessionalAreaEnum>>>;
  profilePicture?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reviews: Array<Maybe<DoctorReview>>;
  sessions: Array<Maybe<Session>>;
  sex: DoctorSex;
  specializationEndDate?: Maybe<Scalars['Date']>;
  specializationStartDate?: Maybe<Scalars['Date']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeOnboardingUrl?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  tags: Array<Maybe<Tag>>;
  tagsValues: Array<Maybe<TagValue>>;
  taxId?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<DoctorTaxRegime>;
  therapeuticOrientation?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  unobravoVote: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  weeklyWorkingHours: Scalars['Int'];
};


export type DoctorSessionsArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type DoctorAgenda = {
  __typename?: 'DoctorAgenda';
  availabilities: Array<DayAvailabilities>;
  date: Scalars['Date'];
  doctorId: Scalars['Float'];
  isVacation: Scalars['Boolean'];
  sessions: Array<Session>;
};

export type DoctorCuFile = {
  __typename?: 'DoctorCuFile';
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  year: Scalars['Int'];
};

export type DoctorCurriculum = {
  __typename?: 'DoctorCurriculum';
  description: Scalars['String'];
  endAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['String']>;
  vote?: Maybe<Scalars['String']>;
};

export enum DoctorIncentiveName {
  Add3or5patients = 'add3or5patients',
  Morepatients = 'morepatients',
  Moresessions = 'moresessions',
  Nightsessions = 'nightsessions',
  Onemorepatient = 'onemorepatient'
}

export type DoctorInvoice = {
  __typename?: 'DoctorInvoice';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  doctorId: Scalars['Int'];
  downloadUrl?: Maybe<Scalars['String']>;
  electronicConfirmed: Scalars['Boolean'];
  externalResponse?: Maybe<ExternalInvoiceServiceResponse>;
  generatedBy?: Maybe<DoctorInvoiceServiceEnum>;
  id: Scalars['String'];
  invoiceDelegationDynamoKey?: Maybe<Scalars['String']>;
  invoiceType: DoctorInvoiceTypeEnum;
  isElectronic: Scalars['Boolean'];
  month: Scalars['Int'];
  platformCountry?: Maybe<PlatformCountry>;
  stamp?: Maybe<Scalars['String']>;
  status?: Maybe<DoctorInvoiceStatus>;
  stripeTransferAmount?: Maybe<Scalars['Int']>;
  stripeTransferId?: Maybe<Scalars['String']>;
  stripeTransferWhen?: Maybe<Scalars['DateTime']>;
  taxRegime: DoctorTaxRegime;
  updatedAt: Scalars['DateTime'];
  uploadedInvoiceWhen?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type DoctorInvoiceDelegation = {
  __typename?: 'DoctorInvoiceDelegation';
  id?: Maybe<Scalars['String']>;
  isSigned: Scalars['Boolean'];
  mandate?: Maybe<Scalars['String']>;
  revocationDate?: Maybe<Scalars['DateTime']>;
  signedDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DoctorInvoiceInput = {
  id: Scalars['String'];
  stamp?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DoctorInvoiceStatus>;
};

export type DoctorInvoiceOverview = {
  __typename?: 'DoctorInvoiceOverview';
  invoices: Array<Maybe<DoctorMonthlyInvoiceOverview>>;
  total: DoctorMonthlyInvoiceTotal;
  year: Scalars['Int'];
};

export enum DoctorInvoiceServiceEnum {
  Fatturapertutti = 'fatturapertutti',
  Unobravoproforma = 'unobravoproforma'
}

export enum DoctorInvoiceStatus {
  Accepted = 'ACCEPTED',
  BillMissing = 'BILL_MISSING',
  Missing = 'MISSING',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Uploaded = 'UPLOADED'
}

export enum DoctorInvoiceTypeEnum {
  Analog = 'analog',
  Delegation = 'delegation',
  Electronic = 'electronic'
}

export type DoctorLite = {
  __typename?: 'DoctorLite';
  age?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type DoctorLockMotivationInput = {
  motivation: LockMotivation;
  notes?: InputMaybe<Scalars['String']>;
};

export type DoctorMonthlyInvoiceOverview = {
  __typename?: 'DoctorMonthlyInvoiceOverview';
  date: Scalars['DateTime'];
  downloadLink?: Maybe<Scalars['String']>;
  invoiceType?: Maybe<DoctorInvoiceTypeEnum>;
  isPaid: Scalars['Boolean'];
  status: DoctorInvoiceStatus;
  total: DoctorMonthlyInvoiceTotal;
};

export type DoctorMonthlyInvoiceTotal = {
  __typename?: 'DoctorMonthlyInvoiceTotal';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type DoctorOrPatient = Doctor | Patient;

export type DoctorOverview = {
  __typename?: 'DoctorOverview';
  available: Scalars['Boolean'];
  /** Calculated from dwh (webapp and outside) */
  averageSessions: Scalars['Float'];
  /** @deprecated Field not used. Deprecated since 3/11/23. */
  constant: Scalars['Int'];
  currentInTherapyPatients: Scalars['Int'];
  /** Only webapp */
  currentWeekFreeSessions: Scalars['Int'];
  currentWeekNewSendings: Scalars['Int'];
  /** Only webapp */
  currentWeekPaidSessions: Scalars['Int'];
  id: Scalars['Float'];
  last90NewConversions: Scalars['Int'];
  last90NewSendings: Scalars['Int'];
  lastLockedAt?: Maybe<Scalars['DateTime']>;
  lastSendingDate?: Maybe<Scalars['DateTime']>;
  lastTerminatedPatientDoctorHistory?: Maybe<LastPatientDoctorHistory>;
  /** @deprecated Field not used. Deprecated since 3/11/23. */
  lockMotivation?: Maybe<LockMotivation>;
  /** @deprecated Field not used and queries the DWH. Deprecated since 3/11/23. */
  maxWeeklyContractHours: Scalars['Int'];
  minWeeklyContractHours: Scalars['Int'];
  /** Only webapp */
  nextWeekFreeSessions: Scalars['Int'];
  /** Only webapp */
  nextWeekPaidSessions: Scalars['Int'];
  selfManagementEnabled: Scalars['Boolean'];
  slotsConfirmed?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
  weeklySlots: Scalars['Int'];
  weeklyWorkingHours: Scalars['Int'];
};

export type DoctorPresentationVariables = {
  __typename?: 'DoctorPresentationVariables';
  completedSessionsCount?: Maybe<Scalars['Int']>;
  degree?: Maybe<Scalars['String']>;
  ongoingPatientsCount?: Maybe<Scalars['Int']>;
  university?: Maybe<Scalars['String']>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export type DoctorRating = {
  __typename?: 'DoctorRating';
  createdAt?: Maybe<Scalars['String']>;
  doctorId?: Maybe<Scalars['Int']>;
  numberOfSession?: Maybe<Scalars['Int']>;
  patientId?: Maybe<Scalars['Int']>;
  patientIdReviewDate?: Maybe<Scalars['String']>;
  review?: Maybe<ReviewType>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type DoctorReview = {
  __typename?: 'DoctorReview';
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorId: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vote: Scalars['Int'];
};

export enum DoctorSex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum DoctorTaxRegime {
  Estero = 'Estero',
  Forfettario = 'Forfettario',
  Impatriati = 'Impatriati',
  Minimi = 'Minimi',
  Ordinario = 'Ordinario'
}

export type DoctorVacation = {
  __typename?: 'DoctorVacation';
  acceptNewMatches?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  end: Scalars['Date'];
  id: Scalars['Float'];
  start: Scalars['Date'];
};

export type DoctorWeeklyAvailabilities = {
  __typename?: 'DoctorWeeklyAvailabilities';
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek: Scalars['String'];
  doctorId: Scalars['Float'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  startTime: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorWeeklyAvailabilitiesInput = {
  dayOfWeek: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type EmailAlreadyExistResponse = {
  __typename?: 'EmailAlreadyExistResponse';
  found?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ExternalInvoiceServiceResponse = {
  __typename?: 'ExternalInvoiceServiceResponse';
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<ExternalStatus>;
  statusDate?: Maybe<Scalars['String']>;
  xmlFile?: Maybe<Scalars['String']>;
  xmlFileName?: Maybe<Scalars['String']>;
};

export enum ExternalStatus {
  Done = 'done',
  Error = 'error',
  Pending = 'pending'
}

export enum FlowType {
  External = 'external',
  Internal = 'internal'
}

export type GetDiaryTracksParamsInput = {
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
};

export type GetMemberGetMemberCodeResponse = {
  __typename?: 'GetMemberGetMemberCodeResponse';
  isEligible: Scalars['Boolean'];
  referralCampaign?: Maybe<ReferralCampaign>;
  referralCode?: Maybe<ReferralCode>;
  rewardCode?: Maybe<RewardCode>;
};

export type GetPatientDoctorNotesParamsInput = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type GetPatientsNotesSummaryParamsInput = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type InAppSurvey = {
  __typename?: 'InAppSurvey';
  createdAt: Scalars['DateTime'];
  doctorId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  inAppSurveyId?: Maybe<InAppSurveyId>;
  patientId?: Maybe<Scalars['Int']>;
  response?: Maybe<QoSSurveyResult>;
  updatedAt: Scalars['DateTime'];
};

export enum InAppSurveyId {
  QosFirstSession = 'qos_first_session',
  QosThirdSession = 'qos_third_session'
}

export type InAppSurveyResponse = {
  __typename?: 'InAppSurveyResponse';
  question: Scalars['String'];
  value: Scalars['Float'];
};

export type InAppSurveyResponseInput = {
  question: Scalars['String'];
  value: Scalars['Float'];
};

export type InformedConsent = {
  __typename?: 'InformedConsent';
  child?: Maybe<InformedConsentRecipient>;
  doctorId: Scalars['Int'];
  id: Scalars['String'];
  partner?: Maybe<InformedConsentRecipient>;
  patient?: Maybe<InformedConsentRecipient>;
  patientId: Scalars['Int'];
  platformCountry?: Maybe<PlatformCountry>;
  signDate?: Maybe<Scalars['DateTime']>;
  signPlace?: Maybe<Scalars['String']>;
  signed: Scalars['Boolean'];
  version?: Maybe<InformedConsentVersion>;
};

export type InformedConsentAttachment = {
  __typename?: 'InformedConsentAttachment';
  downloadLink: Scalars['String'];
  filename: Scalars['String'];
  uploadedAt: Scalars['DateTime'];
};

export type InformedConsentInput = {
  child?: InputMaybe<InformedConsentRecipientInput>;
  id?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<InformedConsentRecipientInput>;
  patient?: InputMaybe<InformedConsentRecipientInput>;
  signDate?: InputMaybe<Scalars['DateTime']>;
  signPlace?: InputMaybe<Scalars['String']>;
  signed: Scalars['Boolean'];
  version?: InputMaybe<Scalars['String']>;
};

export type InformedConsentRecipient = {
  __typename?: 'InformedConsentRecipient';
  birthCountry?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  birthPlace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  residenceCountry?: Maybe<Scalars['String']>;
  residenceProvince?: Maybe<Scalars['String']>;
  residenceStreet?: Maybe<Scalars['String']>;
  residenceTown?: Maybe<Scalars['String']>;
  residenceZip?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
};

export type InformedConsentRecipientInput = {
  birthCountry?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['String']>;
  birthPlace?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  residenceCountry?: InputMaybe<Scalars['String']>;
  residenceProvince?: InputMaybe<Scalars['String']>;
  residenceStreet?: InputMaybe<Scalars['String']>;
  residenceTown?: InputMaybe<Scalars['String']>;
  residenceZip?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
};

export type InformedConsentVersion = {
  __typename?: 'InformedConsentVersion';
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum InvoiceLanguage {
  English = 'ENGLISH',
  Italian = 'ITALIAN',
  Spanish = 'SPANISH',
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  Ar = 'ar',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Bb = 'bb',
  Be = 'be',
  Bg = 'bg',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Cb = 'cb',
  Ce = 'ce',
  Ch = 'ch',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  Db = 'db',
  De = 'de',
  Dv = 'dv',
  Dz = 'dz',
  Eb = 'eb',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Fb = 'fb',
  Ff = 'ff',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gb = 'gb',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  Hb = 'hb',
  He = 'he',
  Hi = 'hi',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Ib = 'ib',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Iu = 'iu',
  Ja = 'ja',
  Jb = 'jb',
  Jv = 'jv',
  Ka = 'ka',
  Kb = 'kb',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mb = 'mb',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nv = 'nv',
  Ny = 'ny',
  Ob = 'ob',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pb = 'pb',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qb = 'qb',
  Qu = 'qu',
  Rb = 'rb',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sb = 'sb',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Tb = 'tb',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ub = 'ub',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Vb = 'vb',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wb = 'wb',
  Wo = 'wo',
  Xb = 'xb',
  Xh = 'xh',
  Yb = 'yb',
  Yi = 'yi',
  Yo = 'yo',
  Za = 'za',
  Zb = 'zb',
  Zh = 'zh',
  Zu = 'zu'
}

export type IsOtherTyping = {
  __typename?: 'IsOtherTyping';
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
  typing: Scalars['Boolean'];
  userId: Scalars['Float'];
};

export type IsUserOnline = {
  __typename?: 'IsUserOnline';
  online: Scalars['Boolean'];
};

export type LastContract = {
  __typename?: 'LastContract';
  general?: Maybe<ContractDetail>;
  particular?: Maybe<ParticularContractDetail>;
  platformCountry?: Maybe<PlatformCountry>;
  type: ContractType;
  validFrom?: Maybe<Scalars['DateTime']>;
};

export type LastPatientDoctorHistory = {
  __typename?: 'LastPatientDoctorHistory';
  id: Scalars['Int'];
  when: Scalars['DateTime'];
};

export enum LaunchDarklyKey {
  AutocompleteEnabled = 'AUTOCOMPLETE_ENABLED',
  BonusPsicologo = 'BONUS_PSICOLOGO',
  BonusPsicologoTherapist = 'BONUS_PSICOLOGO_THERAPIST',
  CameFromEventEnabled = 'CAME_FROM_EVENT_ENABLED',
  NewPricing = 'NEW_PRICING',
  NewSignupMobile = 'NEW_SIGNUP_MOBILE',
  PsychologistBonus = 'PSYCHOLOGIST_BONUS',
  SocialLogin = 'SOCIAL_LOGIN',
  SurveyDownload = 'SURVEY_DOWNLOAD',
  TpNotes = 'TP_NOTES',
  TpNotesPremium = 'TP_NOTES_PREMIUM',
  ZendeskSsoEnabled = 'ZENDESK_SSO_ENABLED'
}

export enum LockMotivation {
  Clinical = 'clinical',
  ClinicalFull = 'clinical_full',
  Final = 'final',
  Full = 'full',
  Health = 'health',
  HealthFull = 'health_full',
  New = 'new',
  NoticePeriod = 'notice_period',
  Ss = 'ss',
  SsFull = 'ss_full',
  Staff = 'staff'
}

export type MarketplaceBundleInvoiceResponse = {
  __typename?: 'MarketplaceBundleInvoiceResponse';
  invoiceDate: Scalars['DateTime'];
  url: Scalars['String'];
};

export type MarketplaceSessionCreditNoteResponse = {
  __typename?: 'MarketplaceSessionCreditNoteResponse';
  creditNoteDate: Scalars['DateTime'];
  url: Scalars['String'];
};

export type MarketplaceSessionInvoiceResponse = {
  __typename?: 'MarketplaceSessionInvoiceResponse';
  invoiceDate: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum MatchAlgorithmVersion {
  Default = 'DEFAULT',
  PreMatch = 'PRE_MATCH',
  PreMatchV1_2 = 'PRE_MATCH_V1_2',
  SeverityClusters = 'SEVERITY_CLUSTERS',
  TopDropPerformer = 'TOP_DROP_PERFORMER'
}

export type Message = {
  __typename?: 'Message';
  content: Scalars['String'];
  conversationId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isMine: Scalars['Boolean'];
  otherUser: DoctorOrPatient;
  readByOther: Scalars['Boolean'];
  sentAt: Scalars['DateTime'];
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['Float']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<Maybe<Message>>;
  pageInfo: PageInfo;
};

export type MobileDevice = {
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptContract?: Maybe<ContractResponse>;
  acceptDataProcessingAgreement: DataProcessingAgreement;
  acceptDoctorSurveyAgreement: SurveyAgreementVersion;
  acceptPatientSurveyAgreement?: Maybe<SurveyAgreementVersion>;
  acceptPatientTos: Patient;
  activatePatient: Scalars['Boolean'];
  addMobileDevice: Scalars['Boolean'];
  applyBpDiscount?: Maybe<ApplyVoucherResponse>;
  applyVoucher?: Maybe<ApplyVoucherResponse>;
  /** @deprecated Mutation no longer supported */
  archivePatient: Scalars['Boolean'];
  /** @deprecated No longer supported */
  associatePatientToDoctor: Patient;
  cancelSession?: Maybe<Session>;
  changePatientEmail: Scalars['Boolean'];
  confirmBonusSession: Scalars['Boolean'];
  confirmChangePatientEmail: Scalars['String'];
  confirmDeletePatient: Scalars['Boolean'];
  confirmElectronicDoctorInvoice?: Maybe<DoctorInvoice>;
  /** @deprecated Docusign no longer supported */
  confirmPatientInformedConsent: Patient;
  confirmResubmit: ConfirmResubmitResponse;
  confirmSocialUser: ConfirmUserResponse;
  /** @deprecated No longer supported */
  confirmUser: ConfirmUserResponse;
  /** @deprecated Mutation no longer supported, use confirmUserV2 specifying algorithmVersion */
  confirmUserPreMatch: ConfirmUserResponse;
  confirmUserV2: ConfirmUserResponse;
  conversationIAmTyping: Scalars['Boolean'];
  createDiaryTrack: DiaryTrack;
  createDoctor: Doctor;
  createDoctorNote: PatientDoctorNote;
  createDoctorVacations: Array<Maybe<DoctorVacation>>;
  createPatient: Patient;
  createPatientBillingInfo: PatientBillingInfo;
  createPatientBonus: PatientBonus;
  createSession: Session;
  createSessions: Array<Session>;
  createStripeCustomerSession: StripeCustomerSession;
  createWalletChargeIntent: Scalars['String'];
  createWalletChargeIntentWithSavedCard: Scalars['String'];
  deleteDataProcessingAgreement?: Maybe<Scalars['Boolean']>;
  deleteDiaryTrack: Scalars['Boolean'];
  deleteDoctor: Scalars['Boolean'];
  deleteDoctorNote: Scalars['Boolean'];
  deleteDoctorUploadedInvoice: Scalars['Boolean'];
  deleteDoctorVacation?: Maybe<DoctorVacation>;
  /** @deprecated Use requestDeletePatient and confirmDeletePatient */
  deletePatient: Scalars['Boolean'];
  deletePatientBonus: Scalars['Boolean'];
  /** @deprecated Docusign no longer supported */
  deletePatientInformedConsent: Patient;
  generateMemberGetMemberReferralCode: ReferralCode;
  generateStripeIntentSecret: Scalars['String'];
  /** @deprecated No longer supported */
  iAmOnline: Scalars['Boolean'];
  /** @deprecated Replaced by payFreeSessionV2 */
  payFreeSession: Scalars['Boolean'];
  payFreeSessionV2: PayFreeSessionResponse;
  paySessionWithBundle: PayWithBundleResult;
  paySessionWithSavedCard?: Maybe<Session>;
  paySessionWithWallet?: Maybe<Session>;
  raiseError: Scalars['String'];
  reassign?: Maybe<ReassignResponse>;
  removeMobileDevice: Scalars['Boolean'];
  removePatientCard?: Maybe<Scalars['Boolean']>;
  requestDeletePatient: Scalars['Boolean'];
  resetSurvey: Scalars['Boolean'];
  resubmitSurvey: Scalars['Boolean'];
  savePatientFreeSessionAvailabilities: PatientFreeSessionAvailabilities;
  savePatientInAppSurveyResponse?: Maybe<InAppSurvey>;
  selectDoctorFromPreMatch: SelectDoctorFromPreMatchResponse;
  sendMessage: Message;
  sendResetPasswordEmail: Scalars['Boolean'];
  sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  setBpEnabledToDoctor: Doctor;
  setDiaryTOSRead: Scalars['Boolean'];
  setDiaryTOSUnread: Scalars['Boolean'];
  setDoctorAvailability?: Maybe<DoctorOverview>;
  setPatientTherapySetting: Scalars['Boolean'];
  setRead: Conversation;
  setUnread: Conversation;
  /** @deprecated No longer supported */
  signup?: Maybe<Patient>;
  signupV2?: Maybe<Patient>;
  suspendTherapyOfPatient: Scalars['Boolean'];
  tipFeedback: Scalars['Boolean'];
  trackLogin: Scalars['Boolean'];
  updateDiaryTrack: DiaryTrack;
  updateDoctor: Doctor;
  updateDoctorConfirmedSlots: Doctor;
  updateDoctorInvoice?: Maybe<DoctorInvoice>;
  updateDoctorInvoiceDelegation: Doctor;
  updateDoctorInvoiceType?: Maybe<DoctorInvoice>;
  updateDoctorNote: PatientDoctorNote;
  updateDoctorVacation: DoctorVacation;
  updateDoctorWeeklyAvailabilities: Array<Maybe<DoctorWeeklyAvailabilities>>;
  updatePatient?: Maybe<Patient>;
  updatePatientBillingInfo?: Maybe<PatientBillingInfo>;
  updatePatientBonus?: Maybe<PatientBonus>;
  updatePatientInformedConsent?: Maybe<Scalars['String']>;
  updatePatientSurvey: Survey;
  updateSession?: Maybe<Session>;
  updateSessionProposal: Session;
  uploadInformedConsentAttachment: InformedConsentAttachment;
  uploadInvoice: UploadedDoctorInvoice;
  upsertDoctorRating?: Maybe<DoctorRating>;
  upsertPatientConsentData: InformedConsent;
  /** @deprecated No longer supported */
  verifyEmailAddress: VerifyEmailResponse;
};


export type MutationAcceptContractArgs = {
  doctorId: Scalars['Float'];
  generalVersion: Scalars['String'];
  minWeeklyContractHours: Scalars['Int'];
  particularVersion: Scalars['String'];
};


export type MutationAcceptDataProcessingAgreementArgs = {
  doctorId: Scalars['Int'];
};


export type MutationAcceptDoctorSurveyAgreementArgs = {
  doctorId: Scalars['Float'];
};


export type MutationAcceptPatientSurveyAgreementArgs = {
  consent3a: Scalars['Boolean'];
  consent3g: Scalars['Boolean'];
  patientId: Scalars['Float'];
};


export type MutationAcceptPatientTosArgs = {
  data: AcceptPatientTosInput;
  patientId: Scalars['Float'];
};


export type MutationActivatePatientArgs = {
  patientId: Scalars['Float'];
};


export type MutationAddMobileDeviceArgs = {
  data: MobileDevice;
  patientId: Scalars['Float'];
};


export type MutationApplyBpDiscountArgs = {
  sessionUUID: Scalars['String'];
};


export type MutationApplyVoucherArgs = {
  code: Scalars['String'];
  sessionUUID: Scalars['String'];
};


export type MutationArchivePatientArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type MutationAssociatePatientToDoctorArgs = {
  patientId: Scalars['Float'];
};


export type MutationCancelSessionArgs = {
  idempotencyKey?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['Float'];
};


export type MutationChangePatientEmailArgs = {
  newEmail: Scalars['String'];
  patientId: Scalars['Float'];
};


export type MutationConfirmBonusSessionArgs = {
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationConfirmChangePatientEmailArgs = {
  token: Scalars['String'];
};


export type MutationConfirmDeletePatientArgs = {
  token: Scalars['String'];
};


export type MutationConfirmElectronicDoctorInvoiceArgs = {
  doctorId: Scalars['Float'];
  invoiceId: Scalars['String'];
};


export type MutationConfirmPatientInformedConsentArgs = {
  patientId: Scalars['Float'];
};


export type MutationConfirmResubmitArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmSocialUserArgs = {
  algorithmVersion?: InputMaybe<MatchAlgorithmVersion>;
  patientId: Scalars['Float'];
};


export type MutationConfirmUserArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmUserPreMatchArgs = {
  token: Scalars['String'];
};


export type MutationConfirmUserV2Args = {
  algorithmVersion?: InputMaybe<MatchAlgorithmVersion>;
  token: Scalars['String'];
};


export type MutationConversationIAmTypingArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
  typing: Scalars['Boolean'];
};


export type MutationCreateDiaryTrackArgs = {
  data: CreateDiaryTrackInput;
  id: Scalars['Int'];
};


export type MutationCreateDoctorArgs = {
  data: CreateDoctorInput;
};


export type MutationCreateDoctorNoteArgs = {
  data: SaveNoteInput;
  doctorId: Scalars['Int'];
  patientId: Scalars['Int'];
  when?: InputMaybe<TpNotesCrudWhen>;
};


export type MutationCreateDoctorVacationsArgs = {
  data: Array<CreateDoctorVacationInput>;
  doctorId: Scalars['Float'];
};


export type MutationCreatePatientArgs = {
  data: CreatePatientInput;
};


export type MutationCreatePatientBillingInfoArgs = {
  data: PatientBillingInfoInput;
  patientId: Scalars['Float'];
};


export type MutationCreatePatientBonusArgs = {
  data: PatientBonusInput;
  patientId: Scalars['Float'];
};


export type MutationCreateSessionArgs = {
  data: CreateSessionInput;
  notifyPatientChat?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateSessionsArgs = {
  data: Array<CreateSessionInput>;
};


export type MutationCreateStripeCustomerSessionArgs = {
  patientId: Scalars['Float'];
};


export type MutationCreateWalletChargeIntentArgs = {
  idempotencyKey?: InputMaybe<Scalars['String']>;
  numberOfSessions: Scalars['Int'];
  patientId: Scalars['Float'];
};


export type MutationCreateWalletChargeIntentWithSavedCardArgs = {
  cardId: Scalars['String'];
  idempotencyKey?: InputMaybe<Scalars['String']>;
  numberOfSessions: Scalars['Int'];
  patientId: Scalars['Float'];
};


export type MutationDeleteDataProcessingAgreementArgs = {
  doctorId: Scalars['Int'];
};


export type MutationDeleteDiaryTrackArgs = {
  id: Scalars['Int'];
  trackId: Scalars['Int'];
};


export type MutationDeleteDoctorArgs = {
  doctorId: Scalars['Float'];
};


export type MutationDeleteDoctorNoteArgs = {
  doctorId: Scalars['Int'];
  noteId: Scalars['Int'];
  when?: InputMaybe<TpNotesCrudWhen>;
};


export type MutationDeleteDoctorUploadedInvoiceArgs = {
  doctorId: Scalars['Float'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationDeleteDoctorVacationArgs = {
  doctorId: Scalars['Float'];
  vacationId: Scalars['Float'];
};


export type MutationDeletePatientArgs = {
  patientId: Scalars['Float'];
};


export type MutationDeletePatientBonusArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePatientInformedConsentArgs = {
  patientId: Scalars['Float'];
};


export type MutationGenerateMemberGetMemberReferralCodeArgs = {
  id: Scalars['Int'];
};


export type MutationGenerateStripeIntentSecretArgs = {
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationPayFreeSessionArgs = {
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationPayFreeSessionV2Args = {
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationPaySessionWithBundleArgs = {
  sessionId: Scalars['Float'];
};


export type MutationPaySessionWithSavedCardArgs = {
  cardId: Scalars['String'];
  idempotencyKey?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationPaySessionWithWalletArgs = {
  patientId: Scalars['Float'];
  sessionId: Scalars['Float'];
};


export type MutationReassignArgs = {
  patientId: Scalars['Float'];
};


export type MutationRemoveMobileDeviceArgs = {
  data: MobileDevice;
  patientId: Scalars['Float'];
};


export type MutationRemovePatientCardArgs = {
  cardId: Scalars['String'];
  patientId: Scalars['Float'];
};


export type MutationRequestDeletePatientArgs = {
  patientId: Scalars['Float'];
};


export type MutationResetSurveyArgs = {
  patientId: Scalars['Float'];
};


export type MutationResubmitSurveyArgs = {
  patientId: Scalars['Float'];
};


export type MutationSavePatientFreeSessionAvailabilitiesArgs = {
  availabilities: Array<PatientFreeSessionAvailabilitiesInput>;
  patientUuid: Scalars['String'];
};


export type MutationSavePatientInAppSurveyResponseArgs = {
  doctorId?: InputMaybe<Scalars['Float']>;
  inAppSurveyId: InAppSurveyId;
  patientId: Scalars['Float'];
  result: SaveQosSurveyInput;
};


export type MutationSelectDoctorFromPreMatchArgs = {
  options: SelectDoctorFromPreMatchOptionsInput;
  patientId: Scalars['Float'];
};


export type MutationSendMessageArgs = {
  data: SendMessageInput;
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
  flowType?: InputMaybe<FlowType>;
  gender?: InputMaybe<DoctorSex>;
  name?: InputMaybe<Scalars['String']>;
  platformCountry?: InputMaybe<PlatformCountry>;
};


export type MutationSendVerificationEmailArgs = {
  algorithmVersion?: InputMaybe<MatchAlgorithmVersion>;
  patientId: Scalars['Int'];
  resend?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetBpEnabledToDoctorArgs = {
  doctorId: Scalars['Float'];
};


export type MutationSetDiaryTosReadArgs = {
  id: Scalars['Int'];
};


export type MutationSetDiaryTosUnreadArgs = {
  id: Scalars['Int'];
};


export type MutationSetDoctorAvailabilityArgs = {
  available?: InputMaybe<Scalars['Boolean']>;
  customSendings?: InputMaybe<Scalars['Int']>;
  doctorId: Scalars['Float'];
  lockMotivation?: InputMaybe<DoctorLockMotivationInput>;
};


export type MutationSetPatientTherapySettingArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
  therapySetting: TherapySettingsEnum;
  therapySettingHour?: InputMaybe<Scalars['String']>;
  therapySettingWeekDay?: InputMaybe<TherapySettingsWeekDay>;
};


export type MutationSetReadArgs = {
  conversationId: Scalars['Float'];
  readerId: Scalars['Float'];
};


export type MutationSetUnreadArgs = {
  conversationId: Scalars['Float'];
};


export type MutationSignupArgs = {
  data: SignupPatientInput;
  patientId: Scalars['Float'];
};


export type MutationSignupV2Args = {
  data: SignupPatientInput;
  patientId: Scalars['Float'];
};


export type MutationSuspendTherapyOfPatientArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type MutationTipFeedbackArgs = {
  patientId: Scalars['Float'];
  score: Scalars['Int'];
  tipCode: Scalars['String'];
};


export type MutationTrackLoginArgs = {
  firebaseId?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDiaryTrackArgs = {
  data: UpdateDiaryTrackInput;
  id: Scalars['Int'];
  trackId: Scalars['Int'];
};


export type MutationUpdateDoctorArgs = {
  data: UpdateDoctorInput;
  doctorId: Scalars['Float'];
};


export type MutationUpdateDoctorConfirmedSlotsArgs = {
  confirmed?: InputMaybe<Scalars['Boolean']>;
  doctorId: Scalars['Int'];
};


export type MutationUpdateDoctorInvoiceArgs = {
  data: DoctorInvoiceInput;
  doctorId: Scalars['Float'];
};


export type MutationUpdateDoctorInvoiceDelegationArgs = {
  data: UpdateDoctorInvoiceDelegationInput;
  doctorId: Scalars['Float'];
};


export type MutationUpdateDoctorInvoiceTypeArgs = {
  doctorId: Scalars['Float'];
  invoiceId: Scalars['String'];
  isElectronic: Scalars['Boolean'];
};


export type MutationUpdateDoctorNoteArgs = {
  data: SaveNoteInput;
  doctorId: Scalars['Int'];
  noteId: Scalars['Int'];
  when?: InputMaybe<TpNotesCrudWhen>;
};


export type MutationUpdateDoctorVacationArgs = {
  data: UpdateDoctorVacationInput;
  doctorId: Scalars['Float'];
};


export type MutationUpdateDoctorWeeklyAvailabilitiesArgs = {
  data: Array<DoctorWeeklyAvailabilitiesInput>;
  doctorId: Scalars['Float'];
};


export type MutationUpdatePatientArgs = {
  data: UpdatePatientInput;
  patientId: Scalars['Float'];
};


export type MutationUpdatePatientBillingInfoArgs = {
  data: PatientBillingInfoInput;
  patientId: Scalars['Float'];
};


export type MutationUpdatePatientBonusArgs = {
  data: PatientBonusInput;
  id: Scalars['ID'];
};


export type MutationUpdatePatientInformedConsentArgs = {
  informedConsent: Scalars['Upload'];
  patientId: Scalars['Float'];
};


export type MutationUpdatePatientSurveyArgs = {
  patientId: Scalars['Float'];
  survey: SurveyInput;
};


export type MutationUpdateSessionArgs = {
  data: UpdateSessionInput;
  sessionId: Scalars['Float'];
};


export type MutationUpdateSessionProposalArgs = {
  body?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['Float'];
};


export type MutationUploadInformedConsentAttachmentArgs = {
  file: Scalars['Upload'];
  patientId: Scalars['Float'];
};


export type MutationUploadInvoiceArgs = {
  base64File?: InputMaybe<Scalars['String']>;
  doctorId: Scalars['Float'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationUpsertDoctorRatingArgs = {
  doctorId: Scalars['Int'];
  patientId: Scalars['Int'];
  review: Review;
};


export type MutationUpsertPatientConsentDataArgs = {
  informedConsent: InformedConsentInput;
  patientId: Scalars['Float'];
};


export type MutationVerifyEmailAddressArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type OutsideUbFeeSessionReport = {
  __typename?: 'OutsideUbFeeSessionReport';
  count: Scalars['Int'];
  ubFee: Scalars['Int'];
  ubFeeTotal: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  firstCursor?: Maybe<Scalars['Float']>;
  hasPreviousPage: Scalars['Boolean'];
};

export type PaginatedDiaryTracks = {
  __typename?: 'PaginatedDiaryTracks';
  data: Array<DailyDiaryTrack>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
};

export type PaginatedDoctorNotes = {
  __typename?: 'PaginatedDoctorNotes';
  currentPage?: Maybe<Scalars['Int']>;
  data: Array<Maybe<PatientDoctorNote>>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginatedPatientsNotesSummary = {
  __typename?: 'PaginatedPatientsNotesSummary';
  currentPage?: Maybe<Scalars['Int']>;
  data: Array<Maybe<PatientsNotesSummary>>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type ParticularContractDetail = {
  __typename?: 'ParticularContractDetail';
  content?: Maybe<Scalars['String']>;
  minWeeklyContractHours?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type PartnerResponse = {
  __typename?: 'PartnerResponse';
  token: Scalars['String'];
};

export enum PastTherapyEnum {
  No = 'NO',
  Ongoing = 'ONGOING',
  Yes = 'YES'
}

export type Patient = {
  __typename?: 'Patient';
  activationLink?: Maybe<Scalars['String']>;
  activeBundle?: Maybe<PatientBundleDetails>;
  age: Scalars['Int'];
  availableFreeSessions?: Maybe<Scalars['Int']>;
  billingInfo?: Maybe<PatientBillingInfo>;
  cameFrom?: Maybe<CameFromType>;
  /** @deprecated Use reassignConditions instead */
  canReassign: Scalars['Boolean'];
  cards: Array<Maybe<Card>>;
  chatStarted?: Maybe<Scalars['Boolean']>;
  consentTS: Scalars['Boolean'];
  converted: Scalars['Boolean'];
  countryIsoCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customToken?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  deletedWhen?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<Doctor>;
  doctorHistory?: Maybe<Array<Maybe<PatientDoctorHistory>>>;
  doctorId?: Maybe<Scalars['Int']>;
  /** @deprecated Changed to doctorHistory */
  doctors: Array<Maybe<PatientDoctorHistory>>;
  email?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  firstSession?: Maybe<Scalars['DateTime']>;
  firstSessionWithCurrentDoctor?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['String']>;
  hasBpCreditsLeft?: Maybe<Scalars['Boolean']>;
  hasConfirmedConsent?: Maybe<Scalars['Boolean']>;
  hashedCity?: Maybe<Scalars['String']>;
  hashedCountry?: Maybe<Scalars['String']>;
  hashedEmail?: Maybe<Scalars['String']>;
  hashedGender?: Maybe<Scalars['String']>;
  hashedName?: Maybe<Scalars['String']>;
  hashedProvince?: Maybe<Scalars['String']>;
  hashedSurname?: Maybe<Scalars['String']>;
  hashedZipCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inAppSurveys?: Maybe<Array<InAppSurvey>>;
  informedConsent?: Maybe<Scalars['String']>;
  informedConsentAttachment?: Maybe<InformedConsentAttachment>;
  informedConsentVersion?: Maybe<Scalars['String']>;
  isBpEnabled?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['Date']>;
  lastMatchAt?: Maybe<Scalars['DateTime']>;
  matchingTags?: Maybe<Array<Maybe<TagDescription>>>;
  name: Scalars['String'];
  nextSessions: Array<Maybe<Session>>;
  numberOfSessions?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  online: Scalars['Boolean'];
  passwordSetted: Scalars['Boolean'];
  pastSessions: SessionConnection;
  phone?: Maybe<Scalars['String']>;
  platformCountry?: Maybe<PlatformCountry>;
  platformDomain?: Maybe<Scalars['String']>;
  prefAge?: Maybe<PatientAgePref>;
  prefDoctorKind?: Maybe<PatientDoctorKindPref>;
  prefForWhom?: Maybe<PatientForWhomPref>;
  prefMarketing?: Maybe<Scalars['Boolean']>;
  prefMarketingAcceptedWhen?: Maybe<Scalars['DateTime']>;
  prefPrivacy: Scalars['Boolean'];
  prefPrivacyAcceptedWhen?: Maybe<Scalars['DateTime']>;
  prefSex?: Maybe<PatientSexPref>;
  prefTerms: Scalars['Boolean'];
  prefTermsAcceptedWhen?: Maybe<Scalars['DateTime']>;
  prefTimeAfternoon: Scalars['Boolean'];
  prefTimeEvening: Scalars['Boolean'];
  prefTimeMorning: Scalars['Boolean'];
  pricing?: Maybe<Pricing>;
  pricingName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  reassignConditions: ReassignConditions;
  sepaDebits: Array<Maybe<SepaDebit>>;
  sessions: SessionConnection;
  sex: PatientSex;
  signUpCompleted: Scalars['Boolean'];
  signupCompletedWhen?: Maybe<Scalars['DateTime']>;
  startTherapyDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PatientStatus>;
  stripeAccountId?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  survey?: Maybe<Survey>;
  /** @deprecated use surveyDownloadUrlV2; deprecated since: 2023/10/18 - v1.90.0; auspiciously dismissed in 3 weeks */
  surveyDownloadUrl?: Maybe<Scalars['String']>;
  surveyDownloadUrlV2?: Maybe<Scalars['String']>;
  surveyId?: Maybe<Scalars['Int']>;
  tags: Array<Maybe<TagDescription>>;
  taxId?: Maybe<Scalars['String']>;
  /** @deprecated Changed to status */
  therapyCompleted: Scalars['Boolean'];
  /** @deprecated No longer supported */
  therapyCompletedWhen?: Maybe<Scalars['DateTime']>;
  therapySetting?: Maybe<TherapySettingsEnum>;
  therapySettingHour?: Maybe<Scalars['String']>;
  therapySettingWeekDay?: Maybe<TherapySettingsWeekDay>;
  timezone?: Maybe<Scalars['String']>;
  underageConsent?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  wallet: Scalars['Int'];
};


export type PatientNextSessionsArgs = {
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
};


export type PatientPastSessionsArgs = {
  before?: InputMaybe<Scalars['Float']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
  last?: Scalars['Int'];
};


export type PatientSessionsArgs = {
  before?: InputMaybe<Scalars['Float']>;
  last?: Scalars['Int'];
};

export type PatientAchievement = {
  __typename?: 'PatientAchievement';
  achieved: Scalars['Boolean'];
  description: Scalars['String'];
  image: Scalars['String'];
  subtitle: Scalars['String'];
  tag: Scalars['String'];
  title: Scalars['String'];
  voucher?: Maybe<VoucherAchievement>;
};

export enum PatientAgePref {
  Adult = 'ADULT',
  None = 'NONE',
  Suitable = 'SUITABLE',
  Young = 'YOUNG'
}

export type PatientAvailabilities = {
  __typename?: 'PatientAvailabilities';
  fromDate: Scalars['String'];
  timeSlots: Array<TimeSlot>;
  toDate: Scalars['String'];
};

export type PatientBillingInfo = {
  __typename?: 'PatientBillingInfo';
  address: Scalars['String'];
  cap: Scalars['String'];
  cf: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryIsoCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  invoiceLanguage: InvoiceLanguage;
  name: Scalars['String'];
  patientId: Scalars['Int'];
  province: Scalars['String'];
  surname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PatientBillingInfoInput = {
  address: Scalars['String'];
  cap: Scalars['String'];
  cf: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryIsoCode?: InputMaybe<Scalars['String']>;
  invoiceLanguage?: InputMaybe<InvoiceLanguage>;
  name: Scalars['String'];
  province: Scalars['String'];
  surname: Scalars['String'];
};

export type PatientBonus = {
  __typename?: 'PatientBonus';
  activationDate: Scalars['DateTime'];
  amount: Scalars['Int'];
  amountUsed: Scalars['Int'];
  bpDiscountAmount?: Maybe<Scalars['Int']>;
  bpDiscountedSessionsLeft?: Maybe<Scalars['Int']>;
  bpSessionsLeft?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  patientId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PatientBonusInput = {
  activationDate: Scalars['DateTime'];
  amount: Scalars['Int'];
  code: Scalars['String'];
};

export type PatientBundleDetails = {
  __typename?: 'PatientBundleDetails';
  bundleId: Scalars['String'];
  buyAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  numSessions: Scalars['Int'];
  patientBundleId: Scalars['String'];
  patientId: Scalars['Float'];
  platformCountry?: Maybe<PlatformCountry>;
  price: Scalars['Int'];
  refunded?: Maybe<BundleRefundedEnum>;
  sessionUsed: Scalars['Int'];
  sessionsAvailables: Scalars['Int'];
  validUntil: Scalars['DateTime'];
};

export type PatientConnection = {
  __typename?: 'PatientConnection';
  edges: Array<Maybe<Patient>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PatientConsentSignUrlResponse = {
  __typename?: 'PatientConsentSignUrlResponse';
  documentId: Scalars['String'];
  envelopeId: Scalars['String'];
  url: Scalars['String'];
};

export enum PatientDoctor {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT'
}

export type PatientDoctorHistory = {
  __typename?: 'PatientDoctorHistory';
  changedWhen?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  id: Scalars['ID'];
  informedConsent?: Maybe<Scalars['String']>;
  patient: Patient;
  /** @deprecated Changed to changedWhen */
  removedWhen?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  why?: Maybe<Scalars['String']>;
};

export enum PatientDoctorKindPref {
  Careful = 'CAREFUL',
  None = 'NONE',
  Proactive = 'PROACTIVE',
  WithProtocols = 'WITH_PROTOCOLS'
}

export type PatientDoctorNote = {
  __typename?: 'PatientDoctorNote';
  createdAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  deletedWhen?: Maybe<Scalars['Date']>;
  doctorId: Scalars['Int'];
  id: Scalars['Int'];
  patientDoctorId: Scalars['Int'];
  patientId: Scalars['Int'];
  patientName?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textSize: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['Int'];
};

export enum PatientForWhomPref {
  Daughter = 'DAUGHTER',
  Me = 'ME',
  Partner = 'PARTNER'
}

export type PatientFreeSessionAvailabilities = {
  __typename?: 'PatientFreeSessionAvailabilities';
  availabilities: Array<PatientAvailabilities>;
  patientUuid: Scalars['String'];
};

export type PatientFreeSessionAvailabilitiesInput = {
  fromDate: Scalars['String'];
  timeSlots: Array<TimeSlotInput>;
  toDate: Scalars['String'];
};

export enum PatientHomepageStepperVariant {
  New = 'NEW',
  Old = 'OLD'
}

export type PatientInvoiceRequestHistory = {
  __typename?: 'PatientInvoiceRequestHistory';
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<PatientInvoiceRequestHistoryStatus>;
};

export enum PatientInvoiceRequestHistoryStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED'
}

export type PatientLite = {
  __typename?: 'PatientLite';
  age?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  hashedEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  sex?: Maybe<PatientSex>;
  uuid?: Maybe<Scalars['String']>;
};

export type PatientOnboarding = {
  __typename?: 'PatientOnboarding';
  status?: Maybe<PatientOnboardingStatus>;
  variant?: Maybe<PatientHomepageStepperVariant>;
};

export enum PatientOnboardingStatus {
  FreeSessionCompleted = 'FREE_SESSION_COMPLETED',
  FreeSessionConfirmed = 'FREE_SESSION_CONFIRMED',
  FreeSessionScheduled = 'FREE_SESSION_SCHEDULED',
  PaidSessionCompleted = 'PAID_SESSION_COMPLETED'
}

export type PatientSessionsAnalyticsResponse = {
  __typename?: 'PatientSessionsAnalyticsResponse';
  cameFrom?: Maybe<CameFromType>;
  firstReply: Scalars['Boolean'];
  firstReplyAllTime: Scalars['Boolean'];
  freeSession: Scalars['Boolean'];
  freeSessionDate?: Maybe<Scalars['DateTime']>;
  lastPaidSessionDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  paidSession: Scalars['Boolean'];
  paidSessionsCount: Scalars['Int'];
  patientId: Scalars['Float'];
  surname?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['DateTime']>;
};

export enum PatientSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN'
}

export enum PatientSexPref {
  Female = 'FEMALE',
  Male = 'MALE',
  None = 'NONE',
  Suitable = 'SUITABLE'
}

export enum PatientStatus {
  Completed = 'COMPLETED',
  FreeSession = 'FREE_SESSION',
  New = 'NEW',
  NotConverted = 'NOT_CONVERTED',
  Ongoing = 'ONGOING',
  Replier = 'REPLIER',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

export type PatientSurveyAgreementStatus = {
  __typename?: 'PatientSurveyAgreementStatus';
  consent3a: Scalars['Boolean'];
  consent3g: Scalars['Boolean'];
};

export enum PatientSurveyVariant {
  Availabilities = 'AVAILABILITIES',
  Control = 'CONTROL',
  Summary = 'SUMMARY'
}

export type PatientsNotesSummary = {
  __typename?: 'PatientsNotesSummary';
  lastNoteDate: Scalars['DateTime'];
  notesCount: Scalars['Int'];
  patientId: Scalars['Int'];
  patientName: Scalars['String'];
};

export enum PayFreeSessionAction {
  None = 'NONE',
  RequestQotConsents = 'REQUEST_QOT_CONSENTS'
}

export type PayFreeSessionResponse = {
  __typename?: 'PayFreeSessionResponse';
  action: PayFreeSessionAction;
  success: Scalars['Boolean'];
};

export type PayWithBundleResult = {
  __typename?: 'PayWithBundleResult';
  patientBundleDetails: Array<Maybe<PatientBundleDetails>>;
  result: Scalars['Boolean'];
  session: Session;
};

export type PlatformAnalytics = {
  __typename?: 'PlatformAnalytics';
  amount: Scalars['Int'];
  count: Scalars['Int'];
  couple: Analytics;
  single: Analytics;
};

export enum PlatformCountry {
  Es = 'ES',
  Fr = 'FR',
  It = 'IT'
}

export type PreMatch = {
  __typename?: 'PreMatch';
  id: Scalars['ID'];
  options: Array<Maybe<PreMatchOption>>;
  requestedAlgorithmVersion?: Maybe<MatchAlgorithmVersion>;
  status: PreMatchStatus;
};

export type PreMatchOption = {
  __typename?: 'PreMatchOption';
  doctor: Doctor;
  doctorId: Scalars['ID'];
  doctorPresentation?: Maybe<DoctorPresentationVariables>;
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  position?: Maybe<Scalars['Int']>;
};

export enum PreMatchStatus {
  Completed = 'completed',
  Expired = 'expired',
  Pending = 'pending'
}

export type Pricing = {
  __typename?: 'Pricing';
  amount: Scalars['Int'];
  fee: Scalars['Int'];
};

export enum ProfessionalAreaEnum {
  Anxiety = 'anxiety',
  Autism = 'autism',
  Couple = 'couple',
  Dependency = 'dependency',
  Depression = 'depression',
  Drugs = 'drugs',
  Expatriation = 'expatriation',
  Food = 'food',
  Grief = 'grief',
  Health = 'health',
  Hypochondria = 'hypochondria',
  Lgbtiq = 'lgbtiq',
  Ocd = 'ocd',
  Panic = 'panic',
  Parenting = 'parenting',
  Phobia = 'phobia',
  Psychosomatic = 'psychosomatic',
  Relationship = 'relationship',
  SelfDevelopment = 'selfDevelopment',
  SelfEsteem = 'selfEsteem',
  SelfHarm = 'selfHarm',
  Sexual = 'sexual',
  Stress = 'stress',
  Trauma = 'trauma',
  Violence = 'violence',
  Work = 'work'
}

export type ProposedSession = {
  __typename?: 'ProposedSession';
  patientId: Scalars['Int'];
  patientName?: Maybe<Scalars['String']>;
  patientSetting?: Maybe<TherapySettingsEnum>;
  when?: Maybe<Scalars['String']>;
};

export type QoSSurveyResult = {
  __typename?: 'QoSSurveyResult';
  doctorConcentration?: Maybe<InAppSurveyResponse>;
  doctorContext?: Maybe<InAppSurveyResponse>;
  relationWithDoctor?: Maybe<InAppSurveyResponse>;
};

export type Query = {
  __typename?: 'Query';
  bundleInvoices?: Maybe<BundleInvoiceConnection>;
  checkAppVersion: AppVersionStatusCheckResult;
  /** @deprecated Query no longer used */
  checkIfEmailAlreadyExist?: Maybe<EmailAlreadyExistResponse>;
  countPatientDoctorNotes: Scalars['Int'];
  /** @deprecated ineffiecient query */
  doctorUnreadMessagesCount: Scalars['Int'];
  downloadPatientDoctorNotes?: Maybe<Scalars['String']>;
  findPatientsByName: PatientConnection;
  getAllSessionsAnalytics: AllSessionsAnalytics;
  getCitiesByName?: Maybe<Array<Maybe<CityData>>>;
  getConversations?: Maybe<ConversationConnection>;
  getCurrentPreMatch: PreMatch;
  getDiaryTracks: PaginatedDiaryTracks;
  getDoctor?: Maybe<Doctor>;
  getDoctorAgenda: Array<Maybe<DoctorAgenda>>;
  getDoctorInvoice?: Maybe<DoctorInvoice>;
  getDoctorInvoices: Array<Maybe<DoctorInvoice>>;
  getDoctorInvoicesOverview: Array<Maybe<DoctorInvoiceOverview>>;
  getDoctorOverview: DoctorOverview;
  getDoctorPresentationVariables?: Maybe<DoctorPresentationVariables>;
  getDoctorVacations: Array<Maybe<DoctorVacation>>;
  getDoctorWeeklyAvailabilities: Array<Maybe<DoctorWeeklyAvailabilities>>;
  getFeatureFlag?: Maybe<Array<Maybe<Scalars['String']>>>;
  getFeatureFlagByKey?: Maybe<Scalars['Boolean']>;
  /** @deprecated switched to property contract of doctor */
  getLastContractVersion?: Maybe<LastContract>;
  getLastDoctorDataProcessingAgreementAccepted?: Maybe<DataProcessingAgreement>;
  getMarketplaceBundleInvoiceUrl: MarketplaceBundleInvoiceResponse;
  getMarketplaceSessionCreditNoteUrl: MarketplaceSessionCreditNoteResponse;
  getMarketplaceSessionInvoiceUrl: MarketplaceSessionInvoiceResponse;
  getMaxNoteTextSize: Scalars['Int'];
  getMemberGetMemberReferralCode?: Maybe<GetMemberGetMemberCodeResponse>;
  /** @deprecated Migrated to getMessagesV2 - Leaving here just to avoid breaking changes */
  getMessages: MessageConnection;
  getMessagesV2: MessageConnection;
  getPatient?: Maybe<Patient>;
  getPatientBillingInfo?: Maybe<PatientBillingInfo>;
  getPatientBillingInfoFromPatientId?: Maybe<PatientBillingInfo>;
  getPatientBonus?: Maybe<PatientBonus>;
  getPatientBonusFromPatientId?: Maybe<PatientBonus>;
  getPatientConsentData?: Maybe<InformedConsent>;
  /** @deprecated Docusign no longer supported */
  getPatientConsentSignUrl?: Maybe<PatientConsentSignUrlResponse>;
  getPatientDoctorHistory?: Maybe<PatientDoctorHistory>;
  getPatientDoctorNotes: PaginatedDoctorNotes;
  getPatientFreeSessionAvailabilities?: Maybe<PatientFreeSessionAvailabilities>;
  getPatientFromFirebaseId?: Maybe<Patient>;
  getPatientOnboardingStatus: PatientOnboarding;
  getPatientSaveCardIntent?: Maybe<Scalars['String']>;
  getPatientSessionsAnalytics: PatientSessionsAnalyticsResponse;
  getPatients: Array<Maybe<Patient>>;
  getPatientsNotesSummary: PaginatedPatientsNotesSummary;
  getPriceAmountForAnonymousPatient: Scalars['Float'];
  getProcessingInvoiceRequestsByPatientId?: Maybe<Array<Maybe<PatientInvoiceRequestHistory>>>;
  getProposedSessions?: Maybe<Array<Maybe<ProposedSession>>>;
  getRegistrationStatus: RegistrationStatus;
  getSession?: Maybe<Session>;
  getSessionAnalytics: SessionAnalytics;
  getSessionByUUID?: Maybe<Session>;
  getSessionUrl?: Maybe<Scalars['String']>;
  getSessions: SessionConnection;
  getSupportedNationalities: Array<Maybe<Scalars['String']>>;
  getSupportedPatientInvoiceLanguages: Array<Maybe<Scalars['String']>>;
  getSurveyPatientVariant?: Maybe<PatientSurveyVariant>;
  getTPNotesDPA: Scalars['String'];
  getTags: Array<Tag>;
  getTherapistFeedbackByPatientId?: Maybe<DoctorRating>;
  getTherapistRatingConfigurationById?: Maybe<TherapistRatingConfiguration>;
  getToken?: Maybe<Scalars['String']>;
  getUploadedDoctorInvoice?: Maybe<UploadedDoctorInvoice>;
  getZendeskToken: Scalars['String'];
  hasDoctorSurveyAgreement?: Maybe<Scalars['Boolean']>;
  hasNotesToDownload: Scalars['Boolean'];
  hasPatientSurveyAgreement?: Maybe<PatientSurveyAgreementStatus>;
  isAppVersionValid?: Maybe<Scalars['Boolean']>;
  isDiaryTOSRead: Scalars['Boolean'];
  isTipFeedbackInserted: Scalars['Boolean'];
  isUserEligible: Scalars['Boolean'];
  listAchievements: Array<PatientAchievement>;
  listPatientBundles?: Maybe<Array<Maybe<PatientBundleDetails>>>;
  /** @deprecated Query no longer supported */
  recoveryPatientByEmail?: Maybe<Scalars['Boolean']>;
  recoveryPatientSignUp?: Maybe<Scalars['String']>;
  sendSurveyRecoveryEmail?: Maybe<Scalars['Boolean']>;
  verifyBpDiscount?: Maybe<ApplyVoucherResponse>;
  verifyPartner?: Maybe<PartnerResponse>;
  verifyRecoverySurveyToken?: Maybe<Scalars['Boolean']>;
  verifyVoucher?: Maybe<ApplyVoucherResponse>;
};


export type QueryBundleInvoicesArgs = {
  page?: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Float'];
};


export type QueryCheckAppVersionArgs = {
  os: DeviceOs;
  version: Scalars['String'];
};


export type QueryCheckIfEmailAlreadyExistArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryCountPatientDoctorNotesArgs = {
  doctorId: Scalars['Int'];
  patientId: Scalars['Int'];
};


export type QueryDoctorUnreadMessagesCountArgs = {
  doctorId: Scalars['Int'];
};


export type QueryDownloadPatientDoctorNotesArgs = {
  doctorId: Scalars['Int'];
  patientId: Scalars['Int'];
};


export type QueryFindPatientsByNameArgs = {
  before?: InputMaybe<Scalars['Float']>;
  doctorId: Scalars['Float'];
  last?: Scalars['Int'];
  name: Scalars['String'];
  passwordSetted?: InputMaybe<Scalars['Boolean']>;
  therapyCompleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetAllSessionsAnalyticsArgs = {
  doctorId: Scalars['Float'];
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetCitiesByNameArgs = {
  cityName: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['Int'];
  platformCountry: PlatformCountry;
};


export type QueryGetConversationsArgs = {
  before?: InputMaybe<Scalars['Float']>;
  doctorId: Scalars['Float'];
  filter?: InputMaybe<ConversationsFilter>;
  last?: Scalars['Int'];
};


export type QueryGetCurrentPreMatchArgs = {
  patientId: Scalars['Float'];
};


export type QueryGetDiaryTracksArgs = {
  id: Scalars['Int'];
  params?: InputMaybe<GetDiaryTracksParamsInput>;
};


export type QueryGetDoctorArgs = {
  id: Scalars['Float'];
};


export type QueryGetDoctorAgendaArgs = {
  doctorId: Scalars['Float'];
  from: Scalars['Date'];
  to: Scalars['Date'];
};


export type QueryGetDoctorInvoiceArgs = {
  doctorId: Scalars['Float'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetDoctorInvoicesArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetDoctorInvoicesOverviewArgs = {
  doctorId: Scalars['Int'];
  from?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};


export type QueryGetDoctorOverviewArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetDoctorPresentationVariablesArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetDoctorVacationsArgs = {
  doctorId: Scalars['Float'];
  fromDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetDoctorWeeklyAvailabilitiesArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetFeatureFlagByKeyArgs = {
  key: LaunchDarklyKey;
};


export type QueryGetLastContractVersionArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetLastDoctorDataProcessingAgreementAcceptedArgs = {
  doctorId: Scalars['Int'];
};


export type QueryGetMarketplaceBundleInvoiceUrlArgs = {
  doctorId: Scalars['Int'];
  patientBundleId: Scalars['String'];
};


export type QueryGetMarketplaceSessionCreditNoteUrlArgs = {
  doctorId: Scalars['Int'];
  sessionUUID: Scalars['String'];
};


export type QueryGetMarketplaceSessionInvoiceUrlArgs = {
  doctorId: Scalars['Int'];
  sessionUUID: Scalars['String'];
};


export type QueryGetMaxNoteTextSizeArgs = {
  doctorId: Scalars['Int'];
};


export type QueryGetMemberGetMemberReferralCodeArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessagesArgs = {
  before?: InputMaybe<Scalars['Float']>;
  doctorId: Scalars['Float'];
  last?: Scalars['Int'];
  patientId: Scalars['Float'];
};


export type QueryGetMessagesV2Args = {
  before?: InputMaybe<Scalars['Float']>;
  doctorId: Scalars['Float'];
  last?: Scalars['Int'];
  patientId: Scalars['Float'];
};


export type QueryGetPatientArgs = {
  id: Scalars['Float'];
};


export type QueryGetPatientBillingInfoArgs = {
  id: Scalars['Float'];
};


export type QueryGetPatientBillingInfoFromPatientIdArgs = {
  patientId: Scalars['Float'];
};


export type QueryGetPatientBonusArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientBonusFromPatientIdArgs = {
  patientId: Scalars['Float'];
};


export type QueryGetPatientConsentDataArgs = {
  id: Scalars['Float'];
};


export type QueryGetPatientConsentSignUrlArgs = {
  id: Scalars['Float'];
};


export type QueryGetPatientDoctorHistoryArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type QueryGetPatientDoctorNotesArgs = {
  doctorId: Scalars['Int'];
  params?: InputMaybe<GetPatientDoctorNotesParamsInput>;
  patientId: Scalars['Int'];
};


export type QueryGetPatientFreeSessionAvailabilitiesArgs = {
  patientUuid: Scalars['String'];
};


export type QueryGetPatientFromFirebaseIdArgs = {
  firebaseId: Scalars['String'];
};


export type QueryGetPatientOnboardingStatusArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type QueryGetPatientSaveCardIntentArgs = {
  idempotencyKey?: InputMaybe<Scalars['String']>;
  patientId: Scalars['Float'];
};


export type QueryGetPatientSessionsAnalyticsArgs = {
  patientId: Scalars['Int'];
};


export type QueryGetPatientsArgs = {
  doctorId: Scalars['Float'];
};


export type QueryGetPatientsNotesSummaryArgs = {
  doctorId: Scalars['Int'];
  params?: InputMaybe<GetPatientsNotesSummaryParamsInput>;
};


export type QueryGetPriceAmountForAnonymousPatientArgs = {
  email?: InputMaybe<Scalars['String']>;
  pTest?: InputMaybe<Scalars['String']>;
  prefForWhom: PatientForWhomPref;
};


export type QueryGetProcessingInvoiceRequestsByPatientIdArgs = {
  patientId: Scalars['Float'];
};


export type QueryGetProposedSessionsArgs = {
  doctorId: Scalars['Int'];
};


export type QueryGetSessionArgs = {
  sessionId: Scalars['Float'];
};


export type QueryGetSessionAnalyticsArgs = {
  doctorId: Scalars['Float'];
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetSessionByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryGetSessionUrlArgs = {
  uuid: Scalars['String'];
};


export type QueryGetSessionsArgs = {
  before?: InputMaybe<Scalars['Float']>;
  doctorId?: InputMaybe<Scalars['Float']>;
  last?: Scalars['Int'];
  patientId?: InputMaybe<Scalars['Float']>;
};


export type QueryGetSurveyPatientVariantArgs = {
  patientUuid: Scalars['String'];
};


export type QueryGetTherapistFeedbackByPatientIdArgs = {
  doctorId: Scalars['Int'];
};


export type QueryGetTherapistRatingConfigurationByIdArgs = {
  patientId: Scalars['Int'];
  therapistRatingConfigurationId: Scalars['String'];
};


export type QueryGetTokenArgs = {
  id: Scalars['Float'];
};


export type QueryGetUploadedDoctorInvoiceArgs = {
  doctorId: Scalars['Float'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryHasDoctorSurveyAgreementArgs = {
  doctorId: Scalars['Float'];
};


export type QueryHasNotesToDownloadArgs = {
  doctorId: Scalars['Int'];
};


export type QueryHasPatientSurveyAgreementArgs = {
  patientId: Scalars['Float'];
};


export type QueryIsAppVersionValidArgs = {
  os: DeviceOs;
  version: Scalars['String'];
};


export type QueryIsDiaryTosReadArgs = {
  id: Scalars['Int'];
};


export type QueryIsTipFeedbackInsertedArgs = {
  patientId: Scalars['Float'];
  tipCode: Scalars['String'];
};


export type QueryIsUserEligibleArgs = {
  id: Scalars['Int'];
};


export type QueryListAchievementsArgs = {
  id: Scalars['Int'];
};


export type QueryListPatientBundlesArgs = {
  patientId: Scalars['Float'];
};


export type QueryRecoveryPatientByEmailArgs = {
  email: Scalars['String'];
};


export type QueryRecoveryPatientSignUpArgs = {
  uid: Scalars['String'];
};


export type QuerySendSurveyRecoveryEmailArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyBpDiscountArgs = {
  sessionUUID: Scalars['String'];
};


export type QueryVerifyPartnerArgs = {
  partnerName: Scalars['String'];
};


export type QueryVerifyRecoverySurveyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyVoucherArgs = {
  code: Scalars['String'];
  sessionUUID: Scalars['String'];
};

export type ReassignConditions = {
  __typename?: 'ReassignConditions';
  automatic: Scalars['Boolean'];
  canPerformSmoothReassign: Scalars['Boolean'];
  withNewSurvey: Scalars['Boolean'];
};

export type ReassignResponse = {
  __typename?: 'ReassignResponse';
  doctorEmail?: Maybe<Scalars['String']>;
  doctorId: Scalars['Float'];
  doctorName: Scalars['String'];
  doctorSurname: Scalars['String'];
};

export type ReferralCampaign = {
  __typename?: 'ReferralCampaign';
  id?: Maybe<Scalars['String']>;
  redemptionsLimit?: Maybe<Scalars['Int']>;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  hasBeenRedeemed: Scalars['Boolean'];
  redemptionsCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type RegistrationStatus = {
  __typename?: 'RegistrationStatus';
  created: Scalars['Boolean'];
  deepAnalysisCompleted: Scalars['Boolean'];
  doctorSelected?: Maybe<Scalars['Boolean']>;
  firstAnalysisCompleted: Scalars['Boolean'];
  initialized: Scalars['Boolean'];
  preferencesCompleted: Scalars['Boolean'];
  signupCompleted: Scalars['Boolean'];
  tosAccepted: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

export type Review = {
  details?: InputMaybe<Array<ReviewDetail>>;
  notes?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};

export type ReviewDetail = {
  question: Scalars['String'];
  rating: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type ReviewDetailType = {
  __typename?: 'ReviewDetailType';
  question: Scalars['String'];
  rating: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type ReviewType = {
  __typename?: 'ReviewType';
  details?: Maybe<Array<ReviewDetailType>>;
  notes?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type Reward = {
  __typename?: 'Reward';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type RewardCode = {
  __typename?: 'RewardCode';
  code: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  redeemedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type RewardThreshold = {
  __typename?: 'RewardThreshold';
  thresholdName: Scalars['String'];
  thresholdValue: Scalars['Int'];
};

export enum Role {
  Admin = 'ADMIN',
  DoctorMatchedAtLeastOnce = 'DOCTOR_MATCHED_AT_LEAST_ONCE',
  IsDoctorOfUser = 'IS_DOCTOR_OF_USER',
  IsPatientOfUser = 'IS_PATIENT_OF_USER',
  SameDoctor = 'SAME_DOCTOR',
  SamePatient = 'SAME_PATIENT',
  UserInConversation = 'USER_IN_CONVERSATION',
  UserInSession = 'USER_IN_SESSION'
}

export type SaveNoteInput = {
  patientName?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  textSize: Scalars['Int'];
  title: Scalars['String'];
};

export type SaveQosSurveyInput = {
  doctorConcentration?: InputMaybe<InAppSurveyResponseInput>;
  doctorContext?: InputMaybe<InAppSurveyResponseInput>;
  relationWithDoctor?: InputMaybe<InAppSurveyResponseInput>;
};

export type SelectDoctorFromPreMatchOptionsInput = {
  doctorId?: InputMaybe<Scalars['Float']>;
  newTp?: InputMaybe<Scalars['Boolean']>;
  randomChoice?: InputMaybe<Scalars['Boolean']>;
};

export type SelectDoctorFromPreMatchResponse = {
  __typename?: 'SelectDoctorFromPreMatchResponse';
  doctor?: Maybe<DoctorLite>;
  id: Scalars['String'];
  patient?: Maybe<PatientLite>;
};

export type SendMessageInput = {
  content: Scalars['String'];
  sessionId?: InputMaybe<Scalars['Float']>;
};

export type SepaDebit = {
  __typename?: 'SepaDebit';
  bankCode?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  sepaId: Scalars['String'];
};

export type Session = {
  __typename?: 'Session';
  amount?: Maybe<Scalars['Int']>;
  canceled: Scalars['Boolean'];
  canceledRefund?: Maybe<Scalars['Boolean']>;
  canceledWhen?: Maybe<Scalars['DateTime']>;
  canceledWhy?: Maybe<Scalars['String']>;
  candidateForFirstPurchase?: Maybe<Scalars['Boolean']>;
  category?: Maybe<SessionCategory>;
  completed: Scalars['Boolean'];
  completedWhen?: Maybe<Scalars['DateTime']>;
  confirmed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  creditNoteDownloadUrl?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  day: Scalars['Date'];
  dayOfWeek: Scalars['String'];
  discount: Scalars['Int'];
  discountType?: Maybe<DiscountType>;
  doctor: Doctor;
  doctorId: Scalars['Int'];
  hasCreditNote?: Maybe<Scalars['Boolean']>;
  hasInvoice?: Maybe<Scalars['Boolean']>;
  hasVoucher?: Maybe<Scalars['Boolean']>;
  hour: Scalars['Hour'];
  id: Scalars['ID'];
  invoiceDownloadUrl?: Maybe<Scalars['String']>;
  isFirstPurchase?: Maybe<Scalars['Boolean']>;
  isFree: Scalars['Boolean'];
  isInvoiceable?: Maybe<Scalars['Boolean']>;
  paid: Scalars['Boolean'];
  paidWhen?: Maybe<Scalars['DateTime']>;
  patient: Patient;
  patientId: Scalars['Int'];
  sessionState?: Maybe<SessionState>;
  sessionsUpdateProposals?: Maybe<Array<Maybe<SessionUpdateProposal>>>;
  /** @deprecated Use generateStripeIntentSecret to get session's stripeIntentSecret */
  stripeIntentSecret: Scalars['String'];
  totalAmount?: Maybe<Scalars['Int']>;
  tpFee?: Maybe<Scalars['Int']>;
  type?: Maybe<SessionType>;
  ubFee?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  voucher?: Maybe<Voucher>;
  when: Scalars['String'];
};


export type SessionDayOfWeekArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type SessionAnalytics = {
  __typename?: 'SessionAnalytics';
  details: Array<Maybe<SessionAnalyticsDetails>>;
  total: Analytics;
};

export type SessionAnalyticsDetails = {
  __typename?: 'SessionAnalyticsDetails';
  amount: Scalars['Int'];
  count: Scalars['Int'];
  couple?: Maybe<Analytics>;
  single?: Maybe<Analytics>;
  when: Scalars['String'];
};

export enum SessionCategory {
  Bonus = 'BONUS',
  External = 'EXTERNAL',
  Free = 'FREE',
  Paid = 'PAID'
}

export type SessionConnection = {
  __typename?: 'SessionConnection';
  edges: Array<Maybe<Session>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SessionNotify = {
  __typename?: 'SessionNotify';
  id: Scalars['String'];
};

export enum SessionState {
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export enum SessionType {
  Couple = 'COUPLE',
  Single = 'SINGLE'
}

export type SessionUpdateProposal = {
  __typename?: 'SessionUpdateProposal';
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: SessionsUpdateProposalStatus;
};

export enum SessionsUpdateProposalStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Submitted = 'submitted'
}

export type SignupPatientInput = {
  email: Scalars['String'];
  pTest?: InputMaybe<Scalars['String']>;
  prefMarketing?: InputMaybe<Scalars['Boolean']>;
  prefPrivacy: Scalars['Boolean'];
  prefTerms: Scalars['Boolean'];
};

export enum SourceEnum {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type StripeCustomerSession = {
  __typename?: 'StripeCustomerSession';
  clientSecret?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['Int']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  conversationIsOtherTyping: IsOtherTyping;
  doctorMessageReceived: Message;
  doctorSessionNotifier: SessionNotify;
  /** @deprecated not used */
  isOnline: IsUserOnline;
  patientMessageReceived: Message;
};


export type SubscriptionConversationIsOtherTypingArgs = {
  doctorId: Scalars['Float'];
  patientId: Scalars['Float'];
};


export type SubscriptionDoctorMessageReceivedArgs = {
  doctorId: Scalars['Float'];
};


export type SubscriptionDoctorSessionNotifierArgs = {
  doctorId: Scalars['Float'];
};


export type SubscriptionIsOnlineArgs = {
  role: PatientDoctor;
  userId: Scalars['Float'];
};


export type SubscriptionPatientMessageReceivedArgs = {
  patientId: Scalars['Float'];
};

export type Survey = {
  __typename?: 'Survey';
  anxiety1: Scalars['Boolean'];
  anxiety2: Scalars['Boolean'];
  anxiety3doc: Scalars['Boolean'];
  anxiety3hypo: Scalars['Boolean'];
  anxiety3panic: Scalars['Boolean'];
  anxiety3phobia: Scalars['Boolean'];
  anxiety3stress: Scalars['Boolean'];
  anxiety3why: Scalars['Boolean'];
  couple1better: Scalars['Boolean'];
  couple1children: Scalars['Boolean'];
  couple1family: Scalars['Boolean'];
  couple1grief: Scalars['Boolean'];
  couple1illness: Scalars['Boolean'];
  couple1separation: Scalars['Boolean'];
  couple1treason: Scalars['Boolean'];
  couple2anxiety: Scalars['Boolean'];
  couple2dependency: Scalars['Boolean'];
  couple2depression: Scalars['Boolean'];
  couple2food: Scalars['Boolean'];
  couple2health: Scalars['Boolean'];
  couple2sex: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deepAnalysisCompleted: Scalars['Boolean'];
  dependency1: Scalars['Boolean'];
  dependency2: Scalars['Boolean'];
  dependency3drugs: Scalars['Boolean'];
  dependency3gamble: Scalars['Boolean'];
  dependency3medications: Scalars['Boolean'];
  dependency3past: Scalars['Boolean'];
  dependency3relationship: Scalars['Boolean'];
  depression1: Scalars['Boolean'];
  depression2: Scalars['Boolean'];
  depression3euphoric: Scalars['Boolean'];
  depression3insignificant: Scalars['Boolean'];
  depression3medications: Scalars['Boolean'];
  depression3sad: Scalars['Boolean'];
  depression3selfharm: Scalars['Boolean'];
  expatriation1: Scalars['Boolean'];
  expatriation2: Scalars['Boolean'];
  extraInfo?: Maybe<Scalars['String']>;
  firstAnalysisCompleted: Scalars['Boolean'];
  food1: Scalars['Boolean'];
  food2: Scalars['Boolean'];
  food3body: Scalars['Boolean'];
  food3disturb: Scalars['Boolean'];
  food3disturbpast: Scalars['Boolean'];
  food3fat: Scalars['Boolean'];
  food3other: Scalars['Boolean'];
  food3uncontrollable: Scalars['Boolean'];
  health1: Scalars['Boolean'];
  health2: Scalars['Boolean'];
  health3autism: Scalars['Boolean'];
  health3cronicserious: Scalars['Boolean'];
  health3family: Scalars['Boolean'];
  health3fertility: Scalars['Boolean'];
  health3grief: Scalars['Boolean'];
  health3malformation: Scalars['Boolean'];
  health3somatic: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  matchWithBp?: Maybe<Scalars['Boolean']>;
  pastTherapy?: Maybe<PastTherapyEnum>;
  placeholder1?: Maybe<Scalars['Boolean']>;
  placeholder2?: Maybe<Scalars['Boolean']>;
  placeholder3?: Maybe<Scalars['Boolean']>;
  placeholder4?: Maybe<Scalars['Boolean']>;
  placeholder5?: Maybe<Scalars['Boolean']>;
  placeholderSevere1: Scalars['Boolean'];
  placeholderSevere2: Scalars['Boolean'];
  placeholderSevere3: Scalars['Boolean'];
  placeholderSevere4: Scalars['Boolean'];
  placeholderSevere5: Scalars['Boolean'];
  placeholderSevere6: Scalars['Boolean'];
  placeholderSevere7: Scalars['Boolean'];
  preferencesCompleted: Scalars['Boolean'];
  relationship1: Scalars['Boolean'];
  relationship2: Scalars['Boolean'];
  relationship3endrelation: Scalars['Boolean'];
  relationship3family: Scalars['Boolean'];
  relationship3grief: Scalars['Boolean'];
  relationship3parenting: Scalars['Boolean'];
  relationship3partner: Scalars['Boolean'];
  selfdevelopment1: Scalars['Boolean'];
  selfesteem1: Scalars['Boolean'];
  sexual1: Scalars['Boolean'];
  sexual2: Scalars['Boolean'];
  sexual3difficult: Scalars['Boolean'];
  sexual3ease: Scalars['Boolean'];
  sexual3lgbtiq: Scalars['Boolean'];
  sexual3orientation: Scalars['Boolean'];
  sexual3satisfaction: Scalars['Boolean'];
  source?: Maybe<SourceEnum>;
  trauma1: Scalars['Boolean'];
  trauma2: Scalars['Boolean'];
  trauma3abortion: Scalars['Boolean'];
  trauma3grief: Scalars['Boolean'];
  trauma3harm: Scalars['Boolean'];
  trauma3illness: Scalars['Boolean'];
  trauma3other: Scalars['Boolean'];
  trauma3sexual: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  work1: Scalars['Boolean'];
  work2: Scalars['Boolean'];
};

export type SurveyAgreementVersion = {
  __typename?: 'SurveyAgreementVersion';
  platformCountry: PlatformCountry;
  validFrom: Scalars['String'];
  validTo?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type SurveyInput = {
  anxiety1?: InputMaybe<Scalars['Boolean']>;
  anxiety2?: InputMaybe<Scalars['Boolean']>;
  anxiety3doc?: InputMaybe<Scalars['Boolean']>;
  anxiety3hypo?: InputMaybe<Scalars['Boolean']>;
  anxiety3panic?: InputMaybe<Scalars['Boolean']>;
  anxiety3phobia?: InputMaybe<Scalars['Boolean']>;
  anxiety3stress?: InputMaybe<Scalars['Boolean']>;
  anxiety3why?: InputMaybe<Scalars['Boolean']>;
  couple1better?: InputMaybe<Scalars['Boolean']>;
  couple1children?: InputMaybe<Scalars['Boolean']>;
  couple1family?: InputMaybe<Scalars['Boolean']>;
  couple1grief?: InputMaybe<Scalars['Boolean']>;
  couple1illness?: InputMaybe<Scalars['Boolean']>;
  couple1separation?: InputMaybe<Scalars['Boolean']>;
  couple1treason?: InputMaybe<Scalars['Boolean']>;
  couple2anxiety?: InputMaybe<Scalars['Boolean']>;
  couple2dependency?: InputMaybe<Scalars['Boolean']>;
  couple2depression?: InputMaybe<Scalars['Boolean']>;
  couple2food?: InputMaybe<Scalars['Boolean']>;
  couple2health?: InputMaybe<Scalars['Boolean']>;
  couple2sex?: InputMaybe<Scalars['Boolean']>;
  deepAnalysisCompleted?: InputMaybe<Scalars['Boolean']>;
  dependency1?: InputMaybe<Scalars['Boolean']>;
  dependency2?: InputMaybe<Scalars['Boolean']>;
  dependency3drugs?: InputMaybe<Scalars['Boolean']>;
  dependency3gamble?: InputMaybe<Scalars['Boolean']>;
  dependency3medications?: InputMaybe<Scalars['Boolean']>;
  dependency3past?: InputMaybe<Scalars['Boolean']>;
  dependency3relationship?: InputMaybe<Scalars['Boolean']>;
  depression1?: InputMaybe<Scalars['Boolean']>;
  depression2?: InputMaybe<Scalars['Boolean']>;
  depression3euphoric?: InputMaybe<Scalars['Boolean']>;
  depression3insignificant?: InputMaybe<Scalars['Boolean']>;
  depression3medications?: InputMaybe<Scalars['Boolean']>;
  depression3sad?: InputMaybe<Scalars['Boolean']>;
  depression3selfharm?: InputMaybe<Scalars['Boolean']>;
  expatriation1?: InputMaybe<Scalars['Boolean']>;
  expatriation2?: InputMaybe<Scalars['Boolean']>;
  extraInfo?: InputMaybe<Scalars['String']>;
  firstAnalysisCompleted?: InputMaybe<Scalars['Boolean']>;
  food1?: InputMaybe<Scalars['Boolean']>;
  food2?: InputMaybe<Scalars['Boolean']>;
  food3body?: InputMaybe<Scalars['Boolean']>;
  food3disturb?: InputMaybe<Scalars['Boolean']>;
  food3disturbpast?: InputMaybe<Scalars['Boolean']>;
  food3fat?: InputMaybe<Scalars['Boolean']>;
  food3other?: InputMaybe<Scalars['Boolean']>;
  food3uncontrollable?: InputMaybe<Scalars['Boolean']>;
  health1?: InputMaybe<Scalars['Boolean']>;
  health2?: InputMaybe<Scalars['Boolean']>;
  health3autism?: InputMaybe<Scalars['Boolean']>;
  health3cronicserious?: InputMaybe<Scalars['Boolean']>;
  health3family?: InputMaybe<Scalars['Boolean']>;
  health3fertility?: InputMaybe<Scalars['Boolean']>;
  health3grief?: InputMaybe<Scalars['Boolean']>;
  health3malformation?: InputMaybe<Scalars['Boolean']>;
  health3somatic?: InputMaybe<Scalars['Boolean']>;
  matchWithBp?: InputMaybe<Scalars['Boolean']>;
  pastTherapy?: InputMaybe<PastTherapyEnum>;
  placeholder1?: InputMaybe<Scalars['Boolean']>;
  placeholder2?: InputMaybe<Scalars['Boolean']>;
  placeholder3?: InputMaybe<Scalars['Boolean']>;
  placeholder4?: InputMaybe<Scalars['Boolean']>;
  placeholder5?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere1?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere2?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere3?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere4?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere5?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere6?: InputMaybe<Scalars['Boolean']>;
  placeholderSevere7?: InputMaybe<Scalars['Boolean']>;
  preferencesCompleted?: InputMaybe<Scalars['Boolean']>;
  relationship1?: InputMaybe<Scalars['Boolean']>;
  relationship2?: InputMaybe<Scalars['Boolean']>;
  relationship3endrelation?: InputMaybe<Scalars['Boolean']>;
  relationship3family?: InputMaybe<Scalars['Boolean']>;
  relationship3grief?: InputMaybe<Scalars['Boolean']>;
  relationship3parenting?: InputMaybe<Scalars['Boolean']>;
  relationship3partner?: InputMaybe<Scalars['Boolean']>;
  selfdevelopment1?: InputMaybe<Scalars['Boolean']>;
  selfesteem1?: InputMaybe<Scalars['Boolean']>;
  sexual1?: InputMaybe<Scalars['Boolean']>;
  sexual2?: InputMaybe<Scalars['Boolean']>;
  sexual3difficult?: InputMaybe<Scalars['Boolean']>;
  sexual3ease?: InputMaybe<Scalars['Boolean']>;
  sexual3lgbtiq?: InputMaybe<Scalars['Boolean']>;
  sexual3orientation?: InputMaybe<Scalars['Boolean']>;
  sexual3satisfaction?: InputMaybe<Scalars['Boolean']>;
  trauma1?: InputMaybe<Scalars['Boolean']>;
  trauma2?: InputMaybe<Scalars['Boolean']>;
  trauma3abortion?: InputMaybe<Scalars['Boolean']>;
  trauma3grief?: InputMaybe<Scalars['Boolean']>;
  trauma3harm?: InputMaybe<Scalars['Boolean']>;
  trauma3illness?: InputMaybe<Scalars['Boolean']>;
  trauma3other?: InputMaybe<Scalars['Boolean']>;
  trauma3sexual?: InputMaybe<Scalars['Boolean']>;
  work1?: InputMaybe<Scalars['Boolean']>;
  work2?: InputMaybe<Scalars['Boolean']>;
};

export enum TpNotesCrudWhen {
  Default = 'DEFAULT',
  DuringAppointment = 'DURING_APPOINTMENT'
}

export type Tag = {
  __typename?: 'Tag';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export enum TagDescription {
  Anxiety = 'anxiety',
  AnxietySevere = 'anxietySevere',
  AnxietySevereDoc = 'anxietySevereDoc',
  AnxietySevereHypo = 'anxietySevereHypo',
  AnxietySeverePanic = 'anxietySeverePanic',
  AnxietySeverePhobia = 'anxietySeverePhobia',
  AnxietySevereStress = 'anxietySevereStress',
  CoupleTherapy = 'coupleTherapy',
  Dependency = 'dependency',
  DependencySevere = 'dependencySevere',
  DependencySevereDrugs = 'dependencySevereDrugs',
  Depression = 'depression',
  DepressionSevere = 'depressionSevere',
  DepressionSevereSelfharm = 'depressionSevereSelfharm',
  Expatriation = 'expatriation',
  ExpatriationSevere = 'expatriationSevere',
  Food = 'food',
  FoodSevere = 'foodSevere',
  General = 'general',
  Health = 'health',
  HealthSevere = 'healthSevere',
  HealthSevereAutism = 'healthSevereAutism',
  HealthSevereSomatic = 'healthSevereSomatic',
  Relationship = 'relationship',
  RelationshipSevere = 'relationshipSevere',
  RelationshipSevereGrief = 'relationshipSevereGrief',
  RelationshipSevereParenting = 'relationshipSevereParenting',
  SelfDevelopment = 'selfDevelopment',
  SelfEsteem = 'selfEsteem',
  Sexual = 'sexual',
  SexualSevere = 'sexualSevere',
  SexualSevereLgbtiq = 'sexualSevereLgbtiq',
  Trauma = 'trauma',
  TraumaSevere = 'traumaSevere',
  TraumaSevereViolence = 'traumaSevereViolence',
  Work = 'work',
  WorkSevere = 'workSevere'
}

export type TagValue = {
  __typename?: 'TagValue';
  tag: TagDescription;
  value: Scalars['Int'];
};

export type TherapistRatingConfiguration = {
  __typename?: 'TherapistRatingConfiguration';
  data: Array<TherapistRatingConfigurationData>;
  id: Scalars['String'];
};

export type TherapistRatingConfigurationData = {
  __typename?: 'TherapistRatingConfigurationData';
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  question: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export enum TherapySettingsEnum {
  EveryFiveWeeks = 'everyFiveWeeks',
  EveryFourWeeks = 'everyFourWeeks',
  EveryThreeWeeks = 'everyThreeWeeks',
  EveryTwoWeeks = 'everyTwoWeeks',
  /** @deprecated No longer supported */
  Monthly = 'monthly',
  NotSet = 'notSet',
  Weekly = 'weekly'
}

export enum TherapySettingsWeekDay {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export type TimeSlot = {
  __typename?: 'TimeSlot';
  fromHour: Scalars['String'];
  toHour: Scalars['String'];
};

export type TimeSlotInput = {
  fromHour: Scalars['String'];
  toHour: Scalars['String'];
};

export type UpdateDiaryTrackInput = {
  activities?: InputMaybe<Array<DiaryActivityName>>;
  mood: DiaryMood;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateDoctorInput = {
  age?: InputMaybe<Scalars['Int']>;
  available?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  countryIsoCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  doctorRegisterCountry?: InputMaybe<Scalars['String']>;
  doctorRegisterNumber?: InputMaybe<Scalars['String']>;
  doctorRegisterRegion?: InputMaybe<Scalars['String']>;
  fiscalCode?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<DoctorInvoiceTypeEnum>;
  isSpecializing?: InputMaybe<Scalars['Boolean']>;
  marketingAccepted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefTimeAfternoon?: InputMaybe<Scalars['Boolean']>;
  prefTimeEvening?: InputMaybe<Scalars['Boolean']>;
  prefTimeMorning?: InputMaybe<Scalars['Boolean']>;
  profilePictureFile?: InputMaybe<Scalars['Upload']>;
  province?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<DoctorSex>;
  specializationEndDate?: InputMaybe<Scalars['Date']>;
  specializationStartDate?: InputMaybe<Scalars['Date']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  taxRegime?: InputMaybe<DoctorTaxRegime>;
  timezone?: InputMaybe<Scalars['String']>;
  weeklyWorkingHours?: InputMaybe<Scalars['Int']>;
};

export type UpdateDoctorInvoiceDelegationInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  fiscalCode: Scalars['String'];
  hasSignedInvoiceDelegation: Scalars['Boolean'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
  streetNumber: Scalars['String'];
  surname: Scalars['String'];
  taxCode: Scalars['String'];
};

export type UpdateDoctorVacationInput = {
  acceptNewMatches?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Float'];
};

export type UpdatePatientInput = {
  cameFrom?: InputMaybe<CameFromType>;
  consentTS?: InputMaybe<Scalars['Boolean']>;
  countryIsoCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  prefAge?: InputMaybe<PatientAgePref>;
  prefDoctorKind?: InputMaybe<PatientDoctorKindPref>;
  prefMarketing?: InputMaybe<Scalars['Boolean']>;
  prefSex?: InputMaybe<PatientSexPref>;
  prefTimeAfternoon?: InputMaybe<Scalars['Boolean']>;
  prefTimeEvening?: InputMaybe<Scalars['Boolean']>;
  prefTimeMorning?: InputMaybe<Scalars['Boolean']>;
  profilePictureFile?: InputMaybe<Scalars['Upload']>;
  sex?: InputMaybe<PatientSex>;
  surname?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  therapyCompleted?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateSessionInput = {
  day?: InputMaybe<Scalars['Date']>;
  hour?: InputMaybe<Scalars['Hour']>;
};

export type UploadedDoctorInvoice = {
  __typename?: 'UploadedDoctorInvoice';
  downloadLink: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['String'];
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  token?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Voucher = {
  __typename?: 'Voucher';
  amount?: Maybe<Scalars['Int']>;
  campaignName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type VoucherAchievement = {
  __typename?: 'VoucherAchievement';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  redeemedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  validUntil: Scalars['DateTime'];
};

export enum VoucherType {
  Amount = 'AMOUNT',
  Percentual = 'PERCENTUAL'
}
