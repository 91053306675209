import { PatientHomepageStepperVariant } from '../../../../generated/patient.generated';
import { useOnboardingStatus } from './useOnboardingStatus';

export const useIsFreeSessionHomeEnable = () => {
  const { variant, queryLoading } = useOnboardingStatus();

  return {
    value: variant === PatientHomepageStepperVariant.New,
    loading: queryLoading
  };
};
