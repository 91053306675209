import {
  ActiveFeatureFlags,
  createFeatureFlagHooks,
  createFeatureFlagProvider,
  LaunchDarklyConfig
} from '@unobravo-monorepo/common/providers/LaunchDarklyProvider/typed';

const patientFlags = {
  [ActiveFeatureFlags.BONUS_PSICOLOGO]: false,
  [ActiveFeatureFlags.INDIGO_CHATBOT]: false,
  [ActiveFeatureFlags.PATIENT_TOS_ES]: false,
  [ActiveFeatureFlags.ZENDESK_SSO_ENABLED]: false,
  [ActiveFeatureFlags.CHANGE_EMAIL]: false,
  [ActiveFeatureFlags.THERAPY_SURVEYS_PT_CONSENT]: false,
  [ActiveFeatureFlags.PLAN_NEXT_SESSIONS_ALERT]: false,
  [ActiveFeatureFlags.PT_VOUCHERS]: false,
  [ActiveFeatureFlags.QUALITY_OF_SERVICE]: false,
  [ActiveFeatureFlags.TECH_PT_MODIFY_APPOINTMENT_MODAL]: false,
  [ActiveFeatureFlags.BUNDLES_VARIATIONS]: false,
  [ActiveFeatureFlags.VIDEOCALL_PT_FULLSCREEN]: false,
  [ActiveFeatureFlags.BUNDLES_ALERT_PAYMENT_MODAL]: false,
  [ActiveFeatureFlags.BUNDLES_PAYWALL_V2]: false,
  [ActiveFeatureFlags.TP_RATINGS]: false
} satisfies LaunchDarklyConfig;

export const usePatientFeatureFlags =
  createFeatureFlagHooks<typeof patientFlags>();

export const PatientFeatureFlagProvider =
  createFeatureFlagProvider(patientFlags);
