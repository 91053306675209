import { ArrowDown, ArrowRight, ArrowUp } from '@unobravo/zenit-icons';
import { Box, Link, Stack, Text } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '../../../../../common/src/components/Divider';
import { BoxIcon } from '../../../../../common/src/components/Icons';
import { BundleType } from '../../../types/Bundles';
import { BundleCardTracker } from './BundleCardTracker';
import { BundleChip } from './BundleChip';

const formatDate = (date: string) =>
  DateTime.fromISO(date).toFormat('dd/MM/yyyy');

const CompletedChip = () => {
  const { t } = useTranslation();

  return (
    <BundleChip
      text={t('bundles.inactiveCard.completedChipLabel')}
      textColor="grey.900"
      bgColor="spinach.100"
    />
  );
};

const ExpiredChip = () => {
  const { t } = useTranslation();

  return (
    <BundleChip
      text={t('bundles.inactiveCard.expiredChipLabel')}
      textColor="grey.900"
      bgColor="grey.50"
    />
  );
};

const InactiveChip = () => {
  const { t } = useTranslation();

  return (
    <BundleChip
      text={t('bundles.inactiveCard.chipLabel')}
      textColor="grey.900"
      bgColor="cappuccino.100"
      icon={<BoxIcon />}
    />
  );
};

const ActiveChip = () => {
  const { t } = useTranslation();

  return (
    <BundleChip
      text={t('bundles.activeCard.chipLabel')}
      textColor="grey.900"
      bgColor="spinach.100"
      icon={<BoxIcon />}
    />
  );
};

const TrackerWrapper = ({
  validUntil,
  children
}: PropsWithChildren<{ validUntil: string }>) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" pt="lg" spacing="sm">
      <Box py="2xs">
        <Divider type="horizontal" />
      </Box>
      {children}
      <Text color="grey.600">
        {t('bundles.activeCard.expiryLabel', {
          bundleExpDate: formatDate(validUntil)
        })}
      </Text>
      <Link
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        color="candy.500"
        style={{
          textDecoration: 'none'
        }}
        href="/docs/bundle-tos.pdf"
      >
        <Stack align="center">
          <Text color="candy.500" fontWeight="semibold" variant="sm">
            {t('bundles.paywall.termsAndConditions')}
          </Text>
          <ArrowRight size="sm" color="candy.500" />
        </Stack>
      </Link>
    </Stack>
  );
};

export const BundleCard = ({
  bundle,
  isInHomepage = false,
  activeBundle = false
}: {
  bundle?: BundleType;
  isInHomepage?: boolean;
  activeBundle?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(activeBundle);

  const onClick = () => {
    if (isInHomepage) {
      navigate('../bundles');
      return;
    }

    setOpen(!open);
  };

  if (!bundle) {
    return (
      <Stack
        direction="column"
        p="md"
        rounded="lg"
        border="md"
        borderColor="cappuccino.100"
        style={{
          cursor: 'pointer'
        }}
        data-testid="bundle-card-bundle-pay-session"
        onClick={() => {
          navigate(`../dialog/bundles/bundlePaySession`, {
            state: {
              modal: location
            }
          });
        }}
      >
        <Stack direction="column" spacing="xs">
          <Stack direction="row" justify="space-between" w="100%">
            <InactiveChip />
            <ArrowRight />
          </Stack>

          <Text variant="xl" fontWeight="bold">
            {t('bundles.inactiveCard.title')}
          </Text>
          <Text variant="md" color="grey.600">
            {t('bundles.inactiveCard.subtitle')}
          </Text>
        </Stack>
      </Stack>
    );
  }

  const expired = DateTime.fromISO(bundle.validUntil) < DateTime.now();
  const completed = bundle.sessionsAvailables === 0;

  if (completed || expired) {
    return (
      <Stack
        direction="column"
        p="md"
        rounded="lg"
        border="md"
        data-testid={`bundle-card-bundle-${
          completed ? 'completed' : 'expired'
        }`}
        borderColor="cappuccino.100"
        style={{
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <Stack direction="column" spacing="xs">
          <Stack direction="row" justify="space-between" w="100%">
            {isInHomepage ? (
              <>
                <InactiveChip />
                <ArrowRight />
              </>
            ) : (
              <>
                {completed && <CompletedChip />}
                {!completed && expired && <ExpiredChip />}
                {open ? <ArrowUp /> : <ArrowDown />}
              </>
            )}
          </Stack>

          <Text variant="xl" fontWeight="bold">
            {t('bundles.inactiveCard.title')}
          </Text>

          {completed && (
            <Text variant="md" color="grey.600">
              {isInHomepage
                ? t('bundles.inactiveCard.pastCompletedSubtitle', {
                    numSessions: bundle.numSessions
                  })
                : t('bundles.altCardSubtitle', {
                    bundlePrice: bundle.price / 100,
                    activationDate: formatDate(bundle.buyAt),
                    numSessions: bundle.numSessions
                  })}
            </Text>
          )}
          {!completed && expired && (
            <Text variant="md" color="grey.600">
              {t('bundles.inactiveCard.pastExpiredSubtitle', {
                numSessions: bundle.numSessions
              })}
            </Text>
          )}

          {open && (
            <TrackerWrapper validUntil={bundle.validUntil}>
              <BundleCardTracker bundle={bundle} />
            </TrackerWrapper>
          )}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      p="md"
      bgColor="spinach.50"
      rounded="lg"
      data-testid="bundle-card"
      spacing={isInHomepage ? 'xl' : 'sm'}
      style={{
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Stack direction="column" spacing="sm">
        <Stack direction="row" justify="space-between" w="100%">
          <ActiveChip />
          {isInHomepage ? (
            <ArrowRight />
          ) : (
            <>{open ? <ArrowUp /> : <ArrowDown />}</>
          )}
        </Stack>

        <Text variant="xl" fontWeight="bold">
          {t('bundles.activeCard.cardTitle', {
            numSessions: bundle.numSessions
          })}
        </Text>
      </Stack>

      {isInHomepage ? (
        <>
          <BundleCardTracker bundle={bundle} />
        </>
      ) : (
        <>
          <Text variant="md" color="grey.600">
            {t('bundles.altCardSubtitle', {
              bundlePrice: bundle.price / 100,
              activationDate: formatDate(bundle.buyAt),
              numSessions: bundle.numSessions
            })}
          </Text>
          {open && (
            <TrackerWrapper validUntil={bundle.validUntil}>
              <BundleCardTracker bundle={bundle} />
            </TrackerWrapper>
          )}
        </>
      )}
    </Stack>
  );
};
