import * as RadioGroup from '@radix-ui/react-radio-group';
import { Stack, Text } from '@unobravo/zenit-web';

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RadioGroupIndicatorContainer = styled(Stack)`
  display: block;
  border: ${({ theme }) => `1.5px solid ${theme.colors.grey[500]}`};
`;

const RadioGroupIndicatorDot = styled(RadioGroup.Indicator)`
  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.candy[500]};
  }
`;
/** needed for data-state="checked" styling */
const RadioItem = styled(Stack)`
  cursor: pointer;
`;

const RadioItemContainer = styled(RadioGroup.Item)`
  /* RadioGroupIndicatorContainer data-state="checked" styled  */
  &[data-state='checked'] ${RadioGroupIndicatorContainer} {
    border: ${({ theme }) => `1.5px solid ${theme.colors.candy[500]}`};
  }
  /* RadioItem data-state="checked" styled */
  &[data-state='checked'] ${RadioItem} {
    border: ${({ theme }) => `1.5px solid ${theme.colors.candy[500]}`};
    outline: ${({ theme }) => `2px solid ${theme.colors.candy[100]}`};
  }
`;

const FinalPriceOutcomeComponent = ({
  integerPart,
  decimalPart,
  label
}: {
  integerPart?: string;
  decimalPart?: string;
  label: string;
}) => (
  <Stack direction="row">
    <Text color="grey.900" fontWeight="bold" variant="xl">
      {integerPart}
    </Text>
    <Text color="grey.900" fontWeight="bold" variant="xs">
      &nbsp;,{decimalPart}€ &nbsp;
    </Text>
    <Text color="grey.900" fontWeight="bold" variant="xl">
      {label}
    </Text>
  </Stack>
);

export const BundleRadioItem = ({
  id,
  value,
  title,
  linethroughPrice,
  finalPriceOutcome,
  integerPart,
  decimalPart,
  children
}: {
  id: string;
  value: string;
  title: string;
  linethroughPrice?: number;
  finalPriceOutcome?: string;
  children?: ReactNode;
  integerPart?: string;
  decimalPart?: string;
}) => {
  const { t } = useTranslation();
  return (
    <RadioItemContainer value={value} id={id} data-testid="radioItem">
      <RadioItem
        direction="column"
        p="md"
        data-testid="bundle-container"
        border="md"
        rounded="sm"
        spacing="xs"
        borderColor="candy.100"
      >
        <Stack justify="space-between">
          <Stack direction="column" spacing="xs">
            <Text color="candy.600" fontWeight="semibold">
              {title}
            </Text>
            <Stack spacing="2xs" align="center">
              {linethroughPrice && (
                <Text
                  color="grey.600"
                  variant="sm"
                  style={{
                    textDecoration: 'line-through'
                  }}
                >
                  {`${linethroughPrice}€`}
                </Text>
              )}
              {finalPriceOutcome ? (
                <Text color="grey.900" variant="xl" fontWeight="bold">
                  {finalPriceOutcome}
                </Text>
              ) : (
                <Stack spacing="2xs">
                  <FinalPriceOutcomeComponent
                    integerPart={integerPart}
                    decimalPart={decimalPart}
                    label={t('bundles.bundleFormV2.priceforSession')}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          <RadioGroupIndicatorContainer
            align="center"
            justify="center"
            rounded="full"
            bgColor="white"
            w="16px"
            h="16px"
          >
            <RadioGroupIndicatorDot />
          </RadioGroupIndicatorContainer>
        </Stack>
        {children}
      </RadioItem>
    </RadioItemContainer>
  );
};
