import { Window } from '@unobravo/zenit-icons';
import { Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { usePatientFeatureFlags } from '@unobravo-monorepo/patient/featureFlags';
import { TroubleshootingModal } from './TroubleshootingModal';
import { useAppointment } from '../hooks/useAppointment';

interface IAppointmentHeader {
  doctorName: string;
}

const ActionLink = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  align-items: center;
  padding-right: 10px;
  user-select: none;
`;

export const AppointmentHeader = ({ doctorName }: IAppointmentHeader) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const { uuid } = useParams();
  const { appointmentUrl, session } = useAppointment(uuid!);
  const { sessionDate, dataLoaded } = session;
  const isToday = dataLoaded ? sessionDate?.isToday : false;

  const { videocallPtFullscreen } = usePatientFeatureFlags();

  return (
    <>
      <Text
        variant={isMobile ? 'md' : 'lg'}
        fontWeight="semibold"
        style={{ margin: '0 auto 0 10px', letterSpacing: '0.16px' }}
      >
        {doctorName}
      </Text>
      {appointmentUrl && isToday && (
        <Stack align="start">
          <ActionLink
            onClick={() =>
              window.open(
                videocallPtFullscreen
                  ? `/patient/appointment/${uuid}/fullscreen`
                  : appointmentUrl,
                '_blank'
              )
            }
          >
            {!isMobile && (
              <Text
                variant="lg"
                color="candy.500"
                textAlign="right"
                style={{ margin: '0 4px 0 0' }}
              >
                {t('videoCall.button')}
              </Text>
            )}
            <Window color="candy.500" />
          </ActionLink>
          <TroubleshootingModal />
        </Stack>
      )}
    </>
  );
};
