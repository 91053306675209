import { StatsigOptions, StatsigUser } from '@statsig/js-client';
import {
  StatsigProvider as Provider,
  useClientAsyncInit
} from '@statsig/react-bindings';
import { ReactNode } from 'react';
import { version } from '../../../../package.json';
import { useCountry } from '../../../translations/src';
import { Spinner } from '../components/Spinner/Spinner';

const { NX_APP_STATSIG_TIER_ENV, NX_APP_STATSIG_API_KEY } = process.env;

interface IStatsigProvider {
  children: ReactNode;
  user?: StatsigUser;
  options?: StatsigOptions;
  /*
  In some usecases, it might be useful to have multiple instances of the Statsig client running.
  IMPORTANT: You must use two different SDK keys for this to work.(one for each instance)
  Various functionality of the Statsig client is keyed on the SDK key being used. 
  Using the same key will lead to collisions.
  */
  instanceApikey?: string;
}

export const StatsigProvider = ({
  children,
  user,
  options,
  instanceApikey
}: IStatsigProvider) => {
  const { domainCountry } = useCountry();
  const apikey = instanceApikey || NX_APP_STATSIG_API_KEY || '';

  /** statsig User config */
  const statsigUser = {
    country: domainCountry.toUpperCase(),
    appVersion: version,
    ...user
  };

  /** Statsig options */
  const statsigOptions = {
    environment: {
      tier: NX_APP_STATSIG_TIER_ENV // development | production
    },
    ...options
  };

  const { client, isLoading } = useClientAsyncInit(
    apikey,
    statsigUser,
    statsigOptions
  );

  if (isLoading) {
    return <Spinner />;
  }

  return <Provider client={client}>{children}</Provider>;
};
