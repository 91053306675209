import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  createSafeStorage,
  StorageKeys
} from '@unobravo-monorepo/common/utils/storage';
import { BundleModal } from './components/bundles/BundleModal';
import { BundleModalContent } from './components/bundles/BundleModalContent';
import { PaymentContext } from './types/paymentContext';
import { useBundleExperiment } from '../bundles/hooks/useBundleExperiment';
import { useBundle } from '../bundles/hooks/useBundle';

const [, setIsMobileApp] = createSafeStorage(StorageKeys.isMobileApp);

export const BundlePaySessionWrapper = ({
  context
}: {
  context: PaymentContext;
}) => {
  const { sessionId } = useParams();

  const { isBundleOpened } = useBundleExperiment();
  const { paywallSeen } = useBundle();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(paywallSeen, []);

  if (context === 'MOBILE') {
    setIsMobileApp(true);
  }

  //  Check for FF variables. If Control -> redirect to home or 404
  if (!isBundleOpened) {
    return null;
  }

  return (
    <>
      {context === 'MOBILE' ? (
        <BundleModalContent uuid={sessionId} context={context} />
      ) : (
        <BundleModal uuid={sessionId} context={context} />
      )}
    </>
  );
};
