import { Select } from '@unobravo-monorepo/common/components/Select';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { useDebounce } from '@unobravo-monorepo/common/hooks';
import {
  convertDateFormat,
  formatDate,
  isDateBeforeToday
} from '@unobravo-monorepo/common/utils/dateUtils';
import { generateFirstSixFiscalCode } from '@unobravo-monorepo/common/utils/generateFiscalCode';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { usePlacesAutocomplete } from '@unobravo-monorepo/patient/features/billingInfo/hooks/usePlacesAutocomplete';
import { InformedConsentRecipient, usePatientCFUtils } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import {
  Countries,
  countriesMap,
  getCFValidator,
  normalizeFiscalCode,
  OtherCountries,
  otherCountry
} from '@unobravo/utils';
import {
  Autocomplete,
  Box,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import CodiceFiscale from 'codice-fiscale-js';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, FieldErrorsImpl, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useErrorHandler } from '../../../../shared/hooks/useErrorHandler';
import { useCities } from '../../hooks/useCities';

const DateInput = styled(TextInput)`
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  -webkit-appearance: none;
  background: unset;
`;

interface ICustomElement {
  children: ReactNode;
}

type ExtendedInformedConsentRecipient = InformedConsentRecipient & {
  residenceProvince?: string;
  residenceCountry?: string;
};

type IConsentFormRecipients = {
  patient: ExtendedInformedConsentRecipient | undefined;
  child: ExtendedInformedConsentRecipient | undefined;
  partner: ExtendedInformedConsentRecipient | undefined;
  singleLegalGuardian: boolean;
};

const countries = Object.keys(countriesMap).map((i) => ({
  label: (countriesMap as Record<string, string>)[i],
  value: i
}));

const Label = ({ children }: ICustomElement) => (
  <Box mb="2xs">
    <Text variant="lg" fontWeight="semibold" color="grey.900">
      {children}
    </Text>
  </Box>
);

const InputGroupWrapper = ({
  column,
  children
}: {
  column?: boolean;
  children: ReactNode;
}) => (
  <Stack direction={column ? 'column' : undefined} spacing="md" mb="md">
    {children}
  </Stack>
);

const InputContainer = ({
  children,
  basis = 1
}: {
  basis?: number;
  children: ReactNode;
}) => <Box style={{ flex: basis }}>{children}</Box>;

const MotionStack = styled(motion(Box))`
  flex: ${({ style }) => style?.flex};
`;

const Motion = ({
  children,
  basis = 1
}: {
  children: ReactNode;
  basis?: number;
}) => (
  <MotionStack
    style={{ flex: basis }}
    initial={{
      height: 0
    }}
    animate={{
      height: 'auto'
    }}
    exit={{
      height: 0
    }}
  >
    {children}
  </MotionStack>
);

type Option = { label: string; value: string };

export const ConsentForm = ({
  person,
  visible = true,
  showAddressFields,
  showBirthPlaceAndDateFields,
  setInputHasFocus
}: {
  person: 'patient' | 'child' | 'partner';
  visible?: boolean;
  showAddressFields?: boolean;
  showBirthPlaceAndDateFields?: boolean;
  setInputHasFocus: (hasFocus: boolean) => void;
}) => {
  const [numberAutocompleteRequest, setNumberAutocompleteRequest] = useState(0);
  const [addressOptions, setAddressOptions] = useState<Option[]>([]);
  const [cityOptions, setCityOptions] = useState<Option[]>([]);
  const { fetchAutocomplete, fetchAddressValidation } = usePlacesAutocomplete();
  const { getCities } = useCities();
  const [addressIsLoading, setAddressLoading] = useState(false);
  const [cityIsLoading, setCityLoading] = useState(false);
  const [nationalities, setNationalities] = useState<
    { label: string; value: string }[]
  >([]);
  const { register, getValues, setValue, control, formState, watch } =
    useFormContext<IConsentFormRecipients>();
  const [city, address] = watch([
    `${person}.birthPlace`,
    `${person}.residenceStreet`
  ]);

  const debouncedAddress = useDebounce(address ?? '', 500);
  const debouncedCities = useDebounce(city ?? '', 500);
  const errors =
    formState.errors && formState.errors[person]
      ? (formState.errors[
          person
        ] as FieldErrorsImpl<ExtendedInformedConsentRecipient>)
      : undefined;
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const { sendGenericError } = useErrorHandler();
  const { domainCountry } = useCountry();
  const {
    countries: { loading: countriesLoading }
  } = usePatientCFUtils({
    onCompleted: (data) => {
      const dataCountries = data?.getSupportedNationalities;
      if (Array.isArray(dataCountries) && !nationalities?.length) {
        setNationalities(
          [...dataCountries, otherCountry].map((value) => ({
            value: value.toUpperCase(),
            label: t(
              `legal:informedConsent.signModal.defaultValues.nationalities.${value.toUpperCase()}`
            )
          }))
        );
      }
    },
    onError: () => sendGenericError()
  });
  const setFieldValue = (
    fieldName: `${typeof person}.${keyof ExtendedInformedConsentRecipient}`,
    fieldValue: string
  ) => {
    setValue(fieldName, fieldValue, {
      shouldValidate: true,
      shouldDirty: true
    });
  };
  const country = getValues(`${person}.birthCountry`);
  const birthDate = getValues(`${person}.birthDate`);
  if (birthDate) {
    // Workaround to format date from dd/MM/yyyy to yyyy-MM-dd
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (regex.test(birthDate)) {
      setTimeout(() => {
        setFieldValue(
          `${person}.birthDate`,
          convertDateFormat(birthDate, true)
        );
      }, 500);
    }
  }
  useEffect(() => {
    const search = async () => {
      let results: Option[] = [];
      try {
        setAddressLoading(true);
        if (debouncedAddress) {
          setNumberAutocompleteRequest((r) => r + 1);
          const response = await fetchAutocomplete(debouncedAddress);
          if (response && Array.isArray(response.suggestions)) {
            results = response.suggestions.map(
              ({
                placePrediction: {
                  text: { text },
                  placeId
                }
              }) => ({ label: text, value: placeId })
            );
          }
        }
      } finally {
        setAddressOptions(results);
        setAddressLoading(false);
      }
    };
    search();
  }, [debouncedAddress]);

  useEffect(() => {
    const search = async () => {
      let results: Option[] = [];
      try {
        setCityLoading(true);
        if (debouncedCities) {
          const response = await getCities(debouncedCities);
          if (Array.isArray(response) && response.length > 0) {
            results = response.map((c) => ({
              label: c?.name ?? '',
              value: c?.name ?? ''
            }));
          }
        }
      } finally {
        setCityOptions(results);
        setCityLoading(false);
      }
    };

    search();
  }, [debouncedCities]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (person === 'patient') {
        if (
          value?.patient?.name &&
          value?.patient?.surname &&
          value?.patient?.birthCountry?.toLowerCase() === 'it' &&
          name !== 'patient.taxCode'
        ) {
          const validateCF = getCFValidator('IT' as Countries & OtherCountries);
          const { isValid } = validateCF(value?.patient?.taxCode ?? '');
          if (!isValid) {
            setValue(
              'patient.taxCode',
              generateFirstSixFiscalCode(
                value.patient.name,
                value.patient.surname
              )
            );
          }
        }
        if (
          value[person]?.birthCountry?.toLowerCase() === 'it' &&
          name === `${person}.taxCode`
        ) {
          const validateCF = getCFValidator('IT' as Countries & OtherCountries);
          const { isValid } = validateCF(value[person]?.taxCode ?? '');
          if (isValid) {
            const cf = new CodiceFiscale(value[person]!.taxCode!);
            if (cf && cf.birthplace && cf.birthplace.nome) {
              setFieldValue(
                `${person}.birthPlace`,
                cf.birthplace.nome
                  .split(' ')
                  .map((word) => {
                    return (
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    );
                  })
                  .join(' ')
              );
            }
            if (cf && cf.birthday) {
              setFieldValue(`${person}.birthDate`, formatDate(cf.birthday));
            }
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, person]);
  const handleSelect = async ({ value }: Option) => {
    try {
      const response = await fetchAddressValidation(value);
      if (response.postal_code)
        setFieldValue(`${person}.residenceZip`, response.postal_code);
      if (response.administrative_area_level_3)
        setFieldValue(
          `${person}.residenceTown`,
          response.administrative_area_level_3
        );
      if (!response.administrative_area_level_3 && response.locality)
        setFieldValue(`${person}.residenceTown`, response.locality);
      if (response.administrative_area_level_2)
        setFieldValue(
          `${person}.residenceProvince`,
          response.administrative_area_level_2
        );
      if (response.country)
        setFieldValue(`${person}.residenceCountry`, response.country);
      if (response.route) {
        const searchedAddress = `${response.route}${
          response.street_number ? `, ${response.street_number}` : ''
        }`;
        setFieldValue(`${person}.residenceStreet`, searchedAddress);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  return (
    <Box
      style={{ display: visible ? 'block' : 'none' }}
      data-testid={`${person}-consent-scroll-container`}
    >
      <InputGroupWrapper column={isMobile}>
        <InputContainer>
          <Label>{t('personalInfo.mainInfo.name')}</Label>
          <TextInput
            onFocus={() => setInputHasFocus(true)}
            data-testid={`${person}-consent-input-name`}
            type="string"
            {...register(`${person}.name`, {
              required: t('common:requiredField'),
              onBlur: () => setInputHasFocus(false)
            })}
            errorMessage={errors?.name?.message}
          />
        </InputContainer>
        <InputContainer>
          <Label>{t('personalInfo.mainInfo.surname')}</Label>
          <TextInput
            onFocus={() => setInputHasFocus(true)}
            data-testid={`${person}-consent-input-surname`}
            type="string"
            {...register(`${person}.surname`, {
              required: t('common:requiredField'),
              onBlur: () => setInputHasFocus(false)
            })}
            errorMessage={errors?.surname?.message}
          />
        </InputContainer>
      </InputGroupWrapper>
      <InputGroupWrapper>
        <InputContainer>
          <Label>
            {t('legal:informedConsent.signModal.labels.nationality')}*
          </Label>
          <Controller
            name={`${person}.birthCountry`}
            control={control}
            defaultValue={domainCountry.toUpperCase()}
            render={({ field: { onChange, value } }) => (
              <Select
                onFocus={() => setInputHasFocus(true)}
                onBlur={() => setInputHasFocus(false)}
                value={nationalities.find((c) => c.value === value)}
                options={nationalities}
                onChange={(val) => onChange(val?.value)}
                isDisabled={countriesLoading}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <Label>{t('personalInfo.billingInfo.fiscalCode')}</Label>
          <TextInput
            data-testid={`${person}-consent-input-taxcode`}
            type="string"
            onFocus={() => setInputHasFocus(true)}
            {...register(`${person}.taxCode`, {
              required: t('common:requiredField'),
              onBlur: () => setInputHasFocus(false),
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const element = e.currentTarget;
                const caret = element.selectionStart;
                setValue(
                  `${person}.taxCode`,
                  normalizeFiscalCode(element.value)
                );
                setTimeout(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });
              },
              validate: (value) => {
                const validateCF = getCFValidator(
                  country as Countries & OtherCountries
                );
                if (value) {
                  const { isValid } = validateCF(value);
                  if (!isValid) {
                    return `${t('legal:informedConsent.infoMessages.taxCode')}`;
                  }
                }
                return undefined;
              }
            })}
            errorMessage={errors?.taxCode?.message}
          />
        </InputContainer>
      </InputGroupWrapper>
      <AnimatePresence>
        {domainCountry === 'it' ? (
          showBirthPlaceAndDateFields && (
            <InputGroupWrapper>
              <InputContainer basis={isMobile ? 1 : 2}>
                <Label>
                  {t('legal:informedConsent.signModal.labels.birthPlace')}*
                </Label>
                <Autocomplete
                  style={{ zIndex: 10 }}
                  options={cityOptions}
                  loading={cityIsLoading}
                  onSelectedOption={({ value }: Option) =>
                    setFieldValue(`${person}.birthPlace`, value)
                  }
                  component={
                    <TextInput
                      onFocus={() => setInputHasFocus(true)}
                      data-testid={`${person}-consent-input-birthplace`}
                      type="string"
                      {...register(`${person}.birthPlace`, {
                        required: t('common:requiredField'),
                        onBlur: () => setInputHasFocus(false),
                        onChange: (ev) =>
                          setFieldValue(`${person}.birthPlace`, ev.target.value)
                      })}
                      value={city ?? ''}
                      errorMessage={errors?.birthPlace?.message}
                    />
                  }
                />
              </InputContainer>
              <InputContainer>
                <Label>
                  {t('legal:informedConsent.signModal.labels.birthDate')}*
                </Label>
                <DateInput
                  data-testid={`${person}-consent-input-birthdate`}
                  type="date"
                  max={DateTime.now().toISODate()}
                  placeholder={t('common:dateFormatPlaceholder')}
                  onFocus={() => setInputHasFocus(true)}
                  {...register(`${person}.birthDate`, {
                    required: t('common:requiredField'),
                    validate: (value) => {
                      if (!value || (value && !isDateBeforeToday(value))) {
                        return t('common:formatError');
                      }
                      return undefined;
                    },
                    onBlur: () => setInputHasFocus(false)
                  })}
                  errorMessage={errors?.birthDate?.message}
                />
              </InputContainer>
            </InputGroupWrapper>
          )
        ) : (
          <InputGroupWrapper>
            <InputContainer basis={isMobile ? 1 : 2}>
              <Label>
                {t('legal:informedConsent.signModal.labels.birthPlace')}*
              </Label>
              <Autocomplete
                style={{ zIndex: 10 }}
                options={cityOptions}
                loading={cityIsLoading}
                onSelectedOption={({ value }: Option) =>
                  setFieldValue(`${person}.birthPlace`, value)
                }
                component={
                  <TextInput
                    onFocus={() => setInputHasFocus(true)}
                    data-testid={`${person}-consent-input-birthplace`}
                    type="string"
                    {...register(`${person}.birthPlace`, {
                      required: t('common:requiredField'),
                      onBlur: () => setInputHasFocus(false),
                      onChange: (ev) =>
                        setFieldValue(`${person}.birthPlace`, ev.target.value)
                    })}
                    value={city ?? ''}
                    errorMessage={errors?.birthPlace?.message}
                  />
                }
              />
            </InputContainer>
            <InputContainer>
              <Label>
                {t('legal:informedConsent.signModal.labels.birthDate')}*
              </Label>
              <DateInput
                data-testid={`${person}-consent-input-birthdate`}
                type="date"
                max={DateTime.now().toISODate()}
                placeholder={t('common:dateFormatPlaceholder')}
                onFocus={() => setInputHasFocus(true)}
                {...register(`${person}.birthDate`, {
                  required: t('common:requiredField'),
                  validate: (value) => {
                    if (!value || (value && !isDateBeforeToday(value))) {
                      return t('common:formatError');
                    }
                    return undefined;
                  },
                  onBlur: () => setInputHasFocus(false)
                })}
                errorMessage={errors?.birthDate?.message}
              />
            </InputContainer>
          </InputGroupWrapper>
        )}
      </AnimatePresence>
      <InputGroupWrapper>
        <InputContainer basis={isMobile ? 1 : 2}>
          <Label>
            {t('legal:informedConsent.signModal.recapLabels.residenceAddress')}*
          </Label>
          <Autocomplete
            style={{ zIndex: 10 }}
            options={addressOptions}
            loading={addressIsLoading}
            onSelectedOption={handleSelect}
            component={
              <TextInput
                onFocus={() => setInputHasFocus(true)}
                autoComplete="street-address"
                data-testid={`${person}-consent-input-residencestreet`}
                type="string"
                {...register(`${person}.residenceStreet`, {
                  required: t('common:requiredField'),
                  onBlur: () => setInputHasFocus(false),
                  onChange: (ev) =>
                    setFieldValue(`${person}.residenceStreet`, ev.target.value)
                })}
                value={address ?? ''}
                errorMessage={errors?.residenceStreet?.message}
              />
            }
            onBlur={() => {
              if (numberAutocompleteRequest) {
                pendoTrack('autocomplete', {
                  numberRequest: numberAutocompleteRequest
                });
                setNumberAutocompleteRequest(0);
              }
            }}
          />
        </InputContainer>
        {!isMobile && showAddressFields && (
          <InputContainer basis={1}>
            <Label>{t('personalInfo.billingInfo.cap')}</Label>
            <TextInput
              onFocus={() => setInputHasFocus(true)}
              data-testid={`${person}-consent-input-residencezip`}
              autoComplete="postal-code"
              type="string"
              {...register(`${person}.residenceZip`, {
                required: t('common:requiredField'),
                onBlur: () => setInputHasFocus(false)
              })}
              errorMessage={errors?.residenceZip?.message}
            />
          </InputContainer>
        )}
      </InputGroupWrapper>
      <AnimatePresence>
        {showAddressFields && (
          <>
            <InputGroupWrapper>
              {isMobile && (
                <Motion basis={1}>
                  <Label>{t('personalInfo.billingInfo.cap')}</Label>
                  <TextInput
                    onFocus={() => setInputHasFocus(true)}
                    data-testid={`${person}-consent-input-residencezip`}
                    autoComplete="postal-code"
                    type="string"
                    {...register(`${person}.residenceZip`, {
                      required: t('common:requiredField'),
                      onBlur: () => setInputHasFocus(false)
                    })}
                    errorMessage={errors?.residenceZip?.message}
                  />
                </Motion>
              )}
              <Motion>
                <Label>{t('personalInfo.billingInfo.city')}</Label>
                <TextInput
                  onFocus={() => setInputHasFocus(true)}
                  data-testid={`${person}-consent-input-residencetown`}
                  type="string"
                  autoComplete="address-level2"
                  {...register(`${person}.residenceTown`, {
                    required: t('common:requiredField'),
                    onBlur: () => setInputHasFocus(false)
                  })}
                  errorMessage={errors?.residenceTown?.message}
                />
              </Motion>
            </InputGroupWrapper>

            <InputGroupWrapper>
              <Motion basis={isMobile ? 1 : 2}>
                <Label>{t('personalInfo.billingInfo.region')}</Label>
                <TextInput
                  autoComplete="address-level1"
                  onFocus={() => setInputHasFocus(true)}
                  data-testid={`${person}-consent-input-residenceprovince`}
                  type="string"
                  {...register(`${person}.residenceProvince`, {
                    required: t('common:requiredField'),
                    onBlur: () => setInputHasFocus(false)
                  })}
                  errorMessage={errors?.residenceProvince?.message}
                />
              </Motion>
              <Motion>
                <Label>{t('personalInfo.billingInfo.state')}</Label>
                <Controller
                  name={`${person}.residenceCountry`}
                  control={control}
                  rules={{
                    required: t('common:requiredField')
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      data-testid={`${person}-state-billing`}
                      onBlur={onBlur}
                      value={countries.find((c) => c.value === value)}
                      isSearchable
                      options={countries}
                      onChange={(val) => onChange((val as Option)?.value)}
                      errorMessage={errors?.residenceCountry?.message}
                    />
                  )}
                />
              </Motion>
            </InputGroupWrapper>
          </>
        )}
      </AnimatePresence>
    </Box>
  );
};
