import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import { useSession } from '@unobravo-monorepo/patient/shared/hooks/useSession';
import { IAppointment, useFirebaseUser } from '@unobravo/core';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useConfirmBonusSession } from '../../payment/hooks/useConfirmBonusSession';
import { usePayFreeSession } from '../../payment/hooks/usePayFreeSession';
import { useTherapySurveyConsent } from '../../therapySurveyConsentModal/hooks/useTherapySurveyConsent';
import { openTherapySurveyConsentModal } from '../../therapySurveyConsentModal/therapySurveyConsent.slice';
import { useBundle } from '../../bundles/hooks/useBundle';

/**
 * TODO: to migrate.
 * Maybe we should use this custom Hook both for Theraphy Setting Bubble && AppointmentBubble
 * to reduce code duplication.
 */

export const useBubbleHandlers = ({
  appointment,
  showVideocall
}: {
  appointment: IAppointment;
  showVideocall: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useFirebaseUser();
  const patientId = currentUser.unbvId!;
  const isConsentGiven = useTherapySurveyConsent();
  const isHome = location.pathname.includes('home');
  const { uuid, id: sessionId } = appointment;
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { payFreeSession, loading: paymentLoading } = usePayFreeSession();
  const { uuid: patientUuid } = usePatient();
  const { confirmBonusSession, loading: confirmBonusLoading } =
    useConfirmBonusSession();
  const { id, isFree, category, sessionDate, refetch, hasVoucher } = useSession(
    uuid,
    { showVideocall },
    appointment
  );
  const sessionCategory = category || 'PAID';
  const isFreeSession = isFree || sessionCategory === 'FREE';

  const { payWithBundle, hasBundleRemaining } = useBundle();

  const stateLocation = {
    state: {
      modal: location
    }
  };

  /** Check Dialog Navigation */
  const checkDialogNavigation = () => {
    if (isHome) return 'home';
    return location.pathname.includes('therapy-setting')
      ? 'therapy-setting'
      : 'chat';
  };

  /**
   * On Modify Session clicked
   */
  const onModifyClicked = () => {
    pendoTrack('modify_session_bubble', {
      sessionId
    });
  };

  /**
   * Open Videocall Callback
   */
  const onVideocallClicked = () => {
    pendoTrack('join_session', { sessionId });
    pushAuthenticatedEvent('StartVideocall', {
      user_id: patientId,
      uuid: patientUuid
    });
    navigate(`../appointment/${uuid}`);
  };

  /**
   * OnPayClicked callback
   * @returns
   */
  const onPayClicked = async (isInAbTest = false) => {
    if (paymentLoading || confirmBonusLoading || !id) {
      return;
    }
    if (isFreeSession) {
      const payResult = await payFreeSession(id, sessionDate);

      if (payResult && !payResult.success) {
        return;
      }
      await refetch();

      if (isConsentGiven) {
        return;
      }

      if (payResult && payResult.action === 'REQUEST_QOT_CONSENTS') {
        setTimeout(() => {
          dispatch(openTherapySurveyConsentModal());
        }, 500);
      }

      return;
    }
    if (sessionCategory === 'BONUS') {
      return (await confirmBonusSession(id)) && refetch();
    }

    if (isInAbTest && !hasBundleRemaining) {
      return navigate(
        `../dialog/${checkDialogNavigation()}/bundlePaySession/${uuid}`,
        stateLocation
      );
    }

    if (hasBundleRemaining) {
      await payWithBundle(+sessionId);
      await refetch();
      return;
    }

    return navigate(
      `../dialog/${checkDialogNavigation()}/paySession/${uuid}`,
      stateLocation
    );
  };

  return {
    onPayClicked,
    onVideocallClicked,
    onModifyClicked,
    sessionCategory,
    hasVoucher
  };
};
