import { Stack, Text } from '@unobravo/zenit-web';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaymentContext } from '../../types/paymentContext';
import { useBundle } from '../../../bundles/hooks/useBundle';
import { useBundleExperiment } from '../../../bundles/hooks/useBundleExperiment';

const LinkDiv = styled.div`
  display: inline;
  color: ${({ theme }) => theme.colors.edamame[500]};
  cursor: pointer;
`;

export const CustomCardContent = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Text color="grey.900">
      {t('bundles.bundleAlert.text')}&nbsp;
      <LinkDiv onClick={onClick}>
        <strong>{t('bundles.bundleAlert.cta')}</strong>
      </LinkDiv>
    </Text>
  );
};

export const BundleAlert = ({
  context,
  uuid
}: {
  context: PaymentContext;
  uuid: string;
}) => {
  const navigate = useNavigate();
  const { getPathFromContext } = useBundle();
  const { showBuyBundlesAlert } = useBundleExperiment();

  if (!showBuyBundlesAlert) {
    return null;
  }

  const gotoPaywall = () => {
    if (context === 'MOBILE') {
      return navigate(`/patient/mobile/bundlePaySession/${uuid}`);
    }

    return navigate(`../${getPathFromContext(context)}`, {
      state: {
        referrer: {
          path: `${getPathFromContext(context)}/bundlePaySession/${uuid}`
        }
      }
    });
  };

  return (
    <Stack p="md" bgColor="edamame.50" rounded="sm">
      <CustomCardContent onClick={gotoPaywall} />
    </Stack>
  );
};
