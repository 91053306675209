import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { Stack, useBreakpointValue } from '@unobravo/zenit-web';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { closeLeftMenu } from '../../layout/layout.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { AppointmentHeader } from '../components/AppointmentHeader';
import { DownloadInvoice } from '../components/DownloadInvoice';
import { EmptyState } from '../components/EmptyState';
import { WherebyEmbed } from '../components/WherebyEmbed';
import { useAppointment } from '../hooks/useAppointment';

export const AppointmentPage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const { doctor } = usePatient();
  const { isMobile } = useBreakpointValue();
  const {
    appointmentUrl,
    invoiceUrl,
    session,
    appointmentUrlLoading,
    sessionLoading,
    downloadInvoice
  } = useAppointment(uuid!);
  const doctorName = doctor ? `${doctor.name} ${doctor.surname}` : '';
  const { sessionDate, dataLoaded } = session;
  const isToday = dataLoaded ? sessionDate?.isToday : false;

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, [dispatch]);

  const DoctorName = <AppointmentHeader doctorName={doctorName} />;

  return !dataLoaded ? (
    <Spinner />
  ) : (
    <PatientLayout>
      <PageContainer>
        <PageContainerHeader>{DoctorName}</PageContainerHeader>
        {isMobile && (
          <Stack p="xs" align="center">
            {DoctorName}
          </Stack>
        )}

        {appointmentUrlLoading || sessionLoading ? (
          <Spinner />
        ) : (
          <>
            {appointmentUrl && isToday && (
              <WherebyEmbed appointmentUrl={appointmentUrl} />
            )}
            {invoiceUrl && !isToday && (
              <DownloadInvoice available downloadInvoice={downloadInvoice} />
            )}
            {!invoiceUrl && !appointmentUrl && (
              <EmptyState state={session?.canceled ? 'canceled' : 'notPaid'} />
            )}
          </>
        )}
      </PageContainer>
    </PatientLayout>
  );
};
