import { getAuth, getIdTokenResult } from 'firebase/auth';
import { Providers } from './providers';
import { userHas } from './utils';

export const providers = () => {
  const auth = getAuth();

  const hasGoogleLogin = userHas(Providers.Google, auth.currentUser);

  const hasEmailProvider = userHas(Providers.Email, auth.currentUser);

  const hasAppleLogin = userHas(Providers.Apple, auth.currentUser);

  const hasSocialLogin = hasGoogleLogin || hasAppleLogin;

  return {
    hasGoogleLogin,
    hasEmailProvider,
    hasAppleLogin,
    hasSocialLogin
  } as const;
};

export const userInfo = () => {
  const auth = getAuth();

  const uid = auth.currentUser?.uid ?? null;

  const email = auth.currentUser?.email ?? null;

  return { uid, email } as const;
};

export const refreshUserInfo = async () => {
  const { currentUser } = getAuth();

  if (!currentUser) return null;

  /* true = force a refresh */
  return getIdTokenResult(currentUser, true);
};
