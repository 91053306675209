import { PaymentElement } from '@stripe/react-stripe-js';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import maestroLogo from '@unobravo-monorepo/patient/assets/maestro-logo.svg';
import mastercardLogo from '@unobravo-monorepo/patient/assets/mastercard-logo.svg';
import stripeLogo from '@unobravo-monorepo/patient/assets/stripe-logo.svg';
import visaLogo from '@unobravo-monorepo/patient/assets/visa-logo.svg';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import { useCountry } from '@unobravo/translations';
import { Box, Button, Link, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFootnote } from '../../../../../common/src/utils/priceUtils';
import { paySessionDataSelector } from '../../newPaySession/paySessionData.slice';
import { usePatientCards } from '../../patientData/hooks/usePatientCards';
import { PaymentMethodComp } from '../../payment/components/PaymentMethodComp';

type PaymentFormType = 'NEW_CARD' | 'CARD_LIST';

const CursorBox = styled(Box)`
  cursor: pointer;
`;

const TextWrapper = styled(({ ...props }) => <Text {...props} />)`
  a,
  a:visited,
  a:hover,
  a:active {
    cursor: 'pointer';
  }
`;

const StripeDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" align="center" spacing="sm" justify="center" mt="xs">
      <Text color="grey.600" variant="sm">
        {t('paymentMethod.securePayment')}
      </Text>
      <img src={stripeLogo} style={{ width: 46 }} alt="stripe-logo" />
      <Box borderRight="sm" borderColor="grey.200" h={20} />
      <img src={visaLogo} alt="visa-logo" style={{ width: 46 }} />
      <img src={mastercardLogo} alt="mastercard-logo" style={{ width: 35 }} />
      <img src={maestroLogo} alt="maestro-logo" style={{ width: 35 }} />
    </Stack>
  );
};

export const CardForm = ({
  pay,
  loading,
  amount,
  onBack
}: {
  pay: (cardId?: string) => Promise<void>;
  loading?: boolean;
  amount: string;
  onBack?: () => void;
}) => {
  const { t } = useTranslation();
  const { domainCountry } = useCountry();
  const { pushAuthenticatedEvent } = usePatientGTM();

  const {
    cards,
    sepaDebits,
    loading: paymentMethodsLoading
  } = usePatientCards();

  const { paymentIntent } = useSelector(paySessionDataSelector);

  const [formType, setFormType] = useState<PaymentFormType>();

  const [complete, setComplete] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);

  const isDisabled = formType === 'NEW_CARD' && !complete;

  const hasPaymentMethods =
    (cards && cards.length > 0) || (sepaDebits && sepaDebits.length > 0);

  const footnote = getFootnote(domainCountry, paymentIntent?.amount);

  // since we could add an "extra" step depending on payment methods available,
  // we need to handle the back button correctly if the user is in the new card form
  // but has payment methods, we need to go back to the card list
  const handleBack = () => {
    if (formType === 'NEW_CARD' && hasPaymentMethods) {
      setFormType('CARD_LIST');
      return;
    }

    onBack?.();
  };

  useEffect(() => {
    if (!hasPaymentMethods) {
      setFormType('NEW_CARD');
      return;
    }

    setFormType('CARD_LIST');

    // assuming that both are nullable but always come as [] if not available
    if (cards && sepaDebits) {
      setSelectedPaymentMethod(
        (cards[0]?.cardId || sepaDebits[0]?.sepaId) ?? null
      );
    }
  }, [hasPaymentMethods, cards, sepaDebits]);

  if (paymentMethodsLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Stack
        direction="column"
        grow
        data-testid="appointment-payment-stripe-info"
      >
        {formType === 'CARD_LIST' && (
          <Stack data-testid="payment-method-list" direction="column" px="xl">
            <Stack direction="column">
              <Box mb="sm">
                <Text color="grey.600">
                  {t('paySession.paymentMethod.choose')}
                </Text>
              </Box>
              {cards?.map((card) => (
                <CursorBox
                  key={card.cardId}
                  data-testid="choose-credit-card"
                  onClick={() => setSelectedPaymentMethod(card.cardId)}
                >
                  <PaymentMethodComp
                    paymentMethod={card}
                    isSelectedCard={card.cardId === selectedPaymentMethod}
                  />
                </CursorBox>
              ))}
              {sepaDebits?.map((sepa) => (
                <CursorBox
                  key={sepa.sepaId}
                  data-testid="choose-sepa-debit"
                  onClick={() => setSelectedPaymentMethod(sepa.sepaId)}
                >
                  <PaymentMethodComp
                    paymentMethod={sepa}
                    isSelectedCard={sepa.sepaId === selectedPaymentMethod}
                  />
                </CursorBox>
              ))}
              <Box mt="sm">
                <TextWrapper variant="sm" color="grey.600">
                  <Trans
                    i18nKey="paySession.paymentMethod.buttonText"
                    components={[
                      <Link
                        data-testid="add-new-card"
                        color="candy.500"
                        to={() => setFormType('NEW_CARD')}
                        underline={false}
                      />
                    ]}
                    values={{ name: t('paySession.paymentMethod.button') }}
                  />
                </TextWrapper>
              </Box>
            </Stack>
          </Stack>
        )}

        {formType === 'NEW_CARD' && (
          <Stack
            direction="column"
            mx="xl"
            data-testid="new-card-section"
            spacing="lg"
          >
            <Text color="grey.600">{t('paymentMethod.stripeDescription')}</Text>
            <Stack direction="column" spacing="sm">
              <PaymentElement
                options={{
                  layout: 'accordion'
                }}
                onChange={(form) => {
                  // enables/disables pay button when payment element is/is not considered 'complete'
                  // e.g. when a card is fully filled out
                  setComplete(form.complete);
                }}
              />
              <Box mb="2xl">
                <StripeDisclaimer />
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack
        bottom={0}
        rounded="lg"
        bgColor="white"
        spacing="xs"
        p="xl"
        position="sticky"
        direction="column"
        style={{
          zIndex: 1
        }}
      >
        <RStack
          w="100%"
          direction={{ base: 'column-reverse', md: 'row' }}
          justify="space-between"
          spacing="xs"
        >
          <Button
            variant="ghost"
            onClick={handleBack}
            label={t('paymentMethod.back')}
            size="lg"
            iconPosition="left"
            data-testid="card-list-back-button"
            type="button"
          />
          <Button
            onClick={async () => {
              if (formType === 'NEW_CARD' && complete) {
                pushAuthenticatedEvent('NewPaymentMethod');
                await pay();
                return;
              }

              if (selectedPaymentMethod) {
                await pay(selectedPaymentMethod);
              }
            }}
            label={`${t(`paySession.confirmPayment`, {
              cost: amount
            })}${footnote}`}
            loading={loading}
            disabled={isDisabled}
            size="lg"
            data-testid="pay-session-button"
          />
        </RStack>
        {footnote && (
          <Text variant="sm" color="grey.600">
            {t('paySession.footnotes.esPriceChange')}
          </Text>
        )}
      </Stack>
    </>
  );
};
