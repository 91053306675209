import { useTranslation } from 'react-i18next';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';

import { z } from '@unobravo/zenit-form';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { BundlesVariations } from '../../../../types/Bundles';
import {
  formSchema,
  getBundlePriceInfo
} from '../../../../shared/components/Bundles/bundle-map';
import { IBundlePriceDetails } from '../../../../shared/components/Bundles/bundle-prices-map';
import { BundleForm as BundleFormV2 } from '../../../../shared/components/Bundles/form-v2/BundleForm';
import { BundleForm } from '../../../../shared/components/Bundles/form-v1/BundleForm';
import { useBundleExperiment } from '../../../bundles/hooks/useBundleExperiment';

export const Paywall = ({
  onSubmit,
  onBack,
  bundlesVariant = 'outside',
  showAlert = false
}: {
  onSubmit: (bundleInfo?: IBundlePriceDetails) => void;
  onBack?: () => void;
  bundlesVariant?: BundlesVariations;
  showAlert?: boolean;
}) => {
  const { t } = useTranslation();

  const { bundlePaywallV2 } = useBundleExperiment();

  const onSubmitValues = (values: z.infer<typeof formSchema>) => {
    if (bundlesVariant && bundlesVariant !== 'outside') {
      const bundleInfo = getBundlePriceInfo(bundlesVariant, values.bundle);
      onSubmit(bundleInfo);
    }
  };

  const bundleFormProps = {
    bundlesVariant,
    showAlert,
    onSkip: onBack,
    onSubmitValues
  };

  return (
    <Stack direction="column" h="100%">
      {bundlesVariant ? (
        <>
          <Box px="lg" pt="lg" mr="2xl">
            <Text variant="2xl" fontWeight="bold">
              {bundlePaywallV2
                ? t('bundles.paywall.titleV2')
                : t('bundles.paywall.title')}
            </Text>
          </Box>
          {bundlePaywallV2 ? (
            <BundleFormV2 {...bundleFormProps} />
          ) : (
            <BundleForm {...bundleFormProps} />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </Stack>
  );
};
