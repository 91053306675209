import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Stack, Text } from '@unobravo/zenit-web';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { TroubleshootingModal } from '../components/TroubleshootingModal';
import { useAppointment } from '../hooks/useAppointment';
import { WherebyEmbed } from '../components/WherebyEmbed';
import { usePatient } from '../../patientData/hooks/usePatient';

const Wrapper = styled(Stack)`
  z-index: 48;
  background: linear-gradient(
    to bottom,
    #2b2b2b,
    ${({ theme }) => theme.colors.grey[800]} 5%
  );
`;

export const AppointmentNewTabPage = () => {
  const { uuid } = useParams();
  const { appointmentUrl, appointmentUrlLoading } = useAppointment(uuid!);
  const { doctor } = usePatient();
  const doctorName = doctor ? `${doctor.name} ${doctor.surname}` : '';

  useEffect(() => {
    document.title = doctorName;
  }, [doctorName]);

  return (
    <Wrapper w="100%" position="fixed" top={0} direction="column" h="100%">
      <Stack px="xl" py="md">
        <Stack w="50%" h="36px" align="center">
          <Text variant="sm" color="white" fontWeight="semibold">
            {doctorName}
          </Text>
        </Stack>

        <Stack justify="end" w="50%" spacing="md" align="center">
          <TroubleshootingModal />
        </Stack>
      </Stack>

      <Stack px="xs" pb="xs" h="100%" style={{ overflow: 'auto' }}>
        {appointmentUrlLoading ? (
          <Spinner />
        ) : (
          <>
            {appointmentUrl && (
              <Stack w="100%" overflow="hidden">
                <WherebyEmbed appointmentUrl={appointmentUrl} />
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Wrapper>
  );
};
