import { SessionStatus } from '@unobravo-monorepo/common/types/IAppointments';
import { Button } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useBundleExperiment } from '../../bundles/hooks/useBundleExperiment';
import { useBundle } from '../../bundles/hooks/useBundle';

interface BundleTestButtonProps {
  action?: (arg?: boolean, variant?: string) => void;
  status: SessionStatus;
}

export const BundleChatExperimentButton = ({
  action,
  status
}: BundleTestButtonProps) => {
  const { isBundleOpened, bundleVariant } = useBundleExperiment();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { hasBundleRemaining } = useBundle();
  const { t } = useTranslation();

  const payLabel = hasBundleRemaining
    ? t('patient:therapySetting.payWithBundle')
    : isBundleOpened
    ? t('patient:therapySetting.payWithPaywallV2')
    : t('patient:therapySetting.appointmentPending');

  return (
    <>
      <Button
        label={payLabel}
        onClick={async () => {
          if (action) {
            setButtonLoading(true);
            await action(isBundleOpened, bundleVariant);
            setButtonLoading(false);
          }
        }}
        variant="filled"
        colorVariant={status === 'VIDEOCALL' ? 'accent' : 'primary'}
        disabled={buttonLoading}
        loading={buttonLoading}
        data-testid="appointment-chat-bundle-test-button"
      />
    </>
  );
};
