import { captureException } from '@sentry/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { paySessionDataSelector } from '../newPaySession/paySessionData.slice';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { RecapVoucher } from './RecapVoucher';
import { CardForm } from './components/CardForm';
import { InfoHeader } from './components/InfoHeader';

export const PaymentMethod: React.FC<{
  onBack?: () => void;
  onComplete?: () => void;
  onError?: () => void;
}> = ({ onBack, onError, onComplete }) => {
  const { sessionId } = useParams();
  const { voucher } = useSelector(paySessionDataSelector);
  const {
    payWithNewPaymentMethod,
    payWithSelectedMethod,
    applyVerifiedVoucher,
    amount: originalAmount
  } = useStripePaymentIntent(sessionId!);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const amount = voucher?.total ?? originalAmount;

  const pay = async (paymentMethodId?: string) => {
    try {
      setLoading(true);

      paymentMethodId
        ? await payWithSelectedMethod(paymentMethodId)
        : await payWithNewPaymentMethod();

      onComplete?.();
    } catch (error) {
      captureException(error, {
        tags: {
          errorContext: 'payments'
        }
      });

      // TODO: check if we can avoid showing the error page
      // it's bothersome to go back and edit the payment method
      onError?.();
    } finally {
      setLoading(false);
    }
  };

  const payWithVoucher = async () => {
    try {
      setLoading(true);

      await applyVerifiedVoucher();

      onComplete?.();
    } catch (error) {
      captureException(error, {
        tags: {
          errorContext: 'payments'
        }
      });

      // TODO: check if we can avoid showing the error page
      // it's bothersome to go back and edit the payment method
      onError?.();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InfoHeader
        showStepper
        title={t('profileAndSettings.paymentMethodLabel')}
        active="PAYMENT_METHOD"
      />
      {voucher?.total === '0,00' ? (
        <RecapVoucher
          pay={payWithVoucher}
          loading={loading}
          onBack={() => onBack?.()}
        />
      ) : (
        <CardForm
          amount={amount ?? ''}
          loading={loading}
          pay={pay}
          onBack={() => onBack?.()}
        />
      )}
    </>
  );
};
