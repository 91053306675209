import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { downloadPdfFile } from '@unobravo-monorepo/common/utils/fileUtils';
import { useErrorHandler } from '@unobravo-monorepo/patient/shared/hooks/useErrorHandler';
import { useFirebaseUser } from '@unobravo/core';
import { Alert, Box, Stack, Text } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Types from '../../../../generated/patient.generated';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useBundleInvoices } from '../hooks/useBundleInvoices';
import { BundleBubble } from './BundleBubble';
import { NoInvoices } from './NoInvoices';

const hasInvoices = (obj: object) => Object.entries(obj).length > 0;

export const BundleInvoices: React.FC = () => {
  const { t } = useTranslation();

  const {
    hasPreviousPage,
    loading,
    groupedByMonth: groupByMonth
  } = useBundleInvoices();

  const { currentUser } = useFirebaseUser();
  const { sendGenericError } = useErrorHandler();
  const { timezone } = usePatient();

  const downloadDocument = async (bundle: Types.BundleDocument) => {
    if (!bundle.url || !bundle?.date || !currentUser) {
      return;
    }
    const filename = `bundle_${DateTime.fromISO(bundle.date).toFormat(
      'dd-MM-yyyy'
    )}`;
    try {
      await downloadPdfFile(bundle.url, filename, currentUser.token);
    } catch {
      sendGenericError();
    }
  };

  return (
    <Stack direction="column" w="100%" h="100%" p="md">
      {loading && !hasInvoices(groupByMonth) && <Spinner />}

      <Alert
        message={t('invoices.bundleAlert')}
        type="informative"
        icon={false}
      />
      {hasInvoices(groupByMonth) && (
        <Stack direction="column" align="center">
          {Object.entries(groupByMonth).map(
            ([month, value]) =>
              value.length > 0 && (
                <Box key={month} mt="xl" w="100%">
                  <Box mb="xs" style={{ textTransform: 'capitalize' }}>
                    <Text variant="sm" color="grey.300" textAlign="center">
                      {month}
                    </Text>
                  </Box>
                  {value.map((bundle) => {
                    const {
                      day,
                      month: dateMonth,
                      year
                    } = getSessionDate(bundle.date, timezone || 'it');

                    const updateAt = DateTime.fromISO(
                      bundle.updatedAt
                    ).toFormat('dd MMMM yyyy');

                    return (
                      <BundleBubble
                        key={bundle.id}
                        title={t('invoices.bundleSizeTitle', {
                          numSessions: bundle?.numSessions
                        })}
                        subtitle={t('invoices.buyDate', {
                          day,
                          dateMonth,
                          year
                        })}
                        variant={bundle.type}
                        onClick={() => downloadDocument(bundle)}
                        buttonLabel={
                          bundle.type === 'invoice'
                            ? t('invoices.invoice')
                            : t('invoices.creditNote')
                        }
                        updateAt={updateAt}
                        isCompleted={bundle.completed}
                      />
                    );
                  })}
                </Box>
              )
          )}

          {hasPreviousPage && hasInvoices(groupByMonth) && (
            <Box
              onClick={() => {
                /** TODO: add paginations in next steps */
              }}
              mt="xs"
              data-testid="invoices-more-appointments-button"
              style={{
                cursor: 'pointer',
                color: 'candy.500'
              }}
            >
              <Text color="candy.500" variant="sm" fontWeight="semibold">
                {t('invoices.loadMoreAppointments')}
              </Text>
            </Box>
          )}
        </Stack>
      )}
      {!loading && !hasInvoices(groupByMonth) && <NoInvoices />}
    </Stack>
  );
};
