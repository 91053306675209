import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { ConfirmUserPreMatchDocument } from '@unobravo-monorepo/common/graphql/mutation.generated';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import { useFirebaseUser } from '@unobravo/core';
import { MatchAlgorithmVersion } from '../../../common/generated/common.generated';
import { CONFIRM_USER_V2 } from '../graphql/mutation';

export const useActivation = () => {
  const { pushAuthenticatedEvent } = useGTM();

  const [confirmUser, { loading }] = useMutation(CONFIRM_USER_V2);
  const [confirmUserPreMatch] = useMutation(ConfirmUserPreMatchDocument);

  const { loginFromCustomToken } = useFirebaseUser();

  const verify = async (
    code: string,
    algorithmVersion?: MatchAlgorithmVersion
  ) => {
    try {
      let res;

      switch (algorithmVersion) {
        case MatchAlgorithmVersion.PreMatch:
          res = await confirmUserPreMatch({ variables: { token: code } });
          break;
        case MatchAlgorithmVersion.TopDropPerformer:
        case MatchAlgorithmVersion.SeverityClusters:
          res = await confirmUser({
            variables: {
              token: code,
              algorithmVersion
            }
          });
          break;
        default:
          res = await confirmUser({ variables: { token: code } });
          break;
      }

      const { verified, token, doctor, patient, doctors } =
        res.data?.confirmUserV2 || res.data?.confirmUserPreMatch || {};

      const oneMatch = doctors?.length === 1;

      if (!verified) {
        throw new Error('Token not verified');
      }

      await loginFromCustomToken(token);

      pushAuthenticatedEvent('ActivatedUser', {
        tp_id: doctor?.id,
        tp_first_name: doctor?.name,
        tp_last_name: doctor?.surname,
        email: patient?.hashedEmail,
        user_id: patient?.id,
        age: patient?.age,
        uuid: patient?.uuid
      });

      return { verified, oneMatch };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // FIXME: help me
      const errorMessage = error?.networkError?.result?.errors?.[0].message;

      const isTokenExpired = errorMessage === 'EXPIRED';
      const userAlreadyVerified =
        errorMessage === 'PATIENT_EMAIL_ALREADY_VERIFIED';

      if (!isTokenExpired && !userAlreadyVerified) {
        captureException(error);
      }

      return {
        hasErrors: true,
        isTokenExpired,
        userAlreadyVerified
      };
    }
  };

  return { verify, loading } as const;
};
