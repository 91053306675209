import { FirstStep } from '@unobravo-monorepo/patient/features/newPaySession/FirstStep';
import {
  ICreditCard,
  ISepaDebit,
  useBillingInfoV2,
  useFirstPayment
} from '@unobravo/patient';
import { Cross } from '@unobravo/zenit-icons';
import { ActionIcon, Modal, RBox, Stack } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePatientGTM } from '../../shared/hooks/usePatientGTM';
import { useSession } from '../../shared/hooks/useSession';
import { setIsMobileApp } from '../layout/layout.slice';
import { usePatient } from '../patientData/hooks/usePatient';
import { usePatientCards } from '../patientData/hooks/usePatientCards';
import { useGetPaymentIntent } from '../payment/hooks/useGetPaymentIntent';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { StripeProvider } from '../payment/providers/stripeProvider';
import { BillingInfo } from './BillingInfo';
import { Completed } from './Completed';
import { Error } from './Error';
import { PaymentMethod } from './PaymentMethod';
import { RecapSkeleton } from './components/RecapSkeleton';
import {
  paySessionDataSelector,
  reset,
  setSelectedCard,
  setStep
} from './paySessionData.slice';
import { PaymentContext } from './types/paymentContext';
import { PaymentStep } from './types/paymentStepper.types';

enum RecurrentPaymentStepper {
  FIRST_STEP = 'FIRST_STEP',
  BILLING_INFO = 'BILLING_INFO',
  PAYMENT_METHOD = 'PAYMENT_METHOD'
}

const PaySessionContent: React.FC<{
  context: PaymentContext;
}> = ({ context }) => {
  const navigate = useNavigate();
  const { id } = usePatient();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { billingInfoData, loading: billingInfoLoading } = useBillingInfoV2(
    id!
  );
  const { cards } = usePatientCards();
  const [finalStep, modifyFinalStep] = useState<'COMPLETE' | 'ERROR'>();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const session = useSession(sessionId!);
  const { candidateForFirstPurchase, loading } = useFirstPayment(sessionId!);
  const { clientSecret, status } = useStripePaymentIntent(sessionId!);
  const [urlParams] = useSearchParams();
  const redirectStatus = urlParams.get('redirect_status');
  const { paymentIntent: cardIntent } = useGetPaymentIntent();
  const { selectedCard } = useSelector(paySessionDataSelector);
  const { step } = useSelector(paySessionDataSelector);
  const { id: billingInfoId } = billingInfoData ?? {};
  const isMobileApp = context === 'MOBILE';
  const modifyStep = (s?: PaymentStep) => s && dispatch(setStep(s));

  const goToFirstStep = () => {
    modifyStep(RecurrentPaymentStepper[RecurrentPaymentStepper.FIRST_STEP]);
  };

  const modifySelectedCard = (c: ICreditCard | ISepaDebit | 'klarna') =>
    dispatch(setSelectedCard(c));

  const navigateBack = () => {
    session.refetch();
    switch (context) {
      case 'AGENDA':
        navigate('../agenda');
        break;
      case 'CHAT':
        navigate('../chat');
        break;
      case 'THERAPY_SETTING':
        navigate('../therapy-setting');
        break;
      case 'HOME':
        navigate('../home');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    // init step
    if (!billingInfoLoading && !!cards && !step) {
      if (!billingInfoId || cards.length === 0) {
        modifyStep(RecurrentPaymentStepper.FIRST_STEP);
      } else {
        modifyStep(RecurrentPaymentStepper.FIRST_STEP);

        // init card
        const firstCard = cards?.[0];
        if (firstCard) {
          modifySelectedCard(firstCard);
        }
      }
    }
  }, [billingInfoId, billingInfoLoading, cards]);

  useEffect(() => {
    const isSessionInvalid =
      session.uuid === sessionId &&
      (session.status === 'CANCELED' || session.status === 'EXPIRED');

    if (isSessionInvalid) {
      navigateBack();
    }
  }, [session.status, session.uuid, sessionId]);

  useEffect(() => {
    dispatch(setIsMobileApp(isMobileApp));
  }, [dispatch, isMobileApp]);

  useEffect(() => {
    if (!loading) {
      pushAuthenticatedEvent('InitSessionPayment', {
        is_firstpurchase: candidateForFirstPurchase
      });
    }
  }, [loading]);

  useEffect(() => {
    if (redirectStatus === 'succeeded') {
      modifyFinalStep('COMPLETE');
    } else if (redirectStatus === 'failed') {
      modifyFinalStep('ERROR');
    }
  }, [redirectStatus]);

  const Content =
    !clientSecret || !session ? (
      <RBox w={{ base: undefined, md: 600 }}>
        <RecapSkeleton />
      </RBox>
    ) : finalStep === 'COMPLETE' || status === 'succeeded' ? (
      <Completed
        onClose={() => navigateBack()}
        context={context}
        sessionDate={session.sessionDate}
      />
    ) : finalStep === 'ERROR' ? (
      <Error
        onClose={() => {
          modifyFinalStep(undefined);
          modifyStep(RecurrentPaymentStepper.FIRST_STEP);
        }}
      />
    ) : (
      <>
        {step === 'FIRST_STEP' && (
          <StripeProvider clientSecret={clientSecret}>
            <FirstStep
              selectedCard={selectedCard}
              onEditBillingInfo={() => {
                modifyStep(RecurrentPaymentStepper.BILLING_INFO);
              }}
              onEditPaymentMethod={() => {
                modifyStep(RecurrentPaymentStepper.PAYMENT_METHOD);
              }}
              onComplete={() => modifyFinalStep('COMPLETE')}
              onError={() => modifyFinalStep('ERROR')}
              context={context}
            />
          </StripeProvider>
        )}
        {step === 'BILLING_INFO' && (
          <BillingInfo onNext={goToFirstStep} onBack={goToFirstStep} />
        )}
        {step === 'PAYMENT_METHOD' && (
          <StripeProvider clientSecret={cardIntent ?? undefined}>
            <PaymentMethod
              onBack={goToFirstStep}
              onNext={(card: ICreditCard | ISepaDebit | 'klarna') => {
                modifySelectedCard(card);
                goToFirstStep?.();
              }}
            />
          </StripeProvider>
        )}
      </>
    );

  return !isMobileApp ? (
    <Modal open w={600} dataTestId="pay-session-modal">
      <Stack
        position="sticky"
        top={0}
        right={0}
        pt="xs"
        pr="xs"
        justify="end"
        style={{ zIndex: 5, marginBottom: -44 }}
      >
        <ActionIcon
          data-testid="pay-session-modal-close"
          variant="ghost"
          type="button"
          onClick={navigateBack}
        >
          <Cross />
        </ActionIcon>
      </Stack>
      {Content}
    </Modal>
  ) : (
    <Stack grow direction="column" h="100%">
      {Content}
    </Stack>
  );
};

export const NewPaySession: React.FC<{
  context: PaymentContext;
}> = ({ context }) => (
  <StripeProvider>
    <PaySessionContent context={context} />
  </StripeProvider>
);
