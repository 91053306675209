import { Divider } from '@unobravo-monorepo/common/components/Divider';
import {
  IAppointment,
  SessionStatus
} from '@unobravo-monorepo/common/types/IAppointments';
import { AppointmentCardBubbleBody } from './AppointmentCardBubbleBody';
import { AppointmentCardBubbleHeader } from './AppointmentCardBubbleHeader';

const canModifyCTAMap = ['CONFIRMED', 'NEW', 'VIDEOCALL'];

const getTextColor = (variant: SessionStatus) => {
  switch (variant) {
    case 'CANCELED':
    case 'EXPIRED':
      return 'grey.500';
    default:
      return 'grey.800';
  }
};

const getDotColor = (variant: SessionStatus) => {
  switch (variant) {
    case 'DONE':
      return 'spinach.500';
    case 'CANCELED':
    case 'EXPIRED':
      return 'grey.500';
    default:
      return 'candy.500';
  }
};

export const AppointmentCardBubble = ({
  appointment,
  variant,
  onModify,
  onPayClicked,
  onVideocallClicked,
  onExpiredClicked,
  showExpiredCTA = false,
  sessionCategory
}: {
  appointment: IAppointment;
  variant: SessionStatus;
  onModify: () => void;
  showExpiredCTA?: boolean;
  onPayClicked?: (arg?: boolean) => void;
  onVideocallClicked?: () => void;
  onExpiredClicked?: () => void;
  sessionCategory?: string;
}) => {
  const textColor = getTextColor(variant);
  const dotColor = getDotColor(variant);
  const canModify = canModifyCTAMap.includes(variant);

  return (
    <>
      <AppointmentCardBubbleHeader
        dotColor={dotColor}
        textColor={textColor}
        appointment={appointment}
        canModify={canModify}
        onModify={onModify}
      />
      <AppointmentCardBubbleBody
        variant={variant}
        textColor={textColor}
        onPayClicked={onPayClicked}
        onVideocallClicked={onVideocallClicked}
        onExpiredClicked={onExpiredClicked}
        showExpiredCTA={showExpiredCTA}
        sessionCategory={sessionCategory}
      />
    </>
  );
};
