import { BundleCard } from './BundleCard';
import { useBundle } from '../../../features/bundles/hooks/useBundle';
import { useBundleExperiment } from '../../../features/bundles/hooks/useBundleExperiment';

export const HomepageBundleWidget = () => {
  const { latestBundle } = useBundle();
  const { isBundleOpened } = useBundleExperiment();

  return latestBundle ? (
    <BundleCard isInHomepage bundle={latestBundle} />
  ) : (
    isBundleOpened && <BundleCard isInHomepage />
  );
};
