import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import { ArrowLeft } from '@unobravo/zenit-icons';
import { Box, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { BundlePageContent } from '../components/BundlePageContent';

export const BundlesPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <PatientLayout>
      <PageContainer>
        <PageContainerHeader>
          <Box px="xs">
            <Text variant="lg" fontWeight="semibold">
              {t('bundles.pageTitle')}
            </Text>
          </Box>
        </PageContainerHeader>
        {isMobile && (
          <Stack
            bgColor="cappuccino.50"
            borderTop="sm"
            borderBottom="sm"
            borderColor="cappuccino.200"
            w="100%"
            h={50}
            align="center"
            justify="space-between"
            style={{ flexShrink: 0, cursor: 'pointer' }}
            data-testid="bundles-page-go-back"
            onClick={goBack}
          >
            <ArrowLeft
              size="md"
              style={{
                marginRight: 'auto',
                marginLeft: '10px'
              }}
            />

            <Text
              variant="lg"
              fontWeight="semibold"
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              {t('bundles.pageTitle')}
            </Text>
          </Stack>
        )}
        <BundlePageContent />
      </PageContainer>
    </PatientLayout>
  );
};
