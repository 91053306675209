import { mediaQueries } from '@unobravo-monorepo/common';
import { Checkbox } from '@unobravo-monorepo/common/components/Checkbox';
import {
  InformedConsentRecipient,
  useInformedConsentData
} from '@unobravo/patient';
import { useColor } from '@unobravo/zenit-core';
import { Box, Button, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ArrowRight } from '@unobravo/zenit-icons';
import { convertDateFormat } from '@unobravo-monorepo/common/utils/dateUtils';
import { generateFirstSixFiscalCode } from '@unobravo-monorepo/common/utils/generateFiscalCode';
import { useABTestVariant } from '@unobravo-monorepo/common/hooks';
import { useErrorHandler } from '../../../../shared/hooks/useErrorHandler';
import { useBillingInfo } from '../../../billingInfo/hooks/useBillingInfo';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { ConsentForm } from './ConsentForm';
import { TabDivider } from './TabDivider';

interface IConfirmStep {
  closeModal?: () => void;
  nextStep: () => void;
  modify: boolean;
}

interface ICustomElement {
  children: ReactNode;
}

const ButtonContainer = styled(
  ({ inputHasFocus, borderColor, bgColor, ...props }) => (
    <motion.div {...props} />
  )
)<{
  inputHasFocus?: boolean;
  borderColor: string;
  bgColor: string;
}>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${({ borderColor }) => borderColor};
  padding-top: 20px;
  padding-bottom: 24px;
  position: sticky;
  bottom: 0;
  background: ${({ bgColor }) => bgColor};
  ${({ inputHasFocus }) =>
    mediaQueries('sm')(`
    position:
      ${inputHasFocus ? 'relative' : 'sticky'};
  `)}
`;

const Divider = () => <Box h="1px" bgColor="grey.200" mt="2xl" mb="2xl" />;

const CustomText = ({ children }: ICustomElement) => (
  <Text variant="md" color="grey.800">
    {children}
  </Text>
);

const CustomBoldText = ({ children }: ICustomElement) => (
  <Text variant="md" color="grey.900" fontWeight="semibold">
    {children}
  </Text>
);

const LeastBox = ({ children }: ICustomElement) => (
  <Box mb="md">
    <CustomText>{children}</CustomText>
  </Box>
);

export const ConsentFormStep: React.FC<IConfirmStep> = ({
  closeModal,
  nextStep,
  modify
}) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const { t } = useTranslation();
  const {
    prefForWhom,
    doctor,
    name: patientName,
    surname: patientSurname
  } = usePatient();
  const { consentData, updateConsentData, loaded } = useInformedConsentData();
  const { isMobile } = useBreakpointValue();
  const { sendGenericError, sendErrorMessage } = useErrorHandler();
  const [showAddressFields, setShowAddressFields] = useState<
    Record<'child' | 'partner' | 'patient', boolean>
  >({
    child: false,
    partner: false,
    patient: false
  });
  const [showBirthPlaceAndDateFields, setShowBirthPlaceAndDateFields] =
    useState<Record<'child' | 'partner' | 'patient', boolean>>({
      child: false,
      partner: false,
      patient: false
    });
  const {
    billingAddress,
    billingCap,
    billingCf,
    billingCity,
    billingName,
    billingSurname,
    billingProvince,
    billingCountryIsoCode,
    loaded: billingLoaded
  } = useBillingInfo();
  const greyColor = useColor('grey.100');
  const whiteColor = useColor('white');

  const { __typename: typeName, ...patientData } = consentData?.patient ?? {};
  const { __typename: cTypeName, ...childData } = consentData?.child ?? {};
  const { __typename: pTypeName, ...partnerData } = consentData?.partner ?? {};

  const mappedBillingData = {
    name: billingName,
    surname: billingSurname,
    taxCode: billingCf,
    residenceZip: billingCap,
    residenceTown:
      billingCity && billingProvince
        ? `${billingCity} (${billingProvince})`
        : undefined,
    residenceStreet: billingAddress,
    residenceProvince: billingProvince,
    residenceCountry: billingCountryIsoCode,
    birthCountry: !billingCountryIsoCode
      ? undefined
      : ['IT', 'ITA', 'ES'].includes(billingCountryIsoCode)
      ? billingCountryIsoCode
      : 'OTHER'
  } as InformedConsentRecipient | undefined;
  const defaultPatientData =
    prefForWhom === 'DAUGHTER' ? undefined : mappedBillingData;
  const defaultChildData =
    prefForWhom === 'DAUGHTER' ? mappedBillingData : undefined;
  const defaultFormValues = {
    patient: consentData?.patient ? patientData : defaultPatientData,
    partner: consentData?.partner ? partnerData : undefined,
    singleLegalGuardian: false,
    child: consentData?.child ? childData : defaultChildData
  };
  if (!consentData?.patient && defaultFormValues.patient) {
    if (patientName) {
      defaultFormValues.patient.name = patientName!;
    }
    if (patientSurname) {
      defaultFormValues.patient.surname = patientSurname!;
    }
    if (patientName && patientSurname && !defaultFormValues.patient.taxCode) {
      defaultFormValues.patient.taxCode = generateFirstSixFiscalCode(
        patientName,
        patientSurname
      );
    }
  }
  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState,
    getValues,
    unregister
  } = methods;
  const isSingleLegalGuardian = watch('singleLegalGuardian');

  const onSubmit: SubmitHandler<{
    patient: InformedConsentRecipient | undefined;
    child: InformedConsentRecipient | undefined;
    partner: InformedConsentRecipient | undefined;
    singleLegalGuardian: boolean;
  }> = async ({ child, partner, patient, singleLegalGuardian }) => {
    if (child?.birthDate) {
      /* eslint-disable-next-line no-param-reassign */
      child = { ...child, birthDate: convertDateFormat(child.birthDate) };
    }
    if (partner?.birthDate) {
      /* eslint-disable-next-line no-param-reassign */
      partner = {
        ...partner,
        birthDate: convertDateFormat(partner.birthDate)
      };
    }
    if (patient?.birthDate) {
      /* eslint-disable-next-line no-param-reassign */
      patient = {
        ...patient,
        birthDate: convertDateFormat(patient.birthDate)
      };
    }
    if (prefForWhom === 'DAUGHTER') {
      if (!showAddressFields.child && !child?.residenceZip) {
        setShowAddressFields({ ...showAddressFields, child: true });
        return;
      }
      if (!showBirthPlaceAndDateFields.child && !child?.birthPlace) {
        setShowBirthPlaceAndDateFields({
          ...showBirthPlaceAndDateFields,
          child: true
        });
        return;
      }
      if (!isSingleLegalGuardian) {
        if (!showAddressFields.partner && !partner?.residenceZip) {
          setShowAddressFields({ ...showAddressFields, partner: true });
          return;
        }
        if (!showBirthPlaceAndDateFields.partner && !partner?.birthPlace) {
          setShowBirthPlaceAndDateFields({
            ...showBirthPlaceAndDateFields,
            partner: true
          });
          return;
        }
      }
    }
    if (!showAddressFields.patient && !patient?.residenceZip) {
      setShowAddressFields({ ...showAddressFields, patient: true });
      return;
    }
    if (!showBirthPlaceAndDateFields.patient && !patient?.birthPlace) {
      setShowBirthPlaceAndDateFields({
        ...showBirthPlaceAndDateFields,
        patient: true
      });
      return;
    }

    const result = await updateConsentData({
      child,
      patient,
      partner: singleLegalGuardian ? undefined : partner,
      version: '2.0.1'
    });
    if (result) {
      nextStep();
      return;
    }
    sendGenericError();
  };

  const onError: SubmitErrorHandler<{
    patient: InformedConsentRecipient | undefined;
    child: InformedConsentRecipient | undefined;
    partner: InformedConsentRecipient | undefined;
    singleLegalGuardian: boolean;
  }> = (formErrors) => {
    if (isSingleLegalGuardian) return;

    const missingRequiredPartnerFields = Object.values(
      formErrors.partner || {}
    ).some((value) => {
      if (typeof value === 'string') {
        return false;
      }
      return value.type === 'required';
    });

    if (missingRequiredPartnerFields) {
      sendErrorMessage(
        t('legal:informedConsent.signModal.errors.partnerRequired.description'),
        t('legal:informedConsent.signModal.errors.partnerRequired.title')
      );
    }
  };
  useEffect(() => {
    // We unregister "partner" fields so the validation doesn't prevent us to go next
    if (isSingleLegalGuardian && getValues('partner')) {
      unregister('partner');
    }
  }, [isSingleLegalGuardian]);
  useEffect(() => {
    if (!loaded || (!consentData && !billingLoaded)) {
      return;
    }
    reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentData, loaded, billingLoaded]);

  const { doctorRegisterNumber, doctorRegisterRegion, name, surname, sex } =
    doctor || {};

  return (
    <FormProvider {...methods}>
      <Box mb="xs">
        <CustomBoldText>
          {t('legal:informedConsent.signModal.between')}
        </CustomBoldText>
        <Box mb="2xs" mt="2xs">
          <CustomText>
            {t(`legal:informedConsent.signModal.tpName.${sex || 'FEMALE'}`, {
              name,
              surname
            })}
          </CustomText>
          {doctorRegisterNumber && doctorRegisterRegion && (
            <CustomText>
              {t(
                `legal:informedConsent.signModal.tpRegistration.${
                  sex || 'FEMALE'
                }`,
                {
                  number: doctorRegisterNumber,
                  region: doctorRegisterRegion
                }
              )}
            </CustomText>
          )}
          <CustomText>
            {t('legal:informedConsent.signModal.descriptionTp')}
          </CustomText>
        </Box>
        <CustomBoldText>
          {t('legal:informedConsent.signModal.and')}
        </CustomBoldText>
      </Box>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {prefForWhom === 'DAUGHTER' && (
          <>
            <TabDivider
              title={t('legal:informedConsent.signModal.childSection')}
              sticky
            />
            <ConsentForm
              person="child"
              setInputHasFocus={setInputHasFocus}
              showAddressFields={
                (formState.touchedFields?.child &&
                  formState.touchedFields.child.residenceStreet) ||
                !!getValues('child.residenceZip') ||
                showAddressFields.child
              }
              showBirthPlaceAndDateFields={
                (formState.touchedFields?.child &&
                  formState.touchedFields.child.taxCode) ||
                !!getValues('child.birthPlace') ||
                showBirthPlaceAndDateFields.child
              }
            />
            <LeastBox>
              {t(`legal:informedConsent.signModal.descriptionPz`)}
            </LeastBox>
          </>
        )}
        {prefForWhom !== 'ME' && (
          <TabDivider
            title={
              prefForWhom === 'DAUGHTER'
                ? isSingleLegalGuardian
                  ? t('legal:informedConsent.signModal.singleParentSection')
                  : t('legal:informedConsent.signModal.parentSection')
                : t('legal:informedConsent.signModal.patientSection')
            }
            sticky
          />
        )}
        <ConsentForm
          person="patient"
          setInputHasFocus={setInputHasFocus}
          showAddressFields={
            (formState.touchedFields?.patient &&
              formState.touchedFields.patient.residenceStreet) ||
            !!getValues('patient.residenceZip') ||
            showAddressFields.patient
          }
          showBirthPlaceAndDateFields={
            (formState.touchedFields?.patient &&
              formState.touchedFields.patient.taxCode) ||
            !!getValues('patient.birthPlace') ||
            showBirthPlaceAndDateFields.patient
          }
        />
        {prefForWhom === 'DAUGHTER' && (
          <Checkbox
            {...register('singleLegalGuardian')}
            label={t(
              `legal:informedConsent.signModal.labels.singleLegalGuardian`
            )}
            id="singleLegalGuardian"
            name="singleLegalGuardian"
            data-testid="single-legal-guardian"
          />
        )}
        {prefForWhom === 'DAUGHTER' && !isSingleLegalGuardian && (
          <>
            <Divider />
            <ConsentForm
              person="partner"
              setInputHasFocus={setInputHasFocus}
              showAddressFields={
                (formState.touchedFields?.partner &&
                  formState.touchedFields.partner.residenceStreet) ||
                !!getValues('partner.residenceZip') ||
                showAddressFields.partner
              }
              showBirthPlaceAndDateFields={
                (formState.touchedFields?.partner &&
                  formState.touchedFields.partner.taxCode) ||
                !!getValues('partner.birthPlace') ||
                showBirthPlaceAndDateFields.partner
              }
            />
          </>
        )}
        {prefForWhom !== 'DAUGHTER' && (
          <LeastBox>
            {t(`legal:informedConsent.signModal.descriptionPz`)}
          </LeastBox>
        )}
        <ButtonContainer
          position="sticky"
          bottom={0}
          borderColor={greyColor}
          bgColor={whiteColor}
          inputHasFocus={inputHasFocus}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          layout
        >
          <Button
            size="lg"
            data-testid="submit-consent-form"
            type="submit"
            label={t(modify ? 'common:modifyConfirm' : 'common:buttonNext')}
            icon={ArrowRight}
            iconPosition={modify ? undefined : 'right'}
          />
          {closeModal != null && (
            <Button
              size="lg"
              variant="ghost"
              label={t(
                modify && !isMobile ? 'common:modifyCancel' : 'common:cancel'
              )}
              onClick={closeModal}
            />
          )}
        </ButtonContainer>
      </form>
    </FormProvider>
  );
};
