import { Check, CreditNote, Invoice, Rotate } from '@unobravo/zenit-icons';
import { Button, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

const InvoiceButton = ({
  onClick,
  variant = 'invoice',
  label
}: {
  onClick: () => void;
  label: string;
  variant?: 'invoice' | 'creditnote';
}) => (
  <Button
    onClick={onClick}
    size="md"
    variant="outlined"
    icon={variant === 'invoice' ? Invoice : CreditNote}
    iconPosition="left"
    colorVariant="primary"
    loading={false}
    label={label}
    data-testid="bundle-bubble-invoice-cta"
  />
);

export const BundleBubble = ({
  title,
  subtitle,
  buttonLabel,
  onClick,
  variant,
  updateAt,
  isCompleted = false
}: {
  title: string;
  subtitle: string;
  buttonLabel: string;
  onClick: () => void;
  variant?: 'invoice' | 'creditnote';
  updateAt?: string;
  isCompleted: boolean;
}) => {
  const { t } = useTranslation();

  const updateOutcome = isCompleted
    ? 'invoices.bundlefinalUpdateAt'
    : 'invoices.bundleUpdateAt';

  return (
    <Stack
      data-testid="bundle-bubble"
      p="md"
      spacing="sm"
      bgColor="cappuccino.50"
      rounded="md"
      direction="column"
    >
      <Stack direction="row" justify="space-between">
        <Stack direction="column" pr="xs" spacing="2xs">
          <Text variant="lg" color="candy.500" fontWeight="semibold">
            {title}
          </Text>
          <Text variant="sm" color="black">
            {subtitle}
          </Text>
        </Stack>

        <InvoiceButton
          onClick={onClick}
          variant={variant}
          label={buttonLabel}
        />
      </Stack>
      <Stack
        p="xs"
        direction="row"
        align="center"
        spacing="xs"
        bgColor="white"
        rounded="xs"
        borderColor="cappuccino.100"
        border="sm"
      >
        <Stack
          rounded="full"
          bgColor={isCompleted ? 'edamame.100' : 'ginger.100'}
          align="center"
          justify="center"
          w="24px"
          h="24px"
        >
          {isCompleted ? (
            <Check size="sm" color="edamame.700" />
          ) : (
            <Rotate size="sm" color="ginger.700" />
          )}
        </Stack>
        <Text variant="sm">{t(updateOutcome, { updateAt })}</Text>
      </Stack>
    </Stack>
  );
};
