import {
  getFootnote,
  transformAmount
} from '@unobravo-monorepo/common/utils/priceUtils';
import { NewPaymentMethodComp } from '@unobravo-monorepo/patient/features/payment/components/NewPaymentMethodComp';
import { ICreditCard, ISepaDebit, useBillingInfoV2 } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { useColor } from '@unobravo/zenit-core';
import { Pencil, Plus } from '@unobravo/zenit-icons';
import {
  Alert,
  Box,
  Button,
  Heading,
  RStack,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { usePatientGTM } from '../../shared/hooks/usePatientGTM';
import { useSession } from '../../shared/hooks/useSession';
import { usePatient } from '../patientData/hooks/usePatient';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { DateContainer } from './components/DateContainer';
import { GiftCard } from './components/GiftCard';
import { RecapSkeleton } from './components/RecapSkeleton';
import { paySessionDataSelector, setVoucher } from './paySessionData.slice';
import { PaymentContext } from './types/paymentContext';
import { BundleAlert } from './components/bundles/BundleAlert';

const Divider = () => (
  <Box
    borderColor="grey.100"
    borderTop="md"
    w="100%"
    style={{ alignSelf: 'center' }}
  />
);

const CustomRipple = styled.button<{ ripple?: string }>`
  all: unset;
  overflow: hidden;
  &:active {
    background-color: ${({ ripple }) => ripple};
  }
`;

const Card = ({
  children,
  title,
  onClick,
  dataTestId,
  isModifyMode,
  disabled
}: {
  children: ReactNode;
  title: string;
  onClick: () => void;
  dataTestId: string;
  isModifyMode?: boolean;
  disabled?: boolean;
}) => {
  const cappuccino = useColor('cappuccino.50');
  const { t } = useTranslation();
  return (
    <Stack data-testid={dataTestId} rounded="2xs" w="100%" direction="column">
      <CustomRipple
        ripple={cappuccino}
        type="button"
        onClick={isModifyMode ? onClick : undefined}
        disabled={disabled}
      >
        <Stack align="center" mb="2xs">
          <Box grow>
            <Text variant="lg" fontWeight="bold" color="grey.800">
              {title}
            </Text>
          </Box>
          {isModifyMode ? (
            <Pencil size="sm" color="candy.500" />
          ) : (
            <Button
              label={t('paySession.paymentMethod.addButtonLabel')}
              size="md"
              icon={Plus}
              onClick={onClick}
            />
          )}
        </Stack>
        {children}
      </CustomRipple>
    </Stack>
  );
};

const MotionBox = motion(Box);

export const FirstStep = ({
  onEditBillingInfo,
  onEditPaymentMethod,
  onError,
  onComplete,
  selectedCard,
  context
}: {
  onEditBillingInfo: () => void;
  onEditPaymentMethod: () => void;
  onError: () => void;
  onComplete: () => void;
  selectedCard?: ICreditCard | ISepaDebit | 'klarna';
  context: PaymentContext;
}) => {
  const { id } = usePatient();
  const { billingInfoData: billingInfo } = useBillingInfoV2(id!);
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const sessionData = useSession(sessionId!);
  const dispatch = useDispatch();
  const {
    amount: originalAmount,
    paySession,
    paymentLoading: loading
  } = useStripePaymentIntent(sessionId!);
  const { voucher, paymentIntent } = useSelector(paySessionDataSelector);
  const amount = voucher?.total ?? originalAmount;
  const { pushAuthenticatedEvent } = usePatientGTM();
  const {
    name: billingName,
    surname: billingSurname,
    address: billingAddress,
    city: billingCity,
    country: billingCountry,
    cf: billingCf,
    province: billingProvince,
    cap: billingCap
  } = billingInfo ?? {};
  const { domainCountry } = useCountry();

  if (!sessionData || sessionData.dataLoading) {
    return <RecapSkeleton />;
  }
  const convertToNumber = (value: string) =>
    parseFloat(value.replace(',', '.'));

  const isTotalVoucher =
    voucher?.success &&
    originalAmount &&
    voucher.discount === convertToNumber(originalAmount) * 100;

  const payButtonDisabled = isTotalVoucher
    ? loading || !billingInfo
    : loading || !billingInfo || !selectedCard;

  const showAlertPromoCode = isTotalVoucher
    ? voucher?.success && !billingInfo
    : voucher?.success && (!selectedCard || !billingInfo);

  const showPaymentMethod = !isTotalVoucher || selectedCard;

  const pay = async () => {
    try {
      await paySession();
      onComplete();
    } catch {
      onError();
    }
  };

  const footnote = getFootnote(domainCountry, paymentIntent?.amount);

  return (
    <>
      <Stack direction="column" px="xl" pt="xl">
        <Heading variant="lg" color="grey.900">
          {t('paySession.appointmentRecap.title')}
        </Heading>
      </Stack>

      <Stack direction="column" px="xl" spacing="md" pt="md">
        <Box bgColor="cappuccino.100" rounded="md">
          <DateContainer sessionDate={sessionData.sessionDate} />
        </Box>

        <Card
          isModifyMode={!!billingInfo}
          dataTestId="edit-billing-info"
          onClick={onEditBillingInfo}
          title={t('personalInfo.billingInfo.title')}
          disabled={loading}
        >
          {billingInfo && (
            <>
              <Text color="grey.800">{`${billingName} ${billingSurname} - ${billingCf}`}</Text>
              <Text color="grey.800">{`${billingAddress}, ${billingCity}, ${billingProvince} ${billingCap} ${billingCountry}`}</Text>
            </>
          )}
        </Card>
        <Divider />

        {showPaymentMethod && (
          <>
            <Card
              isModifyMode={!!selectedCard}
              dataTestId="edit-payment-method"
              onClick={onEditPaymentMethod}
              title={t('paySession.paymentMethod.title')}
              disabled={loading}
            >
              <NewPaymentMethodComp paymentMethod={selectedCard} />
            </Card>
            <Divider />
          </>
        )}

        <Text variant="lg" fontWeight="bold" color="grey.800">
          {t('paySession.discountedCode')}
        </Text>
        <GiftCard
          appliedVoucher={voucher}
          onVoucherConfirmed={(v) => {
            dispatch(
              setVoucher({
                ...v,
                total: transformAmount(v.total)!
              })
            );
            pushAuthenticatedEvent('AppliedVoucher');
          }}
          originalAmount={originalAmount!}
          sessionId={sessionData.uuid}
        />
        <BundleAlert context={context} uuid={sessionData.uuid} />
      </Stack>
      {showAlertPromoCode && (
        <MotionBox
          key="loading-state"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Stack px="xl" pt="xl" w="100%">
            <Alert
              w="100%"
              icon={false}
              message={
                voucher?.success && isTotalVoucher
                  ? t('paySession.promoCode100%Alert')
                  : t('paySession.promoCodeAlert')
              }
              type="informative"
            />
          </Stack>
        </MotionBox>
      )}

      <Stack spacing="xs" direction="column" p="xl">
        <RStack justify="end" direction={{ xs: 'column', md: 'row' }}>
          <Button
            onClick={pay}
            label={`${t(`paySession.confirmPayment`, {
              cost: voucher?.total ?? amount
            })}${footnote}`}
            loading={loading}
            disabled={payButtonDisabled}
            size="lg"
            data-testid="pay-session-button"
          />
        </RStack>
        {footnote && (
          <Text variant="sm" color="grey.600">
            {t('paySession.footnotes.esPriceChange')}
          </Text>
        )}
      </Stack>
    </>
  );
};
