import { useExperiment } from '@statsig/react-bindings';

export type BundleV2Variant = 'control' | 'paywall' | 'alert';

export const useBundleExperiment = () => {
  /** Fake bundle test */
  useExperiment('fake-bundles-test-nc');

  /** Bundle v2 on Statsig */
  const bundlev2 = useExperiment('bundle_v2');
  const bundleVariant = bundlev2.get<BundleV2Variant>(
    'variant',
    'control' // fallback
  ) as BundleV2Variant;

  const isBundleOpened = bundleVariant !== 'control';

  return {
    isBundleOpened,
    bundleVariant,
    bundlePaywallV2: true,
    paywallVariant: bundleVariant === 'paywall',
    showBuyBundlesAlert: bundleVariant === 'alert'
  };
};
