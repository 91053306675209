import { Button, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const BuyBackCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack
      p="md"
      direction="column"
      spacing="xl"
      rounded="lg"
      bgColor="cappuccino.100"
    >
      <Stack direction="column" spacing="xs">
        <Text variant="lg" fontWeight="semibold">
          {t('bundles.buyBackCard.title')}{' '}
        </Text>
        <Text variant="md" color="grey.600">
          {t('bundles.buyBackCard.subtitle')}{' '}
        </Text>
      </Stack>
      <Button
        size="lg"
        data-testid="bundle-buy-back-card-cta"
        label={t('bundles.buyBackCard.cta')}
        onClick={() => {
          navigate(`../dialog/bundles/bundlePaySession`, {
            state: {
              modal: location
            }
          });
        }}
      />
    </Stack>
  );
};
