import { useFirstPayment } from '@unobravo/patient';
import { Cross } from '@unobravo/zenit-icons';
import {
  ActionIcon,
  Modal,
  Stack,
  useBreakpointValue
} from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePatientGTM } from '../../shared/hooks/usePatientGTM';
import { useSession } from '../../shared/hooks/useSession';
import { setIsMobileApp } from '../layout/layout.slice';
import {
  paySessionDataSelector,
  reset,
  setStep
} from '../newPaySession/paySessionData.slice';
import { PaymentContext } from '../newPaySession/types/paymentContext';
import { PaymentStep } from '../newPaySession/types/paymentStepper.types';
import { usePatient } from '../patientData/hooks/usePatient';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { StripeProvider } from '../payment/providers/stripeProvider';
import { BillingInfo } from './BillingInfo';
import { Completed } from './Completed';
import { Error } from './Error';
import { PaymentMethod } from './PaymentMethod';
import { Recap } from './Recap';
import { RecapSkeleton } from './components/RecapSkeleton';

const stepper: Record<PaymentStep, { back?: PaymentStep; next?: PaymentStep }> =
  {
    RECAP: {
      next: 'BILLING_INFO'
    },
    BILLING_INFO: {
      back: 'RECAP',
      next: 'PAYMENT_METHOD'
    },
    PAYMENT_METHOD: {
      back: 'BILLING_INFO'
    },
    PAYMENT_RECAP: {},
    FIRST_STEP: {}
  };

const PaySessionContent: React.FC<{
  context: PaymentContext;
}> = ({ context }) => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpointValue();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const { uuid } = usePatient();
  const session = useSession(sessionId!);
  const { candidateForFirstPurchase, loading } = useFirstPayment(sessionId!);
  const { clientSecret, status } = useStripePaymentIntent(sessionId!);
  const { step } = useSelector(paySessionDataSelector);
  const currentStepMap = stepper[step as PaymentStep];
  const isMobileApp = context === 'MOBILE';
  const [finalStep, modifyFinalStep] = useState<'COMPLETE' | 'ERROR'>();
  const [urlParams] = useSearchParams();
  const redirectStatus = urlParams.get('redirect_status');

  const modifyStep = (s?: PaymentStep) => s && dispatch(setStep(s));
  const goBack = currentStepMap?.back
    ? () => modifyStep(currentStepMap.back)
    : undefined;
  const goNext = currentStepMap?.next
    ? () => modifyStep(currentStepMap.next)
    : undefined;

  const navigateBack = () => {
    session.refetch();
    switch (context) {
      case 'AGENDA':
        navigate('../agenda');
        break;
      case 'CHAT':
        navigate('../chat');
        break;
      case 'THERAPY_SETTING':
        navigate('../therapy-setting');
        break;
      case 'HOME':
        navigate('../home');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    modifyStep('RECAP');
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    const isSessionInvalid =
      session.uuid === sessionId &&
      (session.status === 'CANCELED' || session.status === 'EXPIRED');

    if (isSessionInvalid) {
      navigateBack();
    }
  }, [session.status, session.uuid, sessionId]);

  useEffect(() => {
    dispatch(setIsMobileApp(isMobileApp));
  }, [dispatch, isMobileApp]);

  useEffect(() => {
    if (!loading) {
      pushAuthenticatedEvent('InitPaySession', {
        is_firstpurchase: candidateForFirstPurchase,
        uuid
      });
    }
  }, [loading]);

  useEffect(() => {
    if (redirectStatus === 'succeeded') {
      modifyFinalStep('COMPLETE');
    } else if (redirectStatus === 'failed') {
      modifyFinalStep('ERROR');
    }
  }, [redirectStatus]);

  const Content =
    !clientSecret || !session ? (
      <RecapSkeleton />
    ) : finalStep === 'COMPLETE' || status === 'succeeded' ? (
      <Completed
        onClose={() => navigateBack()}
        context={context}
        sessionDate={session.sessionDate}
      />
    ) : finalStep === 'ERROR' ? (
      <Error onClose={() => modifyFinalStep(undefined)} />
    ) : (
      <>
        {step === 'RECAP' && <Recap onNext={goNext} context={context} />}
        {step === 'BILLING_INFO' && (
          <BillingInfo onNext={goNext} onBack={goBack} />
        )}
        {step === 'PAYMENT_METHOD' && (
          <StripeProvider clientSecret={clientSecret}>
            <PaymentMethod
              onBack={goBack}
              onError={() => modifyFinalStep('ERROR')}
              onComplete={() => modifyFinalStep('COMPLETE')}
            />
          </StripeProvider>
        )}
      </>
    );

  return !isMobileApp ? (
    <Modal
      open
      w={600}
      {...(isMobile ? { h: '90%' } : { h: 660 })}
      dataTestId="pay-session-modal"
    >
      <Stack h="100%" direction="column">
        <Stack
          position="sticky"
          top={0}
          right={0}
          pt="xs"
          pr="xs"
          justify="end"
          style={{ zIndex: 5, marginBottom: -44 }}
        >
          <ActionIcon
            data-testid="pay-session-modal-close"
            variant="ghost"
            type="button"
            onClick={navigateBack}
          >
            <Cross />
          </ActionIcon>
        </Stack>
        {Content}
      </Stack>
    </Modal>
  ) : (
    <Stack grow direction="column" h="100%">
      {Content}
    </Stack>
  );
};

export const PaySession: React.FC<{
  context: PaymentContext;
}> = ({ context }) => (
  <StripeProvider>
    <PaySessionContent context={context} />
  </StripeProvider>
);
