import { Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { BundleRadioItem } from '../BundleRadioItem';
import { BundlesVariations } from '../../../../types/Bundles';
import {
  getBundleMap,
  getBundlePriceInfo,
  getSingleSession
} from '../bundle-map';

const getDecimals = (stringNum: string) => {
  const decimalPart = stringNum.split('.')[1];
  return decimalPart ? decimalPart.substring(0, 2).padEnd(2, '0') : '';
};

const getAmount = (amount: number, fractionDigits = 2) => {
  if (amount % 1 !== 0) {
    const stringAmount = parseFloat(amount.toFixed(fractionDigits)).toString();
    return {
      integerPart: String(Math.floor(amount)),
      decimalPart: getDecimals(stringAmount)
    };
  }
  return {
    integerPart: amount.toString(),
    decimalPart: ''
  };
};

export const BundleFormItem = ({
  bundleId,
  sessionPrice,
  bundlesVariant,
  hasDiscount = true,
  showDescription = true
}: {
  bundleId: string;
  sessionPrice: number;
  bundlesVariant: BundlesVariations;
  hasDiscount?: boolean;
  showDescription?: boolean;
}) => {
  const { t } = useTranslation();
  const bundle = [
    ...getBundleMap(hasDiscount),
    getSingleSession(hasDiscount)
  ].find((elm) => elm.id === bundleId);
  const bundlePriceInfo = getBundlePriceInfo(bundlesVariant, bundleId);

  if (bundle && bundlePriceInfo) {
    const { value, title } = bundle;
    const { bundlePrice, numSessions, expireInMonths } = bundlePriceInfo;

    const fullPrice = numSessions * sessionPrice;

    const { decimalPart, integerPart } = getAmount(bundlePrice / numSessions);

    return (
      <BundleRadioItem
        id={bundleId}
        data-testid="bundle-radio-item-v2"
        value={value}
        title={t(title, { numSessions })}
        finalPriceOutcome={
          !decimalPart
            ? `${integerPart}€ ${t('bundles.bundleFormV2.priceforSession')}`
            : undefined
        }
        decimalPart={decimalPart}
        integerPart={integerPart}
      >
        <>
          {showDescription ? (
            <Text color="grey.700">
              {t('bundles.bundleFormV2.ItemDescription', {
                bundlePrice,
                numSessions,
                expireInMonths,
                fullPrice
              })}
            </Text>
          ) : (
            <></>
          )}
        </>
      </BundleRadioItem>
    );
  }
  return null;
};
