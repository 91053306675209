import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { usePollingRedirect } from '@unobravo-monorepo/common/hooks/usePollingRedirect';

import { captureException } from '@sentry/react';
import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { TouchPointLayout } from '@unobravo-monorepo/common/layouts/touchpoint';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useActivationRegOnTop } from '../hooks/useActivationRegOnTop';

export const RegOnTopVerifyEmail = () => {
  const { code } = useParams();
  const sendToast = useToaster();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { verify: confirmUser } = useActivationRegOnTop();

  const { start: startPolling } = usePollingRedirect();

  const verify = async () => {
    try {
      if (!code) return navigate('/');

      const { data, errors } = (await confirmUser(code)) ?? {};
      const { authToken, visitor } = data?.confirmActivation ?? {};

      if (errors?.length) {
        sendToast?.({
          variant: 'error',
          title: t('common:errorPage.ops'),
          description: t('common:errorPage.retry')
        });

        errors.forEach((e) => captureException(e));
      }

      if (!authToken || !visitor?.id) return navigate('/');
    } catch (error) {
      captureException(error);

      sendToast({
        variant: 'error',
        title: t('common:errorPage.ops'),
        description: t('common:errorPage.retry')
      });
    } finally {
      startPolling();
    }
  };

  useEffect(() => {
    verify();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TouchPointLayout topLeft="" bottomRight="">
      <Spinner />
    </TouchPointLayout>
  );
};
