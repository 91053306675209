import * as RadioGroup from '@radix-ui/react-radio-group';
import { Form, useForm, z, zodResolver } from '@unobravo/zenit-form';
import { Alert, Box, Button, RStack, Stack } from '@unobravo/zenit-web';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  getBundleMap,
  getBundlePriceInfo,
  formSchema,
  getSingleSession,
  labels
} from '../bundle-map';
import { NO_DISCOUNT_VARIANT } from '../bundle-prices-map';
import { BundleFormItem } from './BundleFormItem';
import { BundlesVariations } from '../../../../types/Bundles';
import { TemsAndConditions } from './TemsAndConditions';

const RadioForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button[role='radio'] {
    all: unset;
  }
`;

export const BundleForm = ({
  onSubmitValues,
  onSkip,
  sticky = true,
  bundlesVariant,
  showAlert
}: {
  onSubmitValues: (values: z.infer<typeof formSchema>) => void;
  onSkip?: () => void;
  sticky?: boolean;
  bundlesVariant: BundlesVariations;
  showAlert: boolean;
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      bundle: onSkip ? 'singleSession' : undefined
    }
  });
  const { t } = useTranslation();

  // TODO: to update
  const mockedBaseSessionPrice = 49;

  const hasDiscount = bundlesVariant !== NO_DISCOUNT_VARIANT;
  const bundleMap = getBundleMap(hasDiscount);
  const singleSession = getSingleSession(hasDiscount);

  const getNumSession = (bundleId?: string) => {
    if (bundleId) {
      return getBundlePriceInfo(bundlesVariant, bundleId)?.numSessions || 0;
    }
    return 0;
  };

  const getNumSessionLabel = (bundleId: string) => {
    const numSessions = getNumSession(bundleId);
    if (numSessions === 1) {
      return t(labels.formCTAs.singleSessionV2);
    }
    return t(labels.formCTAs.multipleSessions, { numSessions });
  };

  return (
    <Form {...form}>
      <RadioForm onSubmit={form.handleSubmit(onSubmitValues)}>
        <Box p="lg">
          <Controller
            name="bundle"
            control={form.control}
            render={({ field: { onChange, name, ref, value } }) => (
              <RadioGroup.Root
                style={{
                  display: 'flex',
                  gap: 10,
                  flexDirection: 'column'
                }}
                name={name}
                ref={ref}
                value={value}
                onValueChange={onChange}
              >
                <Stack
                  direction="column"
                  spacing="sm"
                  data-testid="bundle-section"
                >
                  {onSkip && (
                    <BundleFormItem
                      bundleId={singleSession.id}
                      sessionPrice={mockedBaseSessionPrice}
                      bundlesVariant={bundlesVariant}
                      hasDiscount={false}
                      showDescription={false}
                    />
                  )}
                  {[...bundleMap].reverse().map((bundle) => (
                    <BundleFormItem
                      bundleId={bundle.id}
                      key={bundle.id}
                      sessionPrice={mockedBaseSessionPrice}
                      bundlesVariant={bundlesVariant}
                      hasDiscount={hasDiscount}
                    />
                  ))}
                </Stack>

                <TemsAndConditions />
                {!onSkip && showAlert && (
                  <Alert
                    type="informative"
                    message={t('bundles.paywall.noBundles', {
                      fullPrice: mockedBaseSessionPrice
                    })}
                  />
                )}
              </RadioGroup.Root>
            )}
          />
        </Box>

        <Box
          p="md"
          borderTop={
            (sticky && onSkip) || (!onSkip && form.formState?.isValid)
              ? 'sm'
              : undefined
          }
          borderColor={sticky ? 'candy.100' : undefined}
          position={sticky ? 'sticky' : 'relative'}
          bottom={sticky ? 0 : undefined}
          bgColor="white"
        >
          <RStack
            spacing="sm"
            direction={{ base: 'column', md: 'row-reverse' }}
            justify="center"
          >
            {form.formState?.isValid && (
              <Button
                size="lg"
                label={getNumSessionLabel(form.watch('bundle'))}
                type="submit"
                data-testid="bundle-paywall-form-submit-v2"
              />
            )}
          </RStack>
        </Box>
      </RadioForm>
    </Form>
  );
};
