import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  PaymentIntent,
  PaymentIntentResult,
  StripeError
} from '@stripe/stripe-js';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { transformAmount } from '@unobravo-monorepo/common/utils/priceUtils';
import { useErrorHandler } from '@unobravo-monorepo/patient/shared/hooks/useErrorHandler';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import {
  IAppliedVoucher,
  ICreditCard,
  PatientStatus,
  SepaDebit,
  updateCards,
  useFirstPayment,
  useGTMUtils,
  usePaymentIntent
} from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSession } from '../../../shared/hooks/useSession';
import { layoutSelector } from '../../layout/layout.slice';
import {
  paySessionDataSelector,
  setPaymentIntent
} from '../../newPaySession/paySessionData.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { usePatientCards } from '../../patientData/hooks/usePatientCards';
import { useApplyVoucher } from './useApplyVoucher';

export const useStripePaymentIntent = (sessionId: string) => {
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { getPaymentIntent } = usePaymentIntent();
  const session = useSession(sessionId!);
  const { domainCountry } = useCountry();
  const dispatch = useDispatch();
  const { applyBonus, applyVoucher } = useApplyVoucher();
  const { sendGenericError } = useErrorHandler();
  const { status, setStatus, platformCountry, email, doctor, id, uuid } =
    usePatient();
  const { candidateForFirstPurchase } = useFirstPayment(sessionId!);
  const { GTMData } = useGTMUtils();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const stripe = useStripe();
  const elements = useElements();
  const {
    paymentIntent,
    voucher: selectedVoucher,
    selectedCard
  } = useSelector(paySessionDataSelector);
  const { isMobileApp } = useSelector(layoutSelector);
  const { refreshPatientCards } = usePatientCards();

  const fetchPaymentMethod = async () => {
    const { data } = await refreshPatientCards();

    // refetching does not trigger a state update so we need to dispatch the update manually
    // even tho it should since `usePatientCardsUtils` exports `cards` that is used inside the useEffect
    // of `usePatientCards`
    if (data?.getPatient?.cards) {
      dispatch(updateCards({ cards: data.getPatient.cards }));
    }
  };

  const trackEvents = async (
    confirmPaymentIntent: PaymentIntent,
    voucher?: IAppliedVoucher & { code?: string }
  ) => {
    const amount = confirmPaymentIntent.amount / 100;
    const ISODate = session.sessionDate?.rawDate?.toISO();

    pushAuthenticatedEvent('Purchase', {
      total_amount: amount,
      tp_id: doctor?.id,
      tp_first_name: doctor?.name,
      tp_last_name: doctor?.surname,
      session_type: 'card',
      session_date: ISODate,
      currency: 'EUR',
      user_id: id,
      uuid,
      is_firstpurchase: candidateForFirstPurchase,
      voucher_code: voucher?.code,
      voucher_amount: voucher?.discount,
      voucher_campaignname: voucher?.campaignName,
      application: isMobileApp ? 'APP' : 'WEB',
      ...GTMData
    });

    pendoTrack('pay_session', {
      amount
    });
  };

  const updatePatient = () => {
    if (status && ['NEW', 'FREE_SESSION'].includes(status)) {
      setStatus(PatientStatus.Ongoing);
    }
  };

  const wrapErrorMsg = (message: string) => `PaymentError: ${message}`;

  const getStripeErrorMessage = (error: StripeError) =>
    wrapErrorMsg(`[${error.type}] ${error.message}`);

  const applyVerifiedVoucher = async () => {
    if (!selectedVoucher) return;

    let appliedVoucher: IAppliedVoucher | undefined;

    if (selectedVoucher.success) {
      if (selectedVoucher.code) {
        appliedVoucher = await applyVoucher(selectedVoucher.code, session.uuid);
      } else {
        appliedVoucher = await applyBonus(session.uuid);
      }
    }

    if (!appliedVoucher || !appliedVoucher.success) {
      throw new Error(wrapErrorMsg('Fail applying voucher'));
    }

    pendoTrack(
      selectedVoucher.code ? 'voucher_applied' : 'bonus_voucher_applied',
      selectedVoucher
    );

    return { ...appliedVoucher, code: selectedVoucher.code };
  };

  const getPaymentIntentData = useCallback(
    async (clientIntent: string) => {
      if (!stripe) {
        return;
      }
      const result = await stripe.retrievePaymentIntent(clientIntent);
      return result;
    },
    [stripe]
  );

  useEffect(() => {
    async function intent() {
      if (
        session?.id !== undefined &&
        (paymentIntent?.sessionId !== session.id || !paymentIntentId)
      ) {
        const result = await getPaymentIntent(parseInt(session.id, 10));
        setPaymentIntentId(result);
      }
    }
    intent();
  }, [session.id, session.uuid]);

  useEffect(() => {
    async function secret() {
      if (paymentIntentId) {
        const result = await getPaymentIntentData(paymentIntentId);
        if (result?.paymentIntent) {
          dispatch(
            setPaymentIntent({ ...result.paymentIntent, sessionId: session.id })
          );
        }
      }
    }
    secret();
  }, [paymentIntentId, session.id, session.uuid]);

  const confirmPaymentWithSavedId = async (
    clientIntent: string,
    paymentId: string,
    type: 'CARD' | 'SEPA' | 'KLARNA'
  ) => {
    setLoading(true);

    if (!stripe) {
      sendGenericError();
      return;
    }
    let result: PaymentIntentResult | undefined;
    if (type === 'CARD') {
      try {
        result = await stripe.confirmCardPayment(clientIntent, {
          payment_method: paymentId
        });
      } catch {
        // do nothing
      }
    }
    if (type === 'SEPA') {
      try {
        result = await stripe.confirmSepaDebitPayment(clientIntent, {
          payment_method: paymentId
        });
      } catch {
        // do nothing
      }
    }
    if (type === 'KLARNA') {
      try {
        result = await stripe.confirmKlarnaPayment(clientIntent, {
          payment_method: {
            billing_details: {
              address: {
                country: platformCountry ?? domainCountry
              },
              email: email ?? undefined
            }
          },
          return_url: window.location.href
        });
      } catch {
        // do nothing
      }
    }
    setLoading(false);
    if (!result?.paymentIntent) return result;
    if (['NEW', 'FREE_SESSION'].includes(status || '')) {
      setStatus(PatientStatus.Ongoing);
    }

    return result;
  };

  const paySession: (newCardId?: string) => Promise<
    | {
        intent?: PaymentIntent;
        voucher?: Partial<IAppliedVoucher> & { code?: string };
      }
    | undefined
  > = async (newCardId) => {
    if (!session?.uuid || !paymentIntentId) return;

    let appliedVoucher: Partial<IAppliedVoucher> & { code?: string } = {
      code: selectedVoucher?.code
    };
    if (selectedVoucher?.success) {
      appliedVoucher = {
        ...appliedVoucher,
        ...(selectedVoucher.code
          ? await applyVoucher(selectedVoucher.code, session.uuid)
          : await applyBonus(session.uuid))
      };

      if (!appliedVoucher || !appliedVoucher.success) {
        throw new Error(wrapErrorMsg('Fail applying voucher'));
      }
      appliedVoucher &&
        pendoTrack(
          selectedVoucher.code ? 'voucher_applied' : 'bonus_voucher_applied',
          selectedVoucher
        );
    }

    if (appliedVoucher?.success && appliedVoucher?.total === 0) {
      return { voucher: appliedVoucher };
    }

    if (!selectedCard && !newCardId)
      throw new Error(wrapErrorMsg('Missing card'));
    const cardId =
      newCardId ??
      (selectedCard !== 'klarna'
        ? (selectedCard as ICreditCard)?.cardId
        : undefined);

    const sepaId =
      selectedCard !== 'klarna'
        ? (selectedCard as SepaDebit)?.sepaId
        : undefined;
    const paymentType =
      selectedCard === 'klarna' ? 'KLARNA' : cardId ? 'CARD' : 'SEPA';

    const result = await confirmPaymentWithSavedId(
      paymentIntentId,
      cardId ?? sepaId ?? 'klarna',
      paymentType
    );

    if (result?.error) {
      throw new Error(wrapErrorMsg(`${result.error.message}`));
    }

    const amount = result?.paymentIntent?.amount
      ? result.paymentIntent.amount / 100
      : null;
    const ISODate = session.sessionDate?.rawDate?.toISO();
    pushAuthenticatedEvent('Purchase', {
      voucher_code: appliedVoucher?.code,
      voucher_amount: appliedVoucher?.discount,
      total_amount: amount,
      tp_id: doctor?.id,
      tp_first_name: doctor?.name,
      tp_last_name: doctor?.surname,
      session_type: paymentType,
      session_date: ISODate,
      currency: 'EUR',
      user_id: id,
      uuid,
      is_firstpurchase: candidateForFirstPurchase,
      voucher_campaignname: appliedVoucher?.campaignName,
      application: isMobileApp ? 'APP' : 'WEB',
      ...GTMData
    });
    pendoTrack('pay_session', {
      amount
    });
    if (result?.paymentIntent) {
      setPaymentIntent({ ...result?.paymentIntent, sessionId: session.id });
    }

    return { intent: result?.paymentIntent, voucher: appliedVoucher };
  };

  /**
   * method used  when the user has no payment method saved
   * or wants to add a new method and pay with it
   */
  const payWithNewPaymentMethod = async () => {
    if (!session?.uuid || !paymentIntentId) return;

    if (!stripe || !elements) {
      throw new Error(wrapErrorMsg('Missing stripe or elements'));
    }

    const voucher = await applyVerifiedVoucher();

    if (voucher) {
      // fetch the updated payment intent after applying the voucher
      const { error: fetchError } = await elements.fetchUpdates();

      if (fetchError) {
        throw new Error(
          wrapErrorMsg(`${fetchError.status}, ${fetchError.message}`)
        );
      }
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      throw new Error(getStripeErrorMessage(submitError));
    }

    const { error: confirmError, paymentIntent: confirmPaymentIntent } =
      await stripe.confirmPayment({
        clientSecret: paymentIntentId,
        redirect: 'if_required',
        confirmParams: {
          save_payment_method: true,
          return_url: window.location.href
        },
        elements
      });

    if (confirmError) {
      throw new Error(getStripeErrorMessage(confirmError));
    }

    trackEvents(confirmPaymentIntent, voucher);

    updatePatient();

    // if the user is using the webapp we need to refetch the cards
    // from stripe and update state
    !isMobileApp && (await fetchPaymentMethod());
  };

  const payWithSelectedMethod = async (paymentMethodId: string) => {
    if (!session?.uuid || !paymentIntentId) return;

    if (!stripe || !elements) {
      throw new Error(wrapErrorMsg('Missing stripe or elements'));
    }

    const voucher = await applyVerifiedVoucher();

    const { error: confirmError, paymentIntent: confirmPaymentIntent } =
      await stripe.confirmCardPayment(paymentIntentId, {
        payment_method: paymentMethodId
      });

    if (confirmError) {
      throw new Error(getStripeErrorMessage(confirmError));
    }

    trackEvents(confirmPaymentIntent, voucher);

    updatePatient();
  };

  return {
    paymentIntentId,
    ...paymentIntent,
    amount: transformAmount(paymentIntent?.amount),
    clientSecret: paymentIntent?.client_secret,
    paySession,
    payWithNewPaymentMethod,
    payWithSelectedMethod,
    applyVerifiedVoucher,
    paymentLoading: loading
  };
};
