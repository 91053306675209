import { BundlesVariations } from '../../../types/Bundles';

export interface IBundlePriceDetails {
  bundleId: string;
  bundlePrice: number;
  numSessions: number;
  expireInMonths?: number;
  publishableKey: string;
  buyButtonId: string;
}
export const NO_DISCOUNT_VARIANT: BundlesVariations = 'a';

const { NX_APP_ENVIRONMENT } = process.env;
const isProd = NX_APP_ENVIRONMENT === 'production';

// TODO: to move to .env
const PUBLISHABLE_KEY_TEST =
  'pk_test_51Hw365FW0gXfdawfO36R9pIK5TOIkqNRkRaFYCvB9fiMI65FXm9lDOD4xJqtWaY0xappVB1STqYQtlpgHe88IM3n001eVdXO3l';
const PUBLISHABLE_KEY_PROD =
  'pk_live_51Hw365FW0gXfdawfbH47vj8BmswrIMfnXoaLPBli0b3ga0Lq6FcUd9bjA3DbpMgHiLP2julnzRbOL9HpKlYQeRI200ZlPE4g83';
const PUBLISHABLE_KEY = isProd ? PUBLISHABLE_KEY_PROD : PUBLISHABLE_KEY_TEST;

/**
 * Buy Button ID Map for PROD  && TEST
 */
const envKey = isProd ? 'PROD' : 'TEST';

const buyButtonIdMap = {
  TEST: {
    VARIANT_A_SMALL: 'buy_btn_1PjN5DFW0gXfdawf7nSZy5j5',
    VARIANT_A_LARGE: 'buy_btn_1PjN4yFW0gXfdawfkWOgVdoM',
    VARIANT_B_SMALL: 'buy_btn_1PjHdUFW0gXfdawf42ZwW3XA',
    VARIANT_B_LARGE: 'buy_btn_1Pj164FW0gXfdawffCpErmKu',
    VARIANT_C_SMALL: 'buy_btn_1PjN4EFW0gXfdawfi7JEd4lc',
    VARIANT_C_LARGE: 'buy_btn_1PjN3vFW0gXfdawf09vgHrqj',
    VARIANT_D_SMALL: 'buy_btn_1PjN3PFW0gXfdawfYGMetTsx',
    VARIANT_D_LARGE: 'buy_btn_1PieFoFW0gXfdawfuG7aI3FB',
    VARIANT_PAYWALL_V2_SMALL: 'buy_btn_1Q00XvFW0gXfdawfLRvSXF8Q',
    VARIANT_PAYWALL_V2_LARGE: 'buy_btn_1Q00XUFW0gXfdawf8wfHChkT'
  },
  PROD: {
    VARIANT_A_SMALL: 'buy_btn_1Pj16DFW0gXfdawf2BrwygtP',
    VARIANT_A_LARGE: 'buy_btn_1PjN62FW0gXfdawfUziuhvTV',
    VARIANT_B_SMALL: 'buy_btn_1PjN6EFW0gXfdawfy0VxUtux',
    VARIANT_B_LARGE: 'buy_btn_1PjN6WFW0gXfdawfung8mQjC',
    VARIANT_C_SMALL: 'buy_btn_1PjN8rFW0gXfdawf6e9zgofz',
    VARIANT_C_LARGE: 'buy_btn_1PjN95FW0gXfdawfvXePbfuT',
    VARIANT_D_SMALL: 'buy_btn_1PjN9eFW0gXfdawfxaW0jCmf',
    VARIANT_D_LARGE: 'buy_btn_1PizbzFW0gXfdawfYiDF9leA',
    VARIANT_PAYWALL_V2_SMALL: 'buy_btn_1PzxxYFW0gXfdawfaWpl7EqB',
    VARIANT_PAYWALL_V2_LARGE: 'buy_btn_1PzydTFW0gXfdawfrpTSQfX5'
  }
};

const singleSession = {
  bundleId: 'singleSession',
  numSessions: 1,
  bundlePrice: 49,
  publishableKey: PUBLISHABLE_KEY,
  buyButtonId: '' // OK empty
};

/**
 * Test variant A
 * no-discount && small bundle
 */
export const variantA: IBundlePriceDetails[] = [
  {
    bundleId: 'largeBundle',
    bundlePrice: 392,
    numSessions: 8,
    expireInMonths: 6,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_A_LARGE,
    publishableKey: PUBLISHABLE_KEY
  },
  {
    bundleId: 'smallBundle',
    bundlePrice: 196,
    numSessions: 4,
    expireInMonths: 3,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_A_SMALL,
    publishableKey: PUBLISHABLE_KEY
  },
  { ...singleSession } // needed for form item
];

/**
 * Test variant B
 * 4-8 sessions && small discount
 */
export const variantB: IBundlePriceDetails[] = [
  {
    bundleId: 'largeBundle',
    bundlePrice: 363,
    numSessions: 8,
    expireInMonths: 6,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_B_LARGE,
    publishableKey: PUBLISHABLE_KEY
  },
  {
    bundleId: 'smallBundle',
    bundlePrice: 186,
    numSessions: 4,
    expireInMonths: 3,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_B_SMALL,
    publishableKey: PUBLISHABLE_KEY
  },
  { ...singleSession } // needed for form item
];

/**
 * Test variant C
 * 5-10 sessions && small discount
 */
export const variantC: IBundlePriceDetails[] = [
  {
    bundleId: 'largeBundle',
    bundlePrice: 453,
    numSessions: 10,
    expireInMonths: 8,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_C_LARGE,
    publishableKey: PUBLISHABLE_KEY
  },
  {
    bundleId: 'smallBundle',
    bundlePrice: 232,
    numSessions: 5,
    expireInMonths: 4,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_C_SMALL,
    publishableKey: PUBLISHABLE_KEY
  },
  { ...singleSession } // needed for form item
];

/**
 * Test variant D
 * 5-10 sessions && high discount
 */
export const variantD: IBundlePriceDetails[] = [
  {
    bundleId: 'largeBundle',
    bundlePrice: 443,
    numSessions: 10,
    expireInMonths: 8,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_D_LARGE,
    publishableKey: PUBLISHABLE_KEY
  },
  {
    bundleId: 'smallBundle',
    bundlePrice: 227,
    numSessions: 5,
    expireInMonths: 4,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_D_SMALL,
    publishableKey: PUBLISHABLE_KEY
  },
  { ...singleSession } // needed for form item
];

/**
 * Test variant variantPaywallv2 -Bundle 2nd Iteration
 */
export const variantPaywallv2: IBundlePriceDetails[] = [
  {
    bundleId: 'largeBundle',
    bundlePrice: 360,
    numSessions: 8,
    expireInMonths: 6,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_PAYWALL_V2_LARGE,
    publishableKey: PUBLISHABLE_KEY
  },
  {
    bundleId: 'smallBundle',
    bundlePrice: 188,
    numSessions: 4,
    expireInMonths: 3,
    buyButtonId: buyButtonIdMap[envKey].VARIANT_PAYWALL_V2_SMALL,
    publishableKey: PUBLISHABLE_KEY
  },
  { ...singleSession } // needed for form item
];

export interface IBundlePriceMap {
  variant: BundlesVariations;
  bundles: IBundlePriceDetails[];
}

/**
 * Bundle PriceMap for A B C D test
 */
export const bundlePriceMap: IBundlePriceMap[] = [
  {
    variant: 'a',
    bundles: variantA
  },
  {
    variant: 'b',
    bundles: variantB
  },
  {
    variant: 'c',
    bundles: variantC
  },
  {
    variant: 'd',
    bundles: variantD
  },
  {
    variant: 'paywall',
    bundles: variantPaywallv2
  },
  {
    variant: 'alert',
    bundles: variantPaywallv2
  }
];
