import {
  Discount,
  ShareCopy,
  TimeOver,
  UserProfileSquare
} from '@unobravo/zenit-icons';
import { RStack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useCountry } from '@unobravo/translations';
import { MGMInfoElement } from './MGMInfoElement';

const PEOPLE_NUMBER = '5';

export const MGMInfo = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();

  const { domainCountry } = useCountry();

  const DISCOUNT_AMOUNT = domainCountry === 'es' ? '10 €' : '10€';

  return (
    <RStack
      direction="column"
      pl={{ base: undefined, md: 'xl' }}
      ml={{ base: undefined, md: 'md' }}
      mb={{ base: undefined, md: 'xl' }}
      overflow={{ base: 'scroll', md: 'auto' }}
    >
      <Text variant="xl" color="black" fontWeight="semibold">
        {t('memberGetMember.info.title')}
      </Text>

      <RStack
        style={{
          display: isMobile ? 'flex' : 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)'
        }}
        spacing="xl"
        pt={{ base: undefined, md: 'md' }}
        mt={{ base: 'xl', md: undefined }}
        direction={{ base: 'column', md: 'row' }}
      >
        <MGMInfoElement
          title={t('memberGetMember.info.share.title')}
          description={t('memberGetMember.info.share.description')}
          Icon={ShareCopy}
        />

        <MGMInfoElement
          title={t('memberGetMember.info.person.title', {
            peopleNumber: PEOPLE_NUMBER
          })}
          description={t('memberGetMember.info.person.description', {
            peopleNumber: PEOPLE_NUMBER
          })}
          Icon={UserProfileSquare}
        />

        <MGMInfoElement
          title={t('memberGetMember.info.time.title')}
          description={t('memberGetMember.info.time.description', {
            discountAmount: DISCOUNT_AMOUNT
          })}
          Icon={TimeOver}
        />

        <MGMInfoElement
          title={t('memberGetMember.info.discount.title')}
          description={t('memberGetMember.info.discount.description', {
            discountAmount: DISCOUNT_AMOUNT
          })}
          Icon={Discount}
        />
      </RStack>
    </RStack>
  );
};
